import React from 'react'

import { RouterLinkTemplate } from 'Components'

const QuickLinksCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, ContentLink, Url } = data
  const { Id } = ContentLink

  const Layout = () => {
    return (
      <div data-epi-block-id={Id}>
        <div className="group bg-white shadow-normal hover:shadow-active cursor-pointer rounded-lg md:p-5 p-2 gap-2 lg:h-44 md:h-40 h-20 grid justify-items-center items-center">
          <div data-epi-edit="Image">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block" width="35" height="35" />
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden" width="15" height="15" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          <h4 data-epi-edit="Heading"
            className="font-display font-medium text-center xl:text-xl md:text-lg text-xxs md:leading-6 text-gray-800 group-hover:text-black" >
            {Heading}
          </h4>
        </div>
      </div>
    )
  }

  if (!Url) return <Layout />

  return (
    <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
      <Layout />
    </RouterLinkTemplate>
  )
}

export default React.memo(QuickLinksCardBlock)
