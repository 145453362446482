import React from 'react'
import { RouterLinkTemplate } from 'Components'

const HolidayEventCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Url, Image } = data
  const Link = Url.Value[0]
  return (
    <div className="shadow-normal rounded-lg hover:shadow-active justify-between">
      <div className="lg:p-4 md:p-3 sm:p-2 p-1.5">
        <h2 className="text-left font-semibold xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs" data-epi-edit="Heading">
          {Heading} {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <h5 className="lg:mt-5 md:mt-4 sm:mt-3 mt-1 xl:mb-9 lg:mb-7 md:mb-5 sm:mb-4 mb-2 lg:text-sm md:text-xs sm:text-xxs text-xxxs xl:mr-7 lg:mr-6 md:mr-5 sm:mr-4 mr-3" data-epi-edit="Subheading">
          {Subheading} {inEditMode && !Subheading && 'Click to edit Subheading'}
        </h5>
        <div className="flex justify-between">
          <>
            {Link && (
              <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
                <span data-epi-edit="Url" className="xl:text-xl lg:text-lg md:text-base sm:text-sm text-xxs font-semibold text-violet">
                  {Link.Text}
                </span>
              </RouterLinkTemplate>
            )}
          </>
          <div data-epi-edit="Image">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block" width="17" height="20" />
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden" width="10" height="8" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HolidayEventCardBlock)
