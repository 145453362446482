import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { RoundedButtonBlock } from 'Blocks'
import { RouterLinkTemplate } from 'Components'
import { useLocationPathChange, useWindowDimensions } from 'Hooks'
import { getNavbarLinksWithActiveState, LogoRenderer, LoginButtonRenderer, CtaContentBlock, SearchRenderer } from '.'

import BarsSVG from 'SVGs/bars.svg'
import TimesSVG from 'SVGs/times.svg'
import ChevronDownSVG from 'SVGs/chevronDown.svg'
import ChevronUpSVG from 'SVGs/chevronUp.svg'

const NavbarMobile = ({ SearchPage, LogoImage, NavbarLinks, inEditMode, LoginButton, ApplyButton, LoginForm, LeftPageLinks, RightPageLinks, mobileNavRef }) => {
  const [menuVisibility, setMenuVisibility] = useState(false)
  const { width } = useWindowDimensions()
  const barsRef = useRef(null)
  const [menuWidth, setMenuWidth] = useState('640px')
  const mobileNavRefChild = mobileNavRef;

  const [navData, setNavData] = useState(NavbarLinks)
  useEffect(() => {
    setNavData(getNavbarLinksWithActiveState(window.location.pathname, NavbarLinks))
  }, [])
  useEffect(() => {
    if (width < 640) {
      setMenuWidth((width - 16) + 'px')
    } else if (width >= 640 && width < 768) {
      setMenuWidth((width - 32) + 'px')
    } else {
      setMenuWidth((width - 48) + 'px')
    }
  }, [width])
  
  useLocationPathChange((pathname) => {
    setNavData(getNavbarLinksWithActiveState(pathname, navData))
  })
  
  // calls setFixedHeaderHeight after menuVisibility is set since useState is an asynchronous call
  useEffect(() => {
    setFixedHeaderHeight()
  }, [menuVisibility]);

  // a fixed element is taken out of the normal document flow and has no height or width of its own.
  // since the layout wasn't built with a multi fixed header in mind before this code was written,
  // we need to conditionally set the height and overflow scroll properties for the mobile navigation
  // to allow it to be scrollable and not cut off
  function setFixedHeaderHeight() {
    if (menuVisibility && !mobileNavRefChild.current.classList.contains('overflow-y-auto')) {
      mobileNavRefChild.current.classList.add('max-h-[95%]');
      mobileNavRefChild.current.classList.add('overflow-y-auto');
    }
    else {
      mobileNavRefChild.current.classList.remove('max-h-[95%]');
      mobileNavRefChild.current.classList.remove('overflow-y-auto');
    }
  }

  return (
    <div className="lg:hidden">
      <div className="flex justify-between">
        <div className="w-48 mx-2 my-2">
          <LogoRenderer data={LogoImage} name="LogoImage" inEditMode={inEditMode} />
        </div>
        <div ref={barsRef} className="text-xl cursor-pointer grid h-8 w-8 items-center justify-center lg:hidden p-1 border rounded" onClick={() => { setMenuVisibility(!menuVisibility); }}>
          {!menuVisibility && <BarsSVG className="justify-self-center h-5 text-violet" />}
          {menuVisibility && <TimesSVG className="justify-self-center h-5 text-violet" />}
        </div>
      </div>
      {NavbarLinks && <ul className={`${menuVisibility ? 'block' : 'hidden'} z-50 bg-white border px-4 py-4 rounded shadow-md`}>
          {navData?.map((item, i) => {
            const { Name, Links, CtaContent, Active } = item
            const [expand, setExpand] = useState(false)
            return (
              <li key={i}>
                <div className="flex text-base justify-start font-display pb-2">
                  <span className="mr-3 pt-2 text-emerald cursor-pointer" onClick={() => setExpand(!expand)}>
                    {expand ? <ChevronUpSVG className="h-2.5" /> : <ChevronDownSVG className="h-2.5" />}
                  </span>
                  <div className="grid w-full">
                    <p className={`${Active ? 'text-violet' : 'text-black'} text-xl font-medium cursor-pointer`} onClick={() => setExpand(!expand)}>{Name}</p>
                    <div className={`${expand ? 'block' : 'hidden'} text-black`}>
                      <ul className="grid grid-cols-2 gap-x-6">
                        {Links?.map((link, linkIndex) => {
                          const { ParentText, ParentUrl, Childrens } = link
                          return (
                            <li key={linkIndex} className="py-2">
                              <ul>
                                <li className="font-medium font-display text-lg">
                                  {ParentUrl && <NavLink to={ParentUrl} className={({ isActive }) => 'py-1' + (isActive ? 'text-violet-bright' : '')} end onClick={() => setMenuVisibility(!menuVisibility)}>{ParentText}</NavLink>}
                                  {!ParentUrl && <span className="py-1">{ParentText}</span>}
                                </li>
                                {Childrens?.map((child, childIndex) => {
                                  const { Text, Href, Target } = child
                                  const path = Href.toLowerCase()
                                  if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('tel:') || path.endsWith('.pdf')) {
                                    return <li key={childIndex} className="font-body leading-tight"><a href={Href} className="py-1 hover:text-violet-bright" target={Target}>{Text}</a></li>
                                  }
                                  return <li key={childIndex} className="font-body leading-tight"><NavLink to={Href} className={({ isActive }) => 'py-1 ' + (isActive ? 'text-violet-bright' : 'hover:text-violet-bright')} end onClick={() => setMenuVisibility(!menuVisibility)}>{Text}</NavLink></li>
                                })}
                              </ul>
                            </li>
                          )
                        })}
                      </ul>
                      {CtaContent && <div className="py-4"><CtaContentBlock data={CtaContent} inEditMode={inEditMode} handleClick={() => setMenuVisibility(false)} /></div>}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
          <div className="flex justify-around mt-2 pt-4">
            <span onClick={() => setMenuVisibility(!menuVisibility)}>
              <RoundedButtonBlock data={ApplyButton} name="ApplyButton" inEditMode={inEditMode} />
            </span>
            <LoginButtonRenderer data={LoginButton} name="LoginButton" form={LoginForm} formName="LoginForm" inEditMode={inEditMode} />
          </div>
          <div className="mx-8 mt-4">
            <SearchRenderer data={SearchPage} name="SearchPage" inEditMode={inEditMode} />
          </div>
          <ul className="mt-4 grid ml-8 font-medium">
            {LeftPageLinks?.Value?.map((link, i) => (
              <li key={i} onClick={() => setMenuVisibility(!menuVisibility)}>
                <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
                  {link.Text}
                </RouterLinkTemplate>
              </li>
            ))}
            {RightPageLinks?.Value?.map((link, i) => (
              <li key={i} onClick={() => setMenuVisibility(!menuVisibility)}>
                <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
                  {link.Text}
                </RouterLinkTemplate>
              </li>
            ))}
          </ul>
        </ul>}
    </div>
  )
}

export default React.memo(NavbarMobile)
