import React, { useEffect, useState, useRef } from 'react'

import './SearchRenderer.css'

const SearchSVG = () => {
  return (
    <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" fill="#616161">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
    </svg>
  )
}

const SearchRenderer = ({ data, name, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return (
      <div data-epi-edit={name}>
        <SearchSVG />
      </div>
    )
  }

  const [xsrfToken, setXsrfToken] = useState('')
  const tokenEl = useRef(null)
  const formEl = useRef(null)

  useEffect(() => {
    setXsrfToken(window.csrfToken)
  }, [])

  const handleSubmission = (e) => {
    e.preventDefault()
    e.target.removeChild(tokenEl?.current)
    e.target.submit()
  }

  return (
    <form action={data} ref={formEl} data-epi-edit={name} onSubmit={handleSubmission}>
      <input type="hidden" ref={tokenEl} name="__RequestVerificationToken" value={xsrfToken} />
      <div className="search flex overflow-hidden text-xl relative p-1 lg:focus-within:w-48 border-b-2 lg:border-transparent focus-within:border-violet lg:w-[var(--size)] h-[var(--size)] transition-[width] duration-500">
        <button className="bg-transparent cursor-pointer rounded-full border-none w-[calc(var(--size)-10px)] h-[calc(var(--size)-10px)] outline-none" onClick={() => formEl.current && formEl.current.submit()}><SearchSVG /></button>
        <input name="q" type="text" className="p-1 flex-1 outline-none z-10 absolute bg-transparent top-0 bottom-0 left-0 placeholder-gray-500 xl:text-base text-sm font-body w-full" placeholder="Search..."/>
      </div>
    </form>
  )
}

export default SearchRenderer
