import React, { useEffect, useState } from 'react'

import LoginPopupFormBlock from 'Blocks/Forms/LoginPopupFormBlock'

const LoginModal = ({ data, name, inEditMode, isOpen, onClose }) => {
  if (!isOpen) return null

  const [open, setOpen] = useState(isOpen)
  function downHandler (e) {
    if (e.keyCode === 27) {
      closeModal()
    }
  }

  const closeModal = () => {
    setOpen(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  if (!open) return null

  return (
    <div style={{ zIndex: 10000000000 }} className="fixed inset-0 overflow-y-auto backdrop-blur backdrop-filter" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="sm:-mt-20 -mt-48 md:mt-0 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-2">
        <div className="fixed inset-0 bg-gray-600 opacity-40" aria-hidden="true" onClick={() => closeModal()}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <LoginPopupFormBlock data={data} name={name} inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(LoginModal)
