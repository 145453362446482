import React from 'react'
import { Link } from 'react-router-dom'

const RouterLinkTemplate = ({ Path, Target, inEditMode, children }) => {
  if (!Path) return <>{children}</>
  if (inEditMode) return <a href={Path}>{children}</a>
  if (Target) return <a href={Path} target={Target}>{children}</a>
  const path = Path.toLowerCase()
  if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('tel:') || path.endsWith('.pdf')) {
    return <a href={Path}>{children}</a>
  }

  return <Link to={Path}>{children}</Link>
}

export default React.memo(RouterLinkTemplate)
