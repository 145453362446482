import React, { useState } from 'react'

import ChevronLeftSVG from 'SVGs/chevronLeft.svg'
import ChevronRightSVG from 'SVGs/chevronRight.svg'

const getClassName = (currentIndex, activeIndex, previousActiveIndex, arrayLength) => {
  const firstElementIndex = (activeIndex + arrayLength - 2) % arrayLength
  const prevElementIndex = (activeIndex + arrayLength - 1) % arrayLength
  const nextElementIndex = (activeIndex + 1) % arrayLength
  const lastElementIndex = (activeIndex + 2) % arrayLength

  const isRotatingClockwise = previousActiveIndex === prevElementIndex || previousActiveIndex === firstElementIndex

  let result = 'inline-block cursor-pointer transition ease-in-out duration-700 overflow-hidden absolute top-1/2 transform -translate-y-1/2 rounded'
  if (currentIndex === activeIndex) {
    result += ' scale-125 z-30'
    if (isRotatingClockwise) {
      result += ' left-1/2 -translate-x-1/2'
    } else {
      result += ' right-1/2 translate-x-1/2'
    }
  } else if (currentIndex === firstElementIndex) {
    // Prev of Prev element
    result += ' scale-75 z-10 left-1/4 -translate-x-full'
  } else if (currentIndex === prevElementIndex) {
    // Prev element
    result += ' z-20 left-1/3 -translate-x-2/3'
  } else if (currentIndex === nextElementIndex) {
    // Next element
    result += ' z-20 right-1/3 translate-x-2/3'
  } else if (currentIndex === lastElementIndex) {
    // Next of Next element
    result += ' scale-75 z-10 right-1/4 translate-x-full'
  } else {
    // Hidden element
    result += ' scale-75 opacity-0'
    if (isRotatingClockwise) {
      result += ' left-1/2 -translate-x-1/2'
    } else {
      result += ' right-1/2 translate-x-1/2'
    }
  }
  return result
}

const CarouselRenderer = ({ data, name, inEditMode }) => {
  if (!data || data.length === 0) {
    if (!inEditMode) return null
    return <div data-epi-edit={name}>Click to add images</div>
  }

  const n = data?.length ?? 0
  const [activeIndex, setActiveIndex] = useState(data && n > 0 ? Math.floor(n / 2) : null)
  const [prevActiveIndex, setPrevActiveIndex] = useState(null)

  return (
    <div className="lg:block hidden py-8">
      {data && n > 0 && data.map((block, i) => {
        const { Quote, Name } = block
        const fullYear = (new Date(block.Date)).getFullYear()
        return (
          <div className={`py-8 text-center transition ${i === activeIndex ? 'animate-fade-in' : 'hidden'}`} key={i}>
            <h4 className="font-display text-5xl pb-4 italic">“{Quote}”</h4>
            <h5 className="font-display text-3xl italic">{Name} {fullYear}</h5>
          </div>
        )
      })}
      <div className="flex lg:gap-4 items-center my-8     ">
        <div className="rounded-full grid px-1 cursor-pointer select-none" onClick={() => {
          setPrevActiveIndex(activeIndex)
          setActiveIndex((activeIndex + n - 1) % n)
        }}>
          <ChevronLeftSVG className="m-4 h-4 text-gray-500" />
        </div>
        <div data-epi-edit={name} className="w-full md:py-6 py-4 relative h-56">
          {data && n > 0 && data.map((block, i) => {
            const { ContentLink, Image } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i} className={getClassName(i, activeIndex, prevActiveIndex, n)}
                onClick={() => {
                  if (i === activeIndex) return
                  setPrevActiveIndex(activeIndex)
                  setActiveIndex(i)
                }}>
                <img data-src={Image?.Url} alt={Image?.AltTag} width="240" height="215" className='lazy' />
              </div>
            )
          })}
        </div>
        <div className="rounded-full grid px-1 cursor-pointer select-none" onClick={() => {
          setPrevActiveIndex(activeIndex)
          setActiveIndex((activeIndex + 1) % n)
        }}>
          <ChevronRightSVG className="m-4 h-4 text-gray-500" />
        </div>
      </div>
    </div>
  )
}

export default React.memo(CarouselRenderer)
