import React from 'react'

import FooterMain from './FooterMain'

const FooterBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <footer>Empty Footer. You can edit it in root page.</footer>
  }

  const {
    FirstColumn, FirstColumnHeader,
    SecondColumn, SecondColumnHeader,
    ThirdColumn, ThirdColumnHeader,
    FourthColumn, FourthColumnHeader,
    FifthColumn, FifthColumnHeader,
    SocialHeader, SocialLinks,
    NcuaImage, CopyrightText
  } = data
  const DataMapper = {
    FirstColumn: { FirstColumn, FirstColumnHeader },
    SecondColumn: { SecondColumn, SecondColumnHeader },
    ThirdColumn: { ThirdColumn, ThirdColumnHeader },
    FourthColumn: { FourthColumn, FourthColumnHeader },
    FifthColumn: { FifthColumn, FifthColumnHeader }
  }

  return (
    <FooterMain data={{ DataMapper, NcuaImage, SocialHeader, SocialLinks, CopyrightText }} inEditMode={inEditMode} />
  )
}

export default React.memo(FooterBlock)
