import flattenDeep from 'lodash/flattenDeep'

import CtaContentBlock from './CtaContentBlock'
import HeaderBock from './HeaderBlock'
import LeftPageLinksRenderer from './LeftPageLinksRenderer'
import LoginButtonRenderer from './LoginButtonRenderer'
import LogoRenderer from './LogoRenderer'
import Navbar from './Navbar'
import NavbarMobile from './NavbarMobile'
import RightPageLinksRenderer from './RightPageLinksRenderer'
import SearchRenderer from './SearchRenderer'

export default HeaderBock

const getNavbarLinksWithActiveState = (currentPath, navlinks) => {
  return navlinks?.map((item) => {
    const { Links } = item
    const results = Links?.map((link) => {
      const { ParentUrl, Childrens } = link
      const links = Childrens?.map((link) => link.Href)
      links?.push(ParentUrl)
      return links
    })

    item.Active = currentPath && results && flattenDeep(results).includes(currentPath)
    return item
  })
}

export {
  CtaContentBlock,
  LeftPageLinksRenderer,
  LoginButtonRenderer,
  LogoRenderer,
  Navbar,
  NavbarMobile,
  RightPageLinksRenderer,
  SearchRenderer,
  getNavbarLinksWithActiveState
}
