import React from 'react'

import VideoCardBlock from './VideoCardBlock'

const VideoGuideWithWideCards = ({ data, inEditMode }) => {
  const { MainContentArea } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div data-epi-edit="MainContentArea"
        className="xl:mx-48 lg:mx-40 md:mx-12 sm:mx-8 mx-12
                   lg:py-10 py-7
                   grid sm:grid-cols-2 xl:gap-36 lg:gap-24 md:gap-16 gap-12 items-center">
        {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((val, i) => {
          return <VideoCardBlock key={i} data={val} inEditMode={inEditMode} size='wide' />
        })}
      </div>
    </div>
  )
}

export default React.memo(VideoGuideWithWideCards)
