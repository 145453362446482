import React, { useState } from 'react'

import PromotionCarouselCardBlock from './PromotionCarouselCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const CarouselMobileRenderer = ({ data, name, inEditMode }) => {
  if (!data || data.length === 0) {
    if (!inEditMode) return null
    return <div data-epi-edit={name}>Click to add images</div>
  }

  const blockData = contentAreaDataMapper(data)
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div data-epi-edit={name} className="md:hidden justify-around overflow-x-scroll hidden-scrollbars whitespace-nowrap">
      {data && data.length > 0 && data.map((block, i) => {
        const { ContentLink } = block
        const { Id } = ContentLink
        return (
          <div data-epi-block-id={Id} key={i} className="inline-block" onClick={() => setCurrentIndex(i)}>
            <PromotionCarouselCardBlock data={blockData[Id]} inEditMode={inEditMode} isActive={i === currentIndex}/>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(CarouselMobileRenderer)
