import React from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import PromotionContainerCardBlock from './PromotionContainerCardBlock'
import ChevronDownSVG from 'SVGs/chevronDown.svg'
import { Markdown } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const getButtonStyles = (style, color) => {
  // shared classes first
  let inlineStyles = `rounded-full font-medium font-body text-xs sm:text-sm md:text-base whitespace-nowrap
                    py-2 lg:py-2.5 px-4 sm:px-6 md:px-8`

  switch (style) {
    case 'primary':
      inlineStyles += ` border-2 text-white`
      if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#320767] border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
      break
    case 'secondary':
      inlineStyles += ` bg-white border-2 text-black`
      if (color === 'violet') { inlineStyles += ' border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' border-[#21064C] hover:border-violet' }
      break
    case 'primary-with-arrow':
      inlineStyles += ` border-2 text-white`
      if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#320767] border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
      break
    case 'secondary-with-arrow':
      inlineStyles += ` bg-white border-2`
      if (color === 'violet') { inlineStyles += ' text-black border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' text-black border-[#21064C] hover:border-violet' }
      break
  }

  return inlineStyles
}

const ButtonRenderer = ({ data, name, inEditMode }) => {
  if (!data) return
  const { Color, Link, Style } = data
  const showArrow = Style === 'primary-with-arrow' || Style === 'secondary-with-arrow' ? true : false;
  const link = Link?.Value[0]

  const classes = getButtonStyles(Style, Color);

  return (
    <div data-epi-edit={name} className='inline-flex justify-center'>
      <a href={link?.Href}>
        <div className={`flex gap-4 justify-center ${classes}`}>
          {inEditMode && !data && !link?.Text ? 'Click to edit' : <div>{link?.Text}</div>}
          {showArrow && <ChevronDownSVG className="w-4 stroke-2" />}
        </div>
      </a>
    </div>
  )
}

const RightSideRenderer = ({ data, inEditMode }) => {
  if (!data) return
  const { TitleHeader, CardButtonWithArrow, CardHeading, CardBackgroundImage } = data

  return (
    <div className="flex justify-center lg:max-h-96"> {/* Image */}
      <div className="bg-center bg-no-repeat bg-contain xl:p-10 md:p-6 p-[40px] h-full lazy" data-bg={addWebPToImageURL(CardBackgroundImage?.Url)}>
        <div className="lg:mx-10 md:mx-6 group bg-white shadow-active rounded-lg max-w-[300px] sm:max-w-[unset]">
          <div className="flex flex-col gap-6 md:p-6 p-4">
            <Markdown data={TitleHeader} name="TitleHeader" inEditMode={inEditMode} />
            <ButtonRenderer data={CardButtonWithArrow} name="Button" inEditMode={inEditMode} />
            <div className="text-center lg:text-sm text-xs">{CardHeading}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PromotionContainerBlock = ({ data, inEditMode }) => {
  const { Heading, SubHeading, MainContentArea } = data

  if (!MainContentArea && !Heading && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="lg:py-12 md:py-8 py-4 xl:mx-52 lg:mx-36 md:mx-28 sm:mx-24 mx-10">
        <div className="flex flex-col-reverse sm:flex-row xl:gap-x-10 lg:gap-x-8 md:gap-x-6 gap-x-4 md:mb-6 mb-4">
          <div className="w-full sm:w-10/12">
            <div className="mb-6 mt-4 md:my-6 lg:my-8"> {/* Heading */}
              {(Heading || inEditMode) &&
                <Markdown data={Heading} name="Heading" inEditMode={inEditMode} />
              }
            </div>
            <div className='block sm:hidden mb-6'>
              <RightSideRenderer data={data} inEditMode={inEditMode} />
            </div>
            <div className=""> {/* Sub Heading */}
              {(SubHeading || inEditMode) &&
                <Markdown data={SubHeading} name="SubHeading" inEditMode={inEditMode} />
              }
            </div>
            <div data-epi-edit="MainContentArea" className={'grid grid-col-1 lg:gap-y-6 gap-y-4 xl:my-12 lg:my-10 md:my-8 my-6'}>
              {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
                const { ContentLink } = block
                const { Id } = ContentLink
                return (<PromotionContainerCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />)
              })}
            </div>
          </div>
          <div className='hidden sm:block'> {/* Right Side of layout*/}
            <RightSideRenderer data={data} inEditMode={inEditMode} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PromotionContainerBlock)
