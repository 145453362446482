import React, { useEffect, useState } from 'react'

import NewChevronUp from 'SVGs/newChevronUp.svg'

function ScrollToTop () {
  const [visibility, setVisibility] = useState(false)

  const scrolling = (e) => {
    if (window.pageYOffset > 50) {
      setVisibility(true)
      return
    }
    setVisibility(false)
  }

  useEffect(() => {
    window.document.addEventListener('scroll', scrolling, false)
    return () => window.document.removeEventListener('scroll', scrolling)
  }, [])

  return (
    <div onClick={(e) => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      className={`${visibility ? 'opacity-1 h-10 border-gray-300 shadow hover:shadow-lg' : 'opacity-0 h-0 border-white'} w-fit
        flex border bg-white items-center justify-center rounded-full cursor-pointer
        transition-all duration-500 fixed right-1/2 translate-x-2/4 bottom-[19px] md:bottom-[38px] px-4 py-2`}>
      <div className={`${visibility ? 'opacity-1' : 'opacity-0'} transition delay-100 flex space-x-2 items-center`}>
        <span className='text-sm font-medium leading-5 text-violet-medium'>Back to Top</span>
        <NewChevronUp className="w-6 text-violet-medium" />
      </div>
    </div>
  )
}

export default ScrollToTop
