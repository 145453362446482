import React from 'react'

import { Markdown, SearchBar, StarsBackground } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const WelcomeBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { Heading, Subheading, Content, SearchPage, SearchText, CtaLink, Image } = data

  const Link = CtaLink?.Value[0]

  return (
    <div className="bg-gradient-to-br from-blue-deep-sky via-blue-royal to-blue-navy overflow-y-hidden">
      <StarsBackground />
      <div className="container mx-auto max-w-screen-xl relative md:pb-20 pb-6">
        <div className="xl:py-18 lg:py-10 md:py-8 py-6
                      xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      relative">
          <div className="absolute top-0 left-2/3 bottom-0 right-0 bg-center bg-no-repeat bg-contain hidden md:block lazy" data-bg={addWebPToImageURL(Image?.Url)} data-epi-edit="Image" />
          <div className="grid md:grid-cols-12 lg:my-6 md:gap-8 gap-4">
            <h1 className="col-span-full lg:text-6xl md:text-4xl text-2xl font-medium font-display">
              <span data-epi-edit="Heading">
                {Heading}
                {inEditMode && !Heading && 'Click to edit Heading'}
              </span>
            </h1>
            <h4 className="lg:col-span-7 md:col-span-8 lg:text-lg md:text-base text-sm md:font-medium md:font-display font-body">
              <span data-epi-edit="Subheading">
                {Subheading}
                {inEditMode && !Subheading && 'Click to edit Subheading'}
              </span>
            </h4>
            <div className="lg:col-span-7 md:col-span-8">
              <SearchBar action={SearchPage} searchPageName="SearchPage" placeHolder={SearchText} name="SearchText" button={Link} buttonName="CtaLink" inEditMode={inEditMode} hideSearchInput={true} />
            </div>
            <div className="lg:col-span-5 md:col-span-4 md:block hidden" />
            {(Content || inEditMode) && (
              <>
                <div className="lg:col-span-7 md:col-span-8"><Markdown data={Content} name='Content' inEditMode={inEditMode} /></div>
                <div className="lg:col-span-5 md:col-span-4 md:block hidden" />
              </>
            )}
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden w-1/2 sm:w-1/3 mx-auto" width="300px" height="300px" data-epi-edit="Image"/>
          </div>
        </div>
        {inEditMode && (
          <div className="my-8">
            <span className="text-gray-500" data-epi-edit="BackgroundImage">Change container background image</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(WelcomeBlock)
