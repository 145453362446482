import React, { useEffect, useRef } from 'react'

const SignalIntentCalculator = ({ Guid }) => {
  const iframeRef = useRef(null)
  const innerHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <script src='https://embed.signalintent.com/js/embedded.js?org-guid=9931ac1c-7b2b-46fd-984e-7748ced1ffb0'></script>
      <script>
      function resize() {
        var height = document.getElementsByTagName('html')[0].scrollHeight
        window.parent.postMessage(['setHeight', height], '*')
      }

      function onElementHeightChange(elm, callback) {
        var lastHeight = elm.clientHeight, newHeight
        (function run() {
          newHeight = elm.clientHeight
          if (lastHeight != newHeight) callback()
          
          lastHeight = newHeight
  
          if (elm.onElementHeightChangeTimer) clearTimeout(elm.onElementHeightChangeTimer)

          elm.onElementHeightChangeTimer = setTimeout(run, 200)
        })()
      }
      </script>
    </head>
    <body>
      <div id='sgi' data-guid=${Guid}/>
      <script>
      onElementHeightChange(document.body, function() {
        resize()
      })
      </script>
    </body>
    </html>
  `

  const handleMessageEvent = (e) => {
    if (iframeRef?.current) {
      const eventName = e.data[0]
      const data = e.data[1]
      switch (eventName) {
        case 'setHeight':
          iframeRef.current.height = data + 'px'
          break
      }
    }
  }

  useEffect(() => {
    if (iframeRef?.current) {
      const doc = iframeRef.current.contentWindow.document
      doc.open()
      doc.write(innerHtml)
      doc.close()
    }
    window.addEventListener('message', handleMessageEvent)
    return () => window.removeEventListener('message', handleMessageEvent)
  }, [iframeRef])

  return (
    <>
      <div className="container mx-auto max-w-screen-xl">
        <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                        lg:py-10 md:py-7 py-4">
          <iframe ref={iframeRef} className="w-full" />
        </div>
      </div>
    </>
  )
}

export default SignalIntentCalculator
