import React from 'react'
import WeOfferCardBlock from './WeOfferCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const WeOfferBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      xl:py-18 lg:py-10 md:py-8 py-6
                      grid lg:gap-y-12 gap-y-4">
        <h2 data-epi-edit="Heading" className="lg:text-4xl text-xl font-medium font-display lg:block text-center">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <div data-epi-edit="MainContentArea" className={`${MainContentArea?.length >= 3 ? 'grid grid-cols-3 ' : 'flex flex-nowrap lg:mx-36 md:mx-20 mx-6 justify-center '}flex-1 md:gap-12 gap-4 py-8`}>
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <WeOfferCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(WeOfferBlock)
