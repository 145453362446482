import React from 'react'
import LoanCardBlock from './LoanCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const LoanBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea } = data
  if (!MainContentArea && !Heading && !inEditMode) return null

  const blockData = contentAreaDataMapper(MainContentArea)

  const dataLength = MainContentArea?.length ?? 0
  let gridCols = 'grid md:grid-cols-3  grid-cols-2'
  const marginX = 'xl:mx-44 lg:mx-32 md:mx-24 sm:mx-20 mx-12'
  if (dataLength % 3 === 0) {
    gridCols = 'grid md:grid-cols-3 grid-cols-2 xl:px-28 lg:px-16 md:px-12 sm:px-20 px-12 lg:gap-x-16 md:gap-x-12 sm:gap-x-8 gap-x-6 lg:gap-y-20 md:gap-y-12 gap-y-8'
  } else if (dataLength === 2 || dataLength === 4) {
    gridCols = 'grid grid-cols-2 xl:px-64 lg:px-44 md:px-32 sm:px-20 px-12 lg:gap-x-16 md:gap-x-12 sm:gap-x-8 gap-x-6 lg:gap-y-20 md:gap-y-12 gap-y-8'
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className={`${marginX} lg:py-10 md:py-7 py-4`}>
        {(Heading || inEditMode) && <h2 data-epi-edit="Heading" className="lg:mb-20 md:mb-12 mb-8 lg:text-4xl md:text-3xl sm:text-xl text-lg text-center font-bold font-display">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>}
        <div data-epi-edit="MainContentArea" className={`${gridCols} flex-1
                                                        md:max-w-full max-w-md mx-auto`}>
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <LoanCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(LoanBlock)
