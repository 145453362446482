import { useEffect } from 'react'

export default function useEpiContentSaved (action) {
  const eventHandler = (e) => {
    if (e.data.id === 'contentSaved') {
      action(e.data.data.contentLink)
    }
  }

  useEffect(() => {
    window.addEventListener('message', eventHandler, false)
    return () => window.removeEventListener('message', eventHandler)
  })
}
