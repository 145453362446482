import React, { useState } from 'react'

import { Tooltip } from 'Components'
import InfoCircleSVG from 'SVGs/infoCircle.svg'
import SyncSVG from 'SVGs/sync.svg'
import api from 'Helpers/api'

const TooltipDisclosure = ({ name, tooltipText, inEditMode }) => {
  if (!inEditMode && !tooltipText) return null

  return (
    <span data-epi-edit={name} className="lg:ml-1 ml-0.5 cursor-pointer">
      <Tooltip tooltipText={tooltipText}>
        <InfoCircleSVG className="md:h-3 h-2 inline-block" />
      </Tooltip>
    </span>
  )
}

const RateTablewithHoveroverBlock = ({ data, inEditMode, isChild }) => {
  const { Heading, AccountTypes, ButtonText, TemplateName } = data
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const shouldRender = () => {
    return AccountTypes?.length > 0 && typeof AccountTypes[index] !== 'undefined' &&
      AccountTypes[index].Rates && AccountTypes[index].Rates.length > 0
  }

  const TableContainer = () => {
    return (
      <div className={'md:my-0 my-2 pt-2 shadow-normal rounded-lg justify-between bg-violet'}>
        <div className={`${shouldRender() && AccountTypes[index].Rates.length <= 3 ? 'lg:pb-20 md:pb-12 pb-6 bg-white rounded-lg' : 'lg:pb-8 pb-4 bg-white rounded-lg'}`}>
        <table className="w-full divide-y divide-gray-400 font-body text-xs bg-white">
          <thead className="text-center font-bold text-white bg-violet">
            <tr className="rounded-t-lg">
            {inEditMode || AccountTypes[index]?.BalancesTitle
              ? <th className="lg:py-5 py-2 pl-4 text-left">
              {AccountTypes && <span>{AccountTypes[index]?.BalancesTitle}</span>}
              <TooltipDisclosure name="BalancesDisclosure" tooltipText={AccountTypes[index]?.BalancesDisclosure} inEditMode={inEditMode} />
              {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
            </th>
              : null}
              {inEditMode || AccountTypes[index]?.DividendRateTitle
                ? <th className="lg:py-5 py-2 px-4">
                {AccountTypes && <span>{AccountTypes[index]?.DividendRateTitle}</span>}
                <TooltipDisclosure name="DividendRateDisclosure" tooltipText={AccountTypes[index]?.DividendRateDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                : null }
                {inEditMode || AccountTypes[index]?.AnnualPercentageYieldTitle
                  ? <th className="lg:py-5 py-2 px-4">
                {AccountTypes && <span>{AccountTypes[index]?.AnnualPercentageYieldTitle }</span>}
                <TooltipDisclosure name="AnnualPercentageYieldDisclosure" tooltipText={AccountTypes[index]?.AnnualPercentageYieldDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                  : null}
                {inEditMode || AccountTypes[index]?.MinimumOpeningBalanceTitle
                  ? <th className="lg:py-5 py-2 px-4 lg:w-28 sm:w-24">
                {AccountTypes && <span>{AccountTypes[index]?.MinimumOpeningBalanceTitle }</span>}
                <TooltipDisclosure name="MinimumOpeningBalanceDisclosure" tooltipText={AccountTypes[index]?.MinimumOpeningBalanceDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                  : null}
              {inEditMode || AccountTypes[index]?.MinimumBalancetoEarnAPYTitle
                ? <th className="lg:py-5 py-2 px-4 lg:w-28 sm:w-24 w-14">
                {AccountTypes && <span>{AccountTypes[index]?.MinimumBalancetoEarnAPYTitle }</span>}
                <TooltipDisclosure name="MinimumBalancetoEarnAPYDisclosure " tooltipText={AccountTypes[index]?.MinimumBalancetoEarnAPYDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                : null}
              {inEditMode || AccountTypes[index]?.MinimumBalancetoAvoidServiceFeeTitle
                ? <th className="lg:py-5 py-2 px-4 lg:w-32 sm:w-28 w-14">
                {AccountTypes && <span>{AccountTypes[index]?.MinimumBalancetoAvoidServiceFeeTitle }</span>}
                <TooltipDisclosure name="MinimumBalancetoAvoidServiceFeeDisclosure " tooltipText={AccountTypes[index]?.MinimumBalancetoAvoidServiceFeeDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                : null}
              {inEditMode || AccountTypes[index]?.DividendsCompoundandCreditedTitle
                ? <th className="lg:py-5 py-2 px-4 lg:w-28 sm:w-32 w-14">
                {AccountTypes && <span>{AccountTypes[index]?.DividendsCompoundandCreditedTitle }</span>}
                <TooltipDisclosure name="DividendsCompoundandCreditedDisclosure" tooltipText={AccountTypes[index]?.DividendsCompoundandCreditedDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                : null}
              {inEditMode || AccountTypes[index]?.DividendPeriodTitle
                ? <th className="lg:py-5 py-2 pr-4">
                {AccountTypes && <span>{AccountTypes[index]?.DividendPeriodTitle }</span>}
                <TooltipDisclosure name="DividendPeriodDisclosure" tooltipText={AccountTypes[index]?.DividendPeriodDisclosure } inEditMode={inEditMode} />
                {inEditMode && !AccountTypes && <span>Click to add rate data</span>}
              </th>
                : null}
            </tr>
          </thead>
          <tbody>
            {shouldRender() && AccountTypes[index].Rates.map((val, i) => {
              const { Balances, DividendRate, AnnualPercentageYield, MinimumOpeningBalance, MinimumBalancetoEarnAPY, MinimumBalancetoAvoidServiceFee, DividendsCompoundandCredited, DividendPeriod } = val
              return (
                <tr key={i} className="text-gray-500 text-center">
                  {inEditMode || Balances
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:pl-4 pl-2 text-left`}><span dangerouslySetInnerHTML={{ __html: Balances }}/></td>
                    : null}
                  {inEditMode || DividendRate
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: DividendRate }}/></td>
                    : null}
                  {inEditMode || AnnualPercentageYield
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: AnnualPercentageYield }}/></td>
                    : null}
                  {inEditMode || MinimumOpeningBalance
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: MinimumOpeningBalance }}/></td>
                    : null}
                  {inEditMode || MinimumBalancetoEarnAPY
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: MinimumBalancetoEarnAPY }}/></td>
                    : null}
                  {inEditMode || MinimumBalancetoAvoidServiceFee
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: MinimumBalancetoAvoidServiceFee }}/></td>
                    : null}
                  {inEditMode || DividendsCompoundandCredited
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'} lg:px-4 px-2`}><span dangerouslySetInnerHTML={{ __html: DividendsCompoundandCredited }}/></td>
                    : null}
                  {inEditMode || DividendPeriod
                    ? <td className={`${i === 0 ? 'lg:pt-5 py-2' : 'py-2'}`}><span dangerouslySetInnerHTML={{ __html: DividendPeriod }}/></td>
                    : null}
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
      </div>
    )
  }

  function handlePdfGeneration (e) {
    e.preventDefault()
    setLoading(true)
    api.getPdfLink(TemplateName)
      .then(res => {
        setLoading(false)
        setTimeout(() => window.open(res.Response, '_blank'), 200)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const Inner = () => {
    return (
      <div className="lg:grid grid-cols-12 gap-8">
        {AccountTypes?.length > 1 && (
          <div data-epi-edit="AccountTypes"
            className="lg:text-base md:text-sm sm:text-xs text-xxs
                      col-span-2 flex lg:flex-col
                      gap-8 lg:mb-0 mb-8 md:mx-0 sm:mx-3 mx-2
                      scroll-smooth hover:scroll-auto lg:overflow-visible overflow-x-scroll hidden-scrollbars">
            {AccountTypes?.length > 0 && AccountTypes.map((val, i) => {
              if (!val) return null
              const { DisplayName } = val
              return <h4 className={`${index === i ? 'text-violet font-semibold' : 'font-medium'} text-sm font-display cursor-pointer hover:text-violet-light`} key={i}
                onClick={() => setIndex(i)}>{DisplayName}</h4>
            })}
          </div>
        )}
        <div className={`${AccountTypes?.length > 1 ? 'col-span-10' : 'col-span-12'} grid lg:gap-8 md:gap-4 gap-2 bg-white scroll-smooth hover:scroll-auto md:overflow-visible overflow-x-scroll hidden-scrollbars`}>
          <h4 data-epi-edit="Heading" className="lg:text-lg md:text-base md:text-xs text-sm font-semibold font-display md:mx-0 mx-8">
            {Heading} {inEditMode && !Heading && 'Click to edit Heading'}
          </h4>
          <h2 data-epi-edit={AccountTypes?.length > 1 ? '' : 'AccountTypes'} className="lg:text-4xl md:text-2xl text-xl font-semibold font-display md:mx-0 mx-8">
            {AccountTypes && <span>{AccountTypes[index]?.DisplayName}</span>}
            {inEditMode && !AccountTypes && <span className="text-gray-400">Click to add rate data</span>}
          </h2>
          <div className="md:mx-0 mx-8" >
            <TableContainer />
          </div>
          <div className="md:ml-2 ml-8">
            {((ButtonText && TemplateName) || inEditMode) && <button className={`focus:outline-none rounded-full font-medium font-body whitespace-nowrap border-2 border-violet hover:border-violet-light text-violet hover:text-violet-light bg-white md:text-base sm:text-sm text-xs py-0.5 px-4 md:py-1 md:px-6 ${loading ? 'cursor-not-allowed' : ''}`}
              data-epi-edit="TemplateName"
              onClick={handlePdfGeneration}>
              {!loading && <>{ButtonText}</>}
              {loading && <SyncSVG className="md:w-4 w-3 my-0.5 animate-spin" />}
            </button>}
            {inEditMode && <span data-epi-edit="ButtonText" className="ml-2 text-gray-400 text-xs">Change button text</span>}
          </div>
        </div>
      </div>
    )
  }

  if (isChild) return <Inner />

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:ml-24 lg:ml-20 md:mx-12 mx-4
                      lg:py-14 sm:py-10 py-7">
        <Inner />
      </div>
    </div>
  )
}

export default React.memo(RateTablewithHoveroverBlock)
