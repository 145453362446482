import React from 'react'

import IconFeaturedBlock from './IconFeaturedBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const CommunityCentricBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      xl:py-18 lg:py-10 md:py-8 py-6">
        <div data-epi-edit="MainContentArea" className={`grid ${MainContentArea && MainContentArea.length >= 4 ? 'lg:grid-cols-4' : ''} grid-cols-2 lg:gap-8 gap-16`}>
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i}>
                <IconFeaturedBlock data={blockData[Id]} inEditMode={inEditMode} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CommunityCentricBlock)
