import React, { useEffect, useState } from 'react'

import RateTablewithHoveroverBlock from './RateTablewithHoveroverBlock'
import { RatesContainerBlock } from 'Blocks/Main/RatesContainerBlock'
import { useQueryParams } from 'Hooks'

import ArrowLeftSVG from 'SVGs/arrow-left.svg'
import ChevronRight from 'SVGs/chevronRight.svg'

const TabSelection = ({ data, activeIndex, setActiveIndex }) => {
  if (data?.length === 0) return null

  return (
    <div className="xl:ml-24 lg:ml-20 md:ml-12 flex items-end border-b-2 border-gray-300  scroll-smooth hover:scroll-auto overflow-visible  hidden-scrollbars overflow-x-scroll whitespace-nowrap lg:text-lg lg:font-normal font-medium font-display text-xs">
      {data?.map((item, i) => (
        <div key={i} onClick={() => setActiveIndex(i)} className={`${i === activeIndex ? 'border-fuchsia nav-shadow-fuchsia' : 'border-transparent'} border-b-2 pb-2 mr-8 ml-2 md:ml-0 hover:text-gray-600 cursor-pointer`}>
          {item}
        </div>
      ))}
    </div>
  )
}

const DesktopTabSelection = ({ tabNames, mainContentArea, desktopActiveIndex, setDesktopActiveIndex, productTableFromUrl }) => {
  if (tabNames?.length === 0) return null

  const [wasTabClicked, setWasTabClicked] = useState(false)

  function onTabNameClicked(tab, index) {
    setDesktopActiveIndex(index)
    setWasTabClicked(true)
  }

  function onKeyDown(e, index) {
    if (e.keyCode === 13) {
      onTabNameClicked(null, index);
    }
  }

  return (
    <>
      <div className="hidden lg:block xl:ml-24 lg:ml-20 md:ml-12 items-end  scroll-smooth hover:scroll-auto overflow-visible hidden-scrollbars whitespace-nowrap lg:text-lg lg:font-normal font-medium font-display text-xs">
        <div className='flex border-b-2 border-gray-300'>
          {tabNames?.map((item, i) => (
            <div tabIndex={0} key={i} onClick={() => onTabNameClicked(item, i)} onKeyDown={(e) => onKeyDown(e, i)} className={`${i === desktopActiveIndex ? 'border-fuchsia' : 'border-transparent'} border-b-2 pb-2 mr-8 ml-2 md:ml-0 hover:text-gray-600 cursor-pointer`}>
              {item}
            </div>
          ))}
        </div>
        <div className="lg:py-8 md:py-7 py-4" data-epi-edit="MainContentArea">
          <div className="">
            {mainContentArea?.length > 0 &&
              <RatesContainerBlock
                data={mainContentArea[desktopActiveIndex]}
                key={desktopActiveIndex}
                isMobile={false}
                productTableFromUrl={productTableFromUrl}
                wasTabClicked={wasTabClicked}
              />}
          </div>
        </div>
      </div>
    </>
  )
}

const MobileTabSelection = ({ tabNames, mainContentArea, mobileActiveIndex, setMobileActiveIndex, selectionMade, setSelectionMade, productTableFromUrl }) => {
  if (tabNames?.length === 0) return null

  function onBackButtonClicked() {
    setSelectionMade(false)
    setMobileActiveIndex(0)
  }

  function onSelectionMadeClicked(tab, index) {
    setSelectionMade(true)
    setMobileActiveIndex(index)
    const parsedUrl = new URL(window.location.href)
    // add to search params
    parsedUrl.searchParams.set('AccountType', tab)
    // update url
    window.history.pushState({}, '', parsedUrl)
  }

  return (
    <div className="block lg:hidden">
      {selectionMade && (
        <div className='bg-[#D3D9FF]'>
          <button className='h-[48px] pl-4 pr-8 text-violet'
            onClick={() => onBackButtonClicked()}
          >
            <div className='flex'>
              <div className='flex items-center'>
                <ArrowLeftSVG className="inline-block mx-2" width="20" height="20" />
              </div>
              <div className='font-semibold'>
                Back
              </div>
            </div>
          </button>
        </div>
      )}
      {!selectionMade && tabNames?.map((tabName, i) => (
        <div className='' key={i}>
          <div
            className='flex justify-between w-full px-4 py-6 border-b-2 border-gray-300 hover:cursor-pointer hover:bg-gray-100'
            onClick={() => onSelectionMadeClicked(tabName, i)}
          >
            <div className='font-bold text-lg'>{tabName}</div>
            <div className='mr-4'>
              <ChevronRight className="w-[11px] text-violet" />
            </div>
          </div>
        </div>
      ))}
      {selectionMade &&
        <RatesContainerBlock
          data={mainContentArea[mobileActiveIndex]}
          isMobile={true}
          tabName={tabNames[mobileActiveIndex]}
          productTableFromUrl={productTableFromUrl} />
      }
    </div>
  )
}

const GroupOfRateTablesBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data
  const queryParams = useQueryParams()

  const [blockType, setBlockType] = useState(MainContentArea?.[0].ContentType.pop())

  const [rateTableWithHoverActiveIndex, setRateTableWithHoverActiveIndex] = useState(0)
  const tabNamesForRateTableWithHover = MainContentArea?.map(x => x.TabName)

  const [desktopActiveIndex, setDesktopActiveIndex] = useState(0)
  const [mobileActiveIndex, setMobileActiveIndex] = useState(0)
  const [selectionMade, setSelectionMade] = useState(false)
  const [isIndexAlreadySet, setIsIndexAlreadySet] = useState(false)
  const tabNames = MainContentArea?.map(x => x.Title)


  useEffect(() => {
    if (!isIndexAlreadySet && queryParams?.AccountType) {
      const index = tabNames.findIndex((tabName) => tabName === queryParams.AccountType) // since the tab name corresponds to the index of the tabNames array, we can use it to set the active index
      setDesktopActiveIndex(index)
      setMobileActiveIndex(index)
      setSelectionMade(true)
      setIsIndexAlreadySet(true)
    }
  }, [])


  return (
    <>
      {blockType === 'RateTablewithHoveroverBlock' &&
        <div className="container mx-auto max-w-screen-xl">
          <TabSelection data={tabNamesForRateTableWithHover} activeIndex={rateTableWithHoverActiveIndex} setActiveIndex={setRateTableWithHoverActiveIndex} />
          <div className="lg:py-10 md:py-7 py-4" data-epi-edit="MainContentArea">
            <div className="xl:mx-24 lg:mx-20 md:mx-12">
              {MainContentArea?.length > 0 && <RateTablewithHoveroverBlock data={MainContentArea[rateTableWithHoverActiveIndex]} isChild={true} key={rateTableWithHoverActiveIndex} />}
            </div>
          </div>
        </div>
      }

      {blockType === 'RatesContainerBlock' &&
        <div className="container mx-auto max-w-screen-xl">
          <DesktopTabSelection
            tabNames={tabNames}
            mainContentArea={MainContentArea}
            desktopActiveIndex={desktopActiveIndex}
            setDesktopActiveIndex={setDesktopActiveIndex}
            productTableFromUrl={queryParams.Product}
          />

          <MobileTabSelection
            tabNames={tabNames}
            mainContentArea={MainContentArea}
            mobileActiveIndex={mobileActiveIndex}
            setMobileActiveIndex={setMobileActiveIndex}
            selectionMade={selectionMade}
            setSelectionMade={setSelectionMade}
            productTableFromUrl={queryParams.Product}
          />
        </div>
      }
    </>
  )
}

export default React.memo(GroupOfRateTablesBlock)