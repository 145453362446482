import React from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import LegalCareersCardBlock from './LegalCareersCardBlock'

const LegalCareersBlock = ({ data, inEditMode }) => {
  const { Image, MainContentArea } = data

  if (!MainContentArea && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-28 lg:mx-24 md:mx-20 sm:mx-16 mx-10 lg:py-20 md:py-16 sm:py-12 py-8 flex lg:gap-x-16 md:gap-x-12 sm:gap-x-8 gap-x-4 ">
      <div className="w-full h-full ">
        <div data-epi-edit="Image" className="w-full h-full flex justify-between">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block" width="359" height="518" />
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden" width="200" height="400" />
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
      </div>
      <div data-epi-edit="MainContentArea" className="grid grid-cols-2 lg:gap-y-20 md:gap-y-8 sm:gap-y-8 gap-y-2 xl:gap-x-20 lg:gap-x-16 sm:gap-x-10 gap-x-2">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <LegalCareersCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(LegalCareersBlock)
