import React, { useState } from 'react'

import { RouterLinkTemplate } from 'Components'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock'
import { addWebPToImageURL } from 'Helpers/utilities'

const LoanCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, ContentLink, Url, LoginPopupMemberNonmemberReference } = data
  const { Id } = ContentLink

  const [openModal, setOpenModal] = useState(false)

  function onLoanCardClicked() {
    setOpenModal(true)
  }

  function onModalClosed() {
    setOpenModal(false)
  }

  const Layout = () => {
    return (
      <div data-epi-block-id={Id}>
        <div className="group bg-white shadow-normal hover:shadow-active cursor-pointer rounded-lg md:p-5 p-2 gap-2 lg:h-40 md:h-32 h-20 grid justify-items-center items-center">
          <div data-epi-edit="Image">
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="35" height="35" />
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="15" height="15" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          <h4 data-epi-edit="Heading"
            className="font-display font-medium text-center xl:text-xl lg:text-lg text-xs md:leading-6 text-gray-800 group-hover:text-black" >
            {Heading}
          </h4>
        </div>
      </div>
    )
  }

  if (!Url && !LoginPopupMemberNonmemberReference) return <Layout />

  if (!Url && LoginPopupMemberNonmemberReference) {
    return (
      <>
        <button onClick={() => onLoanCardClicked()}>
          <Layout />
        </button>
        {openModal &&
            <LoginPopupMemberNonmemberFormBlock
              onClose={() => onModalClosed()}
              data={LoginPopupMemberNonmemberReference}
              name='LoginPopupMemberNonmemberReference'
              openModalWithExistingContent={openModal}
              hideButton={true} />
          }
      </>
    )
  }

  return (
    <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
      <Layout />
    </RouterLinkTemplate>
  )
}

export default React.memo(LoanCardBlock)
