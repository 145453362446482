import React, { useEffect } from 'react'
import './BlogFormBlock.css'

import formRenderingService from 'Helpers/formRenderingService'
import { useQueryParams } from 'Hooks'
import api from 'Helpers/api'

function insertAfter (referenceNode, newNode) {
  if (referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }
}

const RenderTemplate = (formModel, ele) => {
  const formRendered = formRenderingService.render(formModel, ele)
  if (!formRendered) return

  const submitButton = document.querySelector('.BlogFormBlock .Form__Element.FormSubmitButton')
  const formSection = document.querySelector('.BlogFormBlock section.Form__Element')
  const description = document.querySelector('.BlogFormBlock .Form__Description')
  insertAfter(formSection, submitButton)
  insertAfter(formSection, description)
  description.classList.add('font-body')
}

const BlogFormBlock = ({ data, inEditMode }) => {
  const { Title, FormModel, ContentLink } = data
  const queryParams = useQueryParams()

  useEffect(() => {
    const element = document.getElementById('formContainer_' + ContentLink.Id)
    if (FormModel) {
      RenderTemplate(FormModel, element)
      return
    }

    api.getContentByContentLink(ContentLink.Id, queryParams)
      .then(res => RenderTemplate(res.FormModel, element))
      .catch(err => console.log(err))
  }, [])

  return (
    <div className="container mx-auto max-w-screen-md BlogFormBlock">
      <div className="lg:py-10 md:py-7 py-4
                      md:px-8 px-12
                      grid gap-8">
        <h2 className="font-display font-medium lg:text-4xl md:text-2xl text-xl" data-epi-edit="Title">{Title}</h2>
        <span data-epi-edit="ElementsArea" className="font-display">
          <div id={'formContainer_' + ContentLink.Id}></div>
          {inEditMode && 'Click to edit Form elements'}
        </span>
        {inEditMode && <p><span className="font-body text-gray-500" data-epi-edit="Description">Edit description</span></p>}
      </div>
    </div>
  )
}

export default React.memo(BlogFormBlock)
