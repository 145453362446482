import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { Markdown } from 'Components'

const BenefitsInTabsCardBlock = ({ data, inEditMode }) => {
  const { Heading, Image, Content, Button } = data

  return (
    <>
      <h2 className="lg:text-4xl md:text-2xl text-xl font-semibold font-display lg:py-6 pb-6" data-epi-edit="Heading">
        {Heading}
        {inEditMode && !Heading && 'Click to edit Heading'}
      </h2>
      <div className="group bg-white shadow-normal hover:shadow-active cursor-pointer rounded-lg md:p-12 p-6 gap-6 grid justify-items-center grid-cols-12">
        <div className="lg:col-span-6 sm:col-span-5 col-span-4 grid md:gap-6 gap-4 place-items-center">
          <img data-src={Image?.Url} alt={Image?.AltTag} data-epi-edit="Image" width="180" height="90" className='lazy' />
          <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
        </div>
        <div className="lg:col-span-6 sm:col-span-7 col-span-8">
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        </div>
      </div>
    </>
  )
}

export default React.memo(BenefitsInTabsCardBlock)
