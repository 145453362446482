import React from 'react'

import { SearchBar } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'


const ChildHeroBannerBlock = ({ data, name, inEditMode, prefillSearch, hideSearchInput, isSearchPage, searchCategories }) => {
  const { Heading, Subheading, Image, SearchPage, SearchText, CtaLink, CtaLink2, LoginPopupMemberNonmemberReference1, LoginPopupMemberNonmemberReference2 } = data
  if (!Heading && !Subheading && !Image) {
    if (inEditMode) return <div data-epi-edit={name}>Click to edit {name}</div>
    return null
  }

  const Link = CtaLink?.Value[0]

  const HeadingRenderer = () => {
    return (
      <h1 className="lg:text-6xl md:text-3xl text-2xl font-medium font-display tracking-tightest">
        <span data-epi-edit="Heading">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </span>
      </h1>
    )
  }

  const SubheadingRenderer = () => {
    if (Subheading || inEditMode) {
      return (
        <h3 className="lg:mt-10 lg:text-2xl text-sm font-display tracking-tight">
          <span data-epi-edit="Subheading">
            {Subheading}
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h3>
      )
    }
    return null
  }

  const ImageRenderer = () => {
    return (
    <div data-epi-edit="Image" className="p-4 lg:py-0 w-full">
      <img src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="640" height="400" className="w-full"/>
      {inEditMode && !Image && 'Click to edit Image'}
    </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div data-epi-edit={name} className="xl:py-18 lg:py-10 md:py-8 py-6
                                          xl:pl-24 lg:pl-20 md:px-12 sm:px-8 px-6 
                                          sm:grid sm:grid-cols-2 gap-6 mb-6">
          <div className="relative">
            <HeadingRenderer/>
            <div className={`sm:grid ${!isSearchPage ? 'sm:grid-cols-1' : ''} gap-2 mt-4 lg:mt-0`}>
              <div>
                <SubheadingRenderer/>
                {isSearchPage && (
                <span className="sm:hidden"><ImageRenderer/></span>
                )}
                <div className={`mt-3 mb-3 md:mt-8 lg:mb-0 w-full ${isSearchPage ? 'lg:absolute lg:bottom-2' : ''}`}>
                  <SearchBar action={SearchPage} placeHolder={SearchText} q={prefillSearch} name="SearchText" button={Link} buttonName="CtaLink"
                    button2={CtaLink2} buttonName2="CtaLink2" hideSearchInput={hideSearchInput} isSearchPage={isSearchPage} searchCategories={searchCategories}
                    loginButton1={LoginPopupMemberNonmemberReference1} loginButton2={LoginPopupMemberNonmemberReference2} />
                </div>
              </div>
              {!isSearchPage && (
              <span className="sm:hidden"><ImageRenderer/></span>
              )}
            </div>
          </div>
          <span className="hidden sm:block"><ImageRenderer/></span>
      </div>
    </div>
  )
}

export default React.memo(ChildHeroBannerBlock)
