import React from 'react'
import { StaticRouter } from 'react-router-dom/server'
import { BrowserRouter } from 'react-router-dom'

import BlockPreviewComponent from './BlockPreviewComponent'

import './main.css'

export default function RootBlockPreviewComponent ({ contentLinkId, context, location }) {
  // This code block is for blockPreview
  if (typeof window === 'undefined') {
    return (
      <StaticRouter {...{ context, location }}>
        <BlockPreviewComponent {...{ contentLinkId }} />
      </StaticRouter>
    )
  }

  return (
    <BrowserRouter>
      <BlockPreviewComponent {...{ contentLinkId }} />
    </BrowserRouter>
  )
}
