import React from 'react'

import { Markdown } from 'Components'
import VideoCardBlock from './VideoCardBlock'

const VideoGuideWithContentBlock = ({ data, inEditMode }) => {
  const { Heading, Content, Card, CardLocation } = data

  const ContentContainer = () => {
    return (
      <div className="lg:pt-4 md:pt-2">
        <h3 className="lg:text-3xl text-xl font-semibold font-display">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h3>
        <div className="lg:py-10 pt-5">
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        </div>
      </div>
    )
  }

  const Layout = () => {
    if (CardLocation === 'right') {
      return (
        <>
          <div className="col-span-5">
            <ContentContainer/>
          </div>
          <div className="col-span-7">
            <VideoCardBlock data={Card} name="Card" inEditMode={inEditMode} size="lg" />
          </div>
        </>
      )
    }

    return (
      <>
        <div className="col-span-7">
          <VideoCardBlock data={Card} name="Card" inEditMode={inEditMode} size="lg" />
        </div>
        <div className="col-span-5">
          <ContentContainer/>
        </div>
      </>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-48 lg:mx-40 md:mx-12 sm:mx-8 mx-12
                      lg:py-10 md:py-7 py-4
                      sm:grid hidden sm:grid-cols-12 md:gap-12 gap-8">
        <Layout/>
      </div>
      <div className="xl:mx-48 lg:mx-40 md:mx-12 sm:mx-8 mx-12
                      lg:py-10 md:py-7 py-4
                      sm:hidden grid gap-12">
        <ContentContainer/>
        <VideoCardBlock data={Card} name="Card" inEditMode={inEditMode} size="lg" />
      </div>
      {inEditMode && (
        <div className="xl:mx-48 lg:mx-40 md:mx-12 sm:mx-8 mx-12
                        text-sm text-gray-500">
          <div className="inline-block" data-epi-edit="CardLocation">Change card location option</div>
        </div>
      )}
    </div>
  )
}

export default React.memo(VideoGuideWithContentBlock)
