import React, { useState } from 'react'

import LoginModal from './LoginModal'
import { RoundedButtonBlock } from 'Blocks'

const LoginButtonRenderer = ({ data, form, name, formName, inEditMode }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  return (
    <>
      <div onClick={() => setIsLoggingIn(true)}>
        <RoundedButtonBlock data={data} name={name} inEditMode={inEditMode} />
      </div>
      <LoginModal isOpen={isLoggingIn} onClose={() => setIsLoggingIn(false)} data={form} name={formName} inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(LoginButtonRenderer)
