import React, { useEffect, useState } from 'react'
import ResponsiveEmbed from 'react-responsive-embed'

const VideoModal = ({ data, isOpen, onClose }) => {
  if (!isOpen) return null

  const [open, setOpen] = useState(isOpen)
  function downHandler (e) {
    if (e.keyCode === 27) {
      closeModal()
    }
  }

  const closeModal = () => {
    setOpen(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  if (!open) return null

  return (
    <div style={{ zIndex: 10000000000 }} className="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-2">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => closeModal()}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-transparent rounded-lg text-left overflow-hidden shadow-xl transform transition-all
                        my-8 sm:align-middle lg:max-w-screen-xl sm:max-w-screen-md w-full">
          <button className="bg-white hover:bg-gray-200 py-2 px-4 rounded cursor-pointer absolute top-3 right-3 z-10" onClick={() => closeModal()}>
            <span className="md:text-4xl md:leading-9 leading-4 text-center text-violet">&times;</span>
          </button>
          <ResponsiveEmbed src={`${data}${data.includes('?') ? '&' : '?'}autoplay=1`} allow="autoplay" frameBorder="0" allowFullScreen />
        </div>
      </div>
    </div>
  )
}

export default React.memo(VideoModal)
