import React, { useState } from 'react'

import PlusSVG from 'SVGs/plus.svg'

const FaQRenderer = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  if (!data || data.length === 0) return null

  function handleClick (e, index) {
    e.preventDefault()
    if (index === activeIndex) {
      setActiveIndex(null)
      return
    }
    setActiveIndex(index)
  }

  return (
    <>
      {data.map(({ Question, Answer }, i) => {
        const markdown = { __html: Answer }
        return (
          <div key={i} className="border-b-2 border-b-violet mb-6">
            <div className="pb-6">
              <div className='flex justify-between font-display font-semibold lg:text-xl text-violet hover:text-violet-dark hover:cursor-pointer'>
                <div className="" onClick={(event) => handleClick(event, i)}>{Question}</div>
                <div className="ml-8" onClick={(event) => handleClick(event, i)}>
                  {activeIndex === i ? <PlusSVG className="w-6 rotate-45" /> : <PlusSVG className="w-6" />}
                </div>
              </div>
              <div className={`mt-4 transition-height duration-100 font-display font-medium text-sm lg:text-base ${activeIndex === i ? 'animate-fade-in-down opacity-1 h-auto' : 'opacity-0 h-0 hidden'} lg:prose prose-sm`} dangerouslySetInnerHTML={markdown} />
            </div>
          </div>
        )
      })}
    </>
  )
}

const CommonQuestionsBlock = ({ data, inEditMode }) => {
  const { FrequentlyAskedQuestions } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                   lg:py-6 md:py-2">
        <span data-epi-edit="FrequentlyAskedQuestions">
          <FaQRenderer data={FrequentlyAskedQuestions} />
          {inEditMode && !FrequentlyAskedQuestions && 'Click to edit FAQs'}
        </span>
      </div>
    </div>
  )
}

export default React.memo(CommonQuestionsBlock)
