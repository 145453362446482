import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import Helmet from 'react-helmet'

import RootComponent from './RootComponent'
import RootSearchPageComponent from './RootSearchPageComponent'
import RootBlockPreviewComponent from './RootBlockPreviewComponent'

global.React = React
global.ReactDOM = ReactDOM
global.ReactDOMServer = ReactDOMServer
global.Helmet = Helmet

global.Components = { RootComponent, RootSearchPageComponent, RootBlockPreviewComponent }
