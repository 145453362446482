import React from 'react'

import { Markdown } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const IntroToProductBlock = ({ data, inEditMode }) => {
  const { Heading, Image, ImageLocation, Content, BulletList } = data

  const ContentContainer = () => {
    return (
      <>
        <h2 className="lg:text-4xl text-xl font-medium font-display pb-3">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h2>
        <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        <ListDesktop/>
      </>
    )
  }

  const ImageContainer = () => {
    return (
      <>
        <div data-epi-edit="Image" className="md:py-0 py-6">
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="350" height="335" className='lazy'/>
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
        {inEditMode && (
          <div className="mt-5 text-sm text-gray-500" data-epi-edit="ImageLocation">Change image location option</div>
        )}
      </>
    )
  }

  const ListDesktop = () => {
    return (
      <div className="md:block hidden pt-2">
        <Markdown data={BulletList} name='BulletList' className="intro-to-product-list" inEditMode={inEditMode}/>
      </div>
    )
  }

  const ListMobile = () => {
    return (
      <div className="md:hidden pb-4 mx-6">
        <Markdown data={BulletList} name='BulletList' className="intro-to-product-list" inEditMode={inEditMode}/>
      </div>
    )
  }

  const contentContainerClass = 'col-span-7 flex flex-col self-center'
  const imageContainerClass = 'justify-self-center col-span-5 lg:p-8'

  const Layout = () => {
    if (ImageLocation === 'right') {
      return (
        <>
          <div className={`${contentContainerClass}`}>
            <ContentContainer/>
          </div>
          <div className={`${imageContainerClass} lg:justify-self-end`}>
            <ImageContainer/>
          </div>
        </>
      )
    }

    return (
      <>
        <div className={`${imageContainerClass} lg:justify-self-start`}>
          <ImageContainer/>
        </div>
        <div className={`${contentContainerClass}`}>
          <ContentContainer/>
        </div>
      </>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4
                      grid grid-cols-12 lg:gap-4 gap-6 justify-items-stretch">
        <Layout/>
      </div>
      <ListMobile/>
    </div>
  )
}

export default React.memo(IntroToProductBlock)
