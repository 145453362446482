import React, { useState } from 'react'

import { BenefitsInTabsCardBlock } from '.'

const BenefitsInTabsBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data

  const [index, setIndex] = useState(0)
  const headings = MainContentArea?.map(x => x.Heading)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="grid lg:grid-cols-12 gap-6" data-epi-edit="MainContentArea">
          <div className="lg:col-span-3 flex lg:flex-col gap-6">
            {headings?.length > 0 && headings.map((item, i) => {
              return <span key={i} onClick={() => setIndex(i)} className={`font-display text-base cursor-pointer hover:text-violet-dark ${i === index ? 'text-violet font-semibold' : 'font-medium'}`}>{item}</span>
            })}
          </div>
          <div className="lg:col-span-9">
            {MainContentArea?.length > 0 && <BenefitsInTabsCardBlock data={MainContentArea[index]}/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(BenefitsInTabsBlock)
