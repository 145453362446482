import React from 'react'

import geocoder from 'google-geocoder'

const SearchBar = ({ data, name, apiKey, onResultFound, inEditMode }) => {
  if (!data && !inEditMode) return null

  const geo = geocoder({
    key: apiKey
  })

  return (
    <div className="relative text-gray-800 focus-within:text-gray-800">
      <span data-epi-edit={name}>
        <input type="search" placeholder={data} size={data?.length + 1} autoComplete="off" onKeyDown={(e) => {
          if (e.key === 'Enter') {
            geo.find(e.target.value, (_err, res) => {
              if (!res) return
              const result = res[0]
              onResultFound(result.location)
            })
          }
        }}
          className="appearance-none shadow-inner placeholder-gray-400 py-2 md:text-base text-sm font-body
            rounded-full border border-gray-300 px-4 focus:outline-none focus:text-gray-800"
        />
      </span>
    </div>
  )
}

export default React.memo(SearchBar)
