import React, { useState } from 'react'

import SyncSVG from 'SVGs/sync.svg'
import api from 'Helpers/api'

const PdfGeneratorLinkBlock = ({ data, inEditMode }) => {
  const { Text, TemplateName } = data
  const [loading, setLoading] = useState(false)

  function handlePdfGeneration (e) {
    e.preventDefault()
    setLoading(true)
    api.getPdfLink(TemplateName)
      .then(res => {
        setLoading(false)
        setTimeout(() => window.open(res.Response, '_blank'), 200)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6">
        {!loading && <p className="cursor-pointer text-violet font-body text-sm leading-none lg:text-lg lg:leading-5 font-medium" onClick={handlePdfGeneration}>{Text}</p>}
        {loading && <SyncSVG className="md:w-4 w-3 animate-spin text-violet" />}
      </div>
    </div>
  )
}

export default React.memo(PdfGeneratorLinkBlock)
