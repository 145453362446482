import React from 'react'

const LegalPageCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading } = data

  return (
    <div>
          <h2 className="text-left xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-base font-semibold" data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </h2>
          <h5 className="xl:text-lg lg:text-base md:text-xs sm:text-xs text-xxs xl:mt-10 lg:mt-8 md:mt-6 sm:mt-4 mt-3" data-epi-edit="Subheading">
              {Subheading}
              {inEditMode && !Subheading && 'Click to edit Subheading'}
          </h5>
        </div>
  )
}

export default React.memo(LegalPageCardBlock)
