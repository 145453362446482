import React from 'react'
import { RoundedButtonBlock } from 'Blocks'
import { RouterLinkTemplate } from 'Components'

const CTARenderer = ({ option, link, btn, inEditMode }) => {
  const CTAOption = () => {
    if (!inEditMode) return null
    return (
      <div className="mt-5 text-sm text-gray-500" data-epi-edit="CtaOption">
        Click to change CTA option
      </div>
    )
  }
  if (option === 'none') { return <CTAOption /> }
  if (option === 'button') {
    return (
      <>
        <RoundedButtonBlock data={btn} name="CtaButton" inEditMode={inEditMode} />
        <CTAOption />
      </>
    )
  }
  return (
    <>
      {link && (
        <RouterLinkTemplate Path={link.Href} inEditMode={inEditMode}>
          <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light"> {link.Text} </span>
        </RouterLinkTemplate>
      )}
      {!link && inEditMode && (
        <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet"> Click to edit Link</span>
      )}
      <CTAOption />
    </>
  )
}

const LegalPageContentBlock = ({ data, inEditMode }) => {
  const { HeadingOptions, HeadingfontSize, Heading, Subheading, CtaOption, CtaButton, CtaLink } = data
  const Link = CtaLink.Value[0]

  if (HeadingOptions === 'no') {
    return (
        <div className="container mx-auto max-w-screen-xl">
        <div className="xl:mx-36 lg:mx-28 md:mx-20 sm:mx-16 mx-12 xl:py-16 lg:py-12 md:py-10 sm:py-8 py-4">
            <h5 className="lg:text-lg md:text-base sm:text-xs text-xxs xl:mb-9 lg:mb-7 md:mb-6 sm:mb-5 mb-3" data-epi-edit="Subheading">
                {Subheading}
                {inEditMode && !Subheading && 'Click to edit Subheading'}
            </h5>
            <div>
              <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode} />
            </div>
          </div>
      </div>
    )
  }
  if (HeadingOptions === 'yes') {
    if (HeadingfontSize === 'smallerfont') {
      return (
        <div className="container mx-auto max-w-screen-xl">
          <div className="xl:mx-36 lg:mx-28 md:mx-20 sm:mx-16 mx-12 xl:py-16 lg:py-12 md:py-10 sm:py-8 py-4">
              <h2 className="text-left text-violet-light xl:text-2xl lg:text-xl md:text-lg sm:text-base text-sm font-semibold" data-epi-edit="Heading">
                {Heading}
                {inEditMode && !Heading && 'Click to edit Heading'}
              </h2>
              <h5 className="lg:text-lg md:text-base sm:text-xs text-xxs lg:my-7 md:my-6 sm:my-5 my-3" data-epi-edit="Subheading">
                  {Subheading}
                  {inEditMode && !Subheading && 'Click to edit Subheading'}
              </h5>
              <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode} />
          </div>
        </div>
      )
    }
    return (
      <div className="container mx-auto max-w-screen-xl">
        <div className="xl:mx-36 lg:mx-28 md:mx-20 sm:mx-16 mx-12 xl:py-16 lg:py-12 md:py-10 sm:py-8 py-4">
            <h2 className="text-left xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg font-semibold" data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </h2>
            <h5 className="lg:text-lg md:text-base sm:text-xs text-xxs xl:mt-9 lg:mt-7 md:mt-6 sm:mt-5 mt-3" data-epi-edit="Subheading">
                {Subheading}
                {inEditMode && !Subheading && 'Click to edit Subheading'}
            </h5>
            <CTARenderer className="xl:mt-9 lg:mt-7 md:mt-6 sm:mt-5 mt-3" option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode} />
        </div>
      </div>
    )
  }
}

export default React.memo(LegalPageContentBlock)
