import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const LogoRenderer = ({ data, name, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <div data-epi-edit={name}>Click to edit Logo</div>
  }

  return (
    <div data-epi-edit={name}>
      <RouterLinkTemplate Path={'/'} inEditMode={inEditMode}>
        <img src={addWebPToImageURL(data?.Url)} alt={data?.AltTag} width="300" height="70" className="max-h-24"/>
      </RouterLinkTemplate>
    </div>
  )
}

export default LogoRenderer
