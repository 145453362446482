import React, { useEffect, useState } from 'react'

import BlockComponentSelector from './BlockComponentSelector'
import api from 'Helpers/api'
import { useEpiContentSaved, useInEditMode, useQueryParams } from 'Hooks'

const BlockPreviewComponent = ({ contentLinkId }) => {
  const queryParams = useQueryParams()
  const inEditMode = useInEditMode()

  const [appContext, setAppContext] = useState({ data: null })

  const fetchData = (contentLinkId) => {
    api.getContentByContentLink(contentLinkId, queryParams)
      .then(res => setAppContext({ ...appContext, data: res }))
      .catch(err => console.log(err))
  }

  useEffect(() => fetchData(contentLinkId), [contentLinkId])

  // Editor view events
  useEpiContentSaved((contentLinkId) => fetchData(contentLinkId))

  return <BlockComponentSelector data={appContext.data} inEditMode={inEditMode} />
}

export default React.memo(BlockPreviewComponent)
