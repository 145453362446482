import React, { useEffect, useState, useRef } from 'react'

import TimesSVG from 'SVGs/times.svg'

const LoginPopupMemberNonmemberFormModal = ({ data, isOpen, onClose, inEditMode }) => {
    const { MemberHeading, MemberUsernamePlaceholder, MemberCtaButton1, MemberCtaButton2, ForgotPasswordText,
        NonmemberHeading, NonmemberSubheading, NonmemberCtaButton, ProductLink } = data
    if (!isOpen) return null

    const NonmemberCtaButtonLink = NonmemberCtaButton.Value[0]

    const [open, setOpen] = useState(isOpen)
    const modalRef = useRef(null)

    function downHandler(e) {
        if (e.keyCode === 27) {
            closeModal()
        }
    }

    const closeModal = () => {
        setOpen(false)
        document.body.style.overflow = 'unset'
        if (typeof onClose === 'function') {
            onClose()
        }
    }

    function trapFocus() {
        // makes only the modal elements focusable when it's open. (accessibility feature)
        var focusableElements = modalRef.current.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), input[type="text"]:not([disabled])');
        var firstFocusableElement = focusableElements[0];
        var lastFocusableElement = focusableElements[focusableElements.length - 1];
        modalRef.current.addEventListener('keydown', function (e) {
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }
            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            }
        });
    }

    // called when modal is opened
    useEffect(() => {
        trapFocus();

        document.body.style.overflow = 'hidden'; // prevent scroll when open
        window.addEventListener('keydown', downHandler)
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, [])

    if (!open) return null

    return (
        <div style={{ zIndex: 10000000000 }} className="fixed inset-0 overflow-y-auto backdrop-blur backdrop-filter" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="mt-2 sm:mt-20 md:-mt-14 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-2">
                <div className="fixed inset-0 bg-gray-600 opacity-40" aria-hidden="true" onClick={() => closeModal()}></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div ref={modalRef} className="inline-block bg-white rounded-lg shadow transform max-w-4xl w-full text-left mx-auto font-body font-normal">
                    <div>
                        <div className='flex justify-end py-4 px-4 pb-0'>
                            <button onClick={() => closeModal()}>
                                <TimesSVG className='w-4' />
                            </button>
                        </div>
                        <div className='block md:flex gap-8 lg:py-8 lg:px-12 lg:pt-0 md:px-8 px-8 py-8 pt-0'>
                            <div className='w-full'>
                                <h2 className="mb-4 text-2xl font-display font-semibold" data-epi-edit="MemberHeading">
                                    {MemberHeading}
                                </h2>
                                <form method="POST" action={ProductLink} autoComplete="false">
                                    <input type="text" name="username" data-epi-edit="MemberUsernamePlaceholder" required autoFocus spellCheck="false" autoCorrect="false" autoCapitalize="false" placeholder={MemberUsernamePlaceholder}
                                        className="block border rounded-full font-display placeholder-gray-500 text-gray-500 px-4 py-2 mb-4 w-full" />
                                    <div className='block sm:flex sm:gap-4 my-2'>
                                        <button type="submit" data-epi-edit="MemberCtaButton1" className="block text-lg whitespace-nowrap font-medium font-display bg-violet w-full px-3 py-2 mb-4 sm:mb-0 rounded-full text-white hover:bg-violet-dark hover:shadow-md">
                                            {MemberCtaButton1}
                                        </button>
                                        {MemberCtaButton2 && (
                                            <a data-epi-edit="MemberCtaButton2" href="https://secure.wellbyfinancial.com/enroll" target="_blank" rel="noreferrer" className="text-center text-violet block text-lg whitespace-nowrap font-medium font-display bg-white w-full px-3 py-2 rounded-full hover:shadow-md border-2 border-violet">
                                                {MemberCtaButton2}
                                            </a>
                                        )}
                                    </div>
                                </form>
                                {ForgotPasswordText && <a data-epi-edit="ForgotPasswordText" href="https://secure.wellbyfinancial.com/forgot" target="_blank" rel="noreferrer" className="block my-4 text-violet hover:text-violet-dark font-display font-medium">{ForgotPasswordText}</a>}
                            </div>

                            <hr className='w-auto h-[2px] md:h-auto md:w-[2px] bg-black my-4 lg:my-0' />

                            <div className='w-full'>
                                <h2 className="mb-4 text-2xl font-display font-semibold" data-epi-edit="NonmemberHeading">
                                    {NonmemberHeading}
                                </h2>
                                <div className="text-lg mb-2" data-epi-edit="NonmemberSubheading" dangerouslySetInnerHTML={{ __html: NonmemberSubheading }}></div>
                                {NonmemberCtaButtonLink && (
                                    <div className='flex'>
                                        <a data-epi-edit="NonmemberCtaButton" href={NonmemberCtaButtonLink.Href} target={NonmemberCtaButtonLink.Target} rel="noreferrer" className="text-center text-lg w-full md:w-auto whitespace-nowrap font-medium font-display bg-violet px-8 py-2 rounded-full text-white hover:bg-violet-dark hover:shadow-md my-2">
                                            {NonmemberCtaButtonLink.Text}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(LoginPopupMemberNonmemberFormModal)
