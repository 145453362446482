import React from 'react'

import blocks from 'Blocks'
import { getComponentType } from 'Helpers/transform'

const BlockComponentSelector = ({ data, inEditMode }) => {
  const componentType = getComponentType(data, blocks)
  const DynamicComponent = blocks[componentType || 'Block']

  if (data) {
    if ('BackgroundColor' in data && 'BlockId' in data) {
      const { BackgroundColor, BlockId } = data
      let bgColor
      switch (BackgroundColor) {
        case 'violet-tint':
          bgColor = 'bg-violet-tint'
          break
        case 'blue-tint':
          bgColor = 'bg-blue-tint'
          break
        case 'emerald-tint':
          bgColor = 'bg-emerald-tint'
          break
        case 'white':
          bgColor = 'bg-white'
          break
        default:
          bgColor = 'bg-transparent'
          break
      }
      return (
        <>
          <div id={BlockId?.replaceAll(' ', '')} className={bgColor}>
            <DynamicComponent data={data} inEditMode={inEditMode} />
          </div>
          {inEditMode && (
            <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                            my-8">
              <span className="text-gray-500" data-epi-edit="BackgroundColor">Change container background color</span>
            </div>
          )}
        </>
      )
    }
  }

  return <DynamicComponent data={data} inEditMode={inEditMode} />
}

export default React.memo(BlockComponentSelector)
