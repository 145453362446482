import React from 'react'

const dateFormat = (date) => date.toISOString().replace(/-|:|\.\d\d\d/g, '')

const formatAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const getMonthName = (date) => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()]

const DateTimeRenderer = ({ start, startName, end, endName }) => {
  if (start === end && start.getHours() === 0 && start.getMinutes() === 0) {
    return <>
      <span data-epi-edit={startName}>{getMonthName(start)} {start.getDate()}, {start.getFullYear()}</span>
      <span data-epi-edit={endName}> </span>
    </>
  }

  if (start === end) {
    return <>
      <span data-epi-edit={startName}>{formatAMPM(start)} on {getMonthName(start)} {start.getDate()}, {start.getFullYear()}</span>
      <span data-epi-edit={endName}> </span>
    </>
  }

  if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
    return <span><span data-epi-edit={startName}>{formatAMPM(start)}</span> - <span data-epi-edit={endName}>{formatAMPM(end)}</span> on {getMonthName(start)} {start.getDate()}, {start.getFullYear()}</span>
  }

  return <>
    <span data-epi-edit={startName}>{getMonthName(start)} {start.getDate()}{start.getFullYear() === end.getFullYear() ? '' : `, ${start.getFullYear()}`}</span> - <span data-epi-edit={endName}>{getMonthName(end)} {end.getDate()}, {end.getFullYear()}</span>
  </>
}

const HolidayEventCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, StartDate, EndDate, LinkText } = data

  const startDate = new Date(StartDate)
  const endDate = EndDate ? new Date(EndDate) : startDate
  const hrefString = `http://www.google.com/calendar/render?action=TEMPLATE&text=${Heading}&dates=${dateFormat(startDate)}/${dateFormat(endDate)}&details=${Subheading}`

  return (
    <div className="border-b border-gray-400">
      <div className="flex justify-between items-center xl:mt-6 lg:mt-5 md:mt-4 sm:mt-3 mt-2">
        <h2 className="xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-lg font-semibold " data-epi-edit="Heading">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <h4 suppressHydrationWarning className="xl:text-2xl lg:text-xl md:text-lg sm:text-base text-sm font-semibold">
          <DateTimeRenderer start={startDate} startName="StartDate" end={endDate} endName="EndDate" />
        </h4>
      </div>
      <div className="flex justify-between lg:mt-4 md:mt-3 sm:mt-2 mt-1 xl:mb-7 lg:mb-6 md:mb-5 sm:mb-4 mb-2">
        <p className="lg:text-sm md:text-xs text-xxs lg:max-w-sm" data-epi-edit="Subheading">
          {Subheading}
          {inEditMode && !Subheading && 'Click to edit Subheading'}
        </p>
        <a className="xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs font-medium text-violet" data-epi-edit="LinkText"
          suppressHydrationWarning href={hrefString} target="_blank" rel="noreferrer">
          {LinkText || 'Add to Calendar'}
          {inEditMode && !LinkText && 'Click to edit link text'}
        </a>
      </div>
    </div>
  )
}

export default React.memo(HolidayEventCardBlock)
