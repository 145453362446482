import React, { useEffect, useState } from 'react'

import './RateTableBlock.css'

import { Tooltip } from 'Components'
import InfoCircleSquigglySVG from 'SVGs/infoCircleSquiggly.svg'

const RatesTableBlock = ({ data, inEditMode }) => {
  const { BodyData, HeaderData, Title } = data
  const [filteredColumnKeys, setFilteredColumnKeys] = useState([])

  let allColumns = null;
  let bodyData = null;

  // check if HeaderData.Value is not null before trying to access the first index
  if (HeaderData.Value !== null && HeaderData.Value.length > 0) {
    allColumns = TransformHeaderDataAndGetAllColumns(HeaderData.Value[0], BodyData.Value)
  }

  if (BodyData.Value !== null && BodyData.Value.length > 0) {
    bodyData = BodyData.Value
  }

  // every time the data changes, we want to get the filtered column keys for the new table data
  useEffect(() => {
    getFilteredColumnKeys(HeaderData.Value[0])
  }, [data])

  function getFilteredColumnKeys(firstColumnHeaderTitles) {
    let columnHeaderTitleKeys = Object.keys(firstColumnHeaderTitles);
    // return keys that contain the word Title to get an array of only the column titles
    columnHeaderTitleKeys = columnHeaderTitleKeys.filter(key => key.includes('Title'))

    let filteredColumnKeys = columnHeaderTitleKeys.filter(key => firstColumnHeaderTitles[key] !== '')
    filteredColumnKeys = filteredColumnKeys.map((key) => key.substring(0, 7))

    setFilteredColumnKeys(filteredColumnKeys)
  }

  function TransformHeaderDataAndGetAllColumns(firstColumnHeaderTitles, bodyData) {
    let columnHeaderTitleKeys = Object.keys(firstColumnHeaderTitles);
    // return keys that contain the word Title to get an array of only the column titles
    columnHeaderTitleKeys = columnHeaderTitleKeys.filter(key => key.includes('Title'))

    let filteredColumnKeys = columnHeaderTitleKeys.filter(key => firstColumnHeaderTitles[key] !== '')
    filteredColumnKeys = filteredColumnKeys.map((key) => key.substring(0, 7))

    // object to hold the column titles
    let columnHeaderTitlesArray;

    // loop through the keys and return only if the column title has a value
    columnHeaderTitlesArray = filteredColumnKeys.map((item, index) => {
      const ColumnTitle = `Column${index + 1}Title`
      const ColumnTooltip = `Column${index + 1}Tooltip`

      let columnData = bodyData?.map((item) => {
        let columnDataArray = []
        filteredColumnKeys.forEach((key, i) => {
          columnDataArray.push(item[`${key}Value`])
        })
        return columnDataArray
      })

      return {
        Title: firstColumnHeaderTitles[`${ColumnTitle}`],
        Tooltip: firstColumnHeaderTitles[`${ColumnTooltip}`],
        ColumnData: columnData,
      }
    })

    return columnHeaderTitlesArray;
  }

  return (
    <div>
      <table className={`w-full bg-white text-xs whitespace-normal`}> {/*we can add table-fixed class if we want the columns to all be fixed width */}
        <thead className='text-white font-bold text-left'>
          <tr>
            {allColumns !== null && allColumns.length > 0 && allColumns.map((item, i) => (
              <th
                className={`bg-violet py-5 px-4 whitespace-normal ${i === 0 ? 'rounded-tl-lg' : ''} ${i === allColumns.length - 1 ? 'rounded-tr-lg' : ''}`}
                key={i}
              >
                <div className='flex gap-4'>
                  <div>
                    {item.Title}
                  </div>
                  {item.Tooltip && (
                    <div className='flex items-center'>
                      <Tooltip tooltipText={item.Tooltip}>
                        <InfoCircleSquigglySVG className="h-4" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <>
            {bodyData !== null && bodyData.length > 0 && bodyData.map((item, i) => {
              let backgroundColor = i % 2 === 0 ? 'bg-white' : 'bg-[#D3D9FF] bg-opacity-40'
              return (
                <tr key={i} className={`${backgroundColor}`}>
                  {filteredColumnKeys.map((columnKey, i) => {
                    const value = item[`${columnKey}Value`]
                    return (
                      <td key={i}
                        className='override-ul-list py-5 px-4'
                      >
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </>
        </tbody>
      </table>
    </div>
  )
}

export default React.memo(RatesTableBlock)