import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock.jsx'
import { Markdown } from 'Components'
import BlockComponentSelector from '../../BlockComponentSelector'

const OfferInfoForm = ({ data, inEditMode }) => {
  const { Heading, InfoContent, CtaButton1, CtaButton2, LoginPopupMemberNonmemberReference1, LoginPopupMemberNonmemberReference2, Form } = data
  const showLoginButton1Instead = CtaButton1?.Url === null
  const showLoginButton2Instead = CtaButton2?.Url === null

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="grid md:grid-cols-12 md:gap-12 gap-8">
          <div className="md:col-span-6">
            <h2 data-epi-edit="Heading" className="lg:text-4xl md:text-2xl text-xl font-display">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </h2>
            <div className="md:my-8 my-6">
              <Markdown data={InfoContent} name='InfoContent' inEditMode={inEditMode} />
            </div>
            <div className="flex flex-wrap gap-4">
              {showLoginButton1Instead ?
                LoginPopupMemberNonmemberReference1 && <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference1} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} />
                :
                <RoundedButtonBlock data={CtaButton1} name="CtaButton1" inEditMode={inEditMode} />}
              {showLoginButton2Instead ?
                LoginPopupMemberNonmemberReference2 && <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference2} name='LoginPopupMemberNonmemberForm2' inEditMode={inEditMode} />
                :
                <RoundedButtonBlock data={CtaButton2} name="CtaButton2" inEditMode={inEditMode} />}
            </div>
          </div>
          <div className="md:col-span-6" data-epi-edit="Form">
            <BlockComponentSelector data={Form} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(OfferInfoForm)
