import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import LazyLoad from 'vanilla-lazyload'

import api from 'Helpers/api'
import { AppContext } from './contexts'
import { HeaderBlock, FooterBlock, ExitSiteSettingsBlock } from 'Blocks'
import PageComponentSelector from './PageComponentSelector'
import { HiddenLinks, MetaData, NProgress, ScrollToTop } from 'Components'
import { useCopyContent, useEpiContentSaved, useQueryParams, useInEditMode } from 'Hooks'

export default function App({ data, header, footer, isAnnouncementHidden, isSecondaryAnnouncementHidden, defaultHeader, defaultFooter, exitSiteSettings }) {
  const location = useLocation()
  const queryParams = useQueryParams()
  const inEditMode = useInEditMode()
  let lazyLoadInstance

  const [appContext, setAppContext] = useState({ data, isLoading: false, header: header, footer: footer, defaultHeader: defaultHeader, defaultFooter: defaultFooter })
  const value = { appContext, setAppContext }

  const exitSiteSettingsData = exitSiteSettings;
  const [showExitSiteSettingsBlock, setShowExitSiteSettingsBlock] = useState(false);
  const [externalLinkToOpen, setExternalLinkToOpen] = useState('#');
  const globalClickEventHandlerRef = useRef(null);

  // Editor view events
  useEpiContentSaved((contentLink) => {
    api.getContentByContentLink(contentLink, queryParams)
      .then(res => setAppContext({ ...appContext, data: res }))
      .catch(err => console.log(err))
  })

  useCopyContent()

  function initializeVanillaLazyLoad() {
    lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
      data_src: 'src',
      data_srcset: 'srcset',
      use_native: true,
    })

    const lazyLoadInstanceBackgroundImages = new LazyLoad({ // needed to lazy load css background images
    })
  }

  useEffect(() => {
    initializeVanillaLazyLoad()

    globalClickEventHandlerRef.current = (e) => { // single instance so react knows which click event to remove after it's attached
      if (!exitSiteSettingsData) {
        return;
      }
      var currentTarget = e.currentTarget;
      var currentTargetHref = currentTarget.getAttribute('href');
  
      if (currentTargetHref && currentTargetHref.startsWith('http')) {
        const currentTargetUrl = new URL(currentTargetHref);
        const currentTargetDomain = currentTargetUrl.hostname;
        const exclusionList = exitSiteSettingsData?.ExclusionList?.Value;

        // if the currently clicked link is in the exclusion list, don't show the exit site settings block
        if (exclusionList && exclusionList.some(exclusion => exclusion.indexOf(currentTargetDomain) !== -1)) {
          return;
        }

        e.preventDefault();
        setExternalLinkToOpen(currentTargetHref);
        setShowExitSiteSettingsBlock(true);
      }
    }
    addGlobalClickEventToLinksForExitSiteModal();
  }, [])

  function addGlobalClickEventToLinksForExitSiteModal(removeCurrentClickEvents = false) {
    if (removeCurrentClickEvents) {
      const linksToRemoveGlobalClickEvent = document.querySelectorAll('a[href^="http"]');
      linksToRemoveGlobalClickEvent.forEach(link => {
        link.removeEventListener('click', globalClickEventHandlerRef.current);
      });
    }

    const linksToAttachGlobalClickEvent = document.querySelectorAll('a[href^="http"]');
    linksToAttachGlobalClickEvent.forEach(link => {
      link.addEventListener('click', globalClickEventHandlerRef.current);
    });
  }

  function onPageChanged() {
    initializeVanillaLazyLoad()
    addGlobalClickEventToLinksForExitSiteModal(true);
  }

  return (
    <AppContext.Provider value={value}>
      {showExitSiteSettingsBlock && <ExitSiteSettingsBlock data={exitSiteSettingsData} externalLinkToOpen={externalLinkToOpen} isOpen={showExitSiteSettingsBlock} onClose={() => setShowExitSiteSettingsBlock(false)} />}
      <NProgress isAnimating={appContext.isLoading} key={location.key} />
      <MetaData />
      <div data-epi-edit="Header">
        <HeaderBlock data={appContext.header} isAnnouncementHidden={isAnnouncementHidden} isSecondaryAnnouncementHidden={isSecondaryAnnouncementHidden} />
      </div>
      <Routes>
        <Route path="*" element={
          <PageComponentSelector inEditMode={inEditMode} onPageChanged={onPageChanged} />
        }>
        </Route>
      </Routes>
      <div data-epi-edit="Footer">
        <FooterBlock data={appContext.footer} />
      </div>
      <HiddenLinks />
      <ScrollToTop />
    </AppContext.Provider>
  )
}
