import React, { useState } from 'react'

import HighlightedTipsCardBlock from './HighlightedTipsCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const getColStartColEndClassName = (colStart, colEnd) => {
  let gridColStart
  switch (colStart) {
    case 2:
      gridColStart = 'col-start-2'
      break
    case 3:
      gridColStart = 'col-start-3'
      break
    case 4:
      gridColStart = 'col-start-4'
      break
    case 5:
      gridColStart = 'col-start-5'
      break
    case 6:
      gridColStart = 'col-start-6'
      break
    case 7:
      gridColStart = 'col-start-7'
      break
    case 8:
      gridColStart = 'col-start-8'
      break
    case 9:
      gridColStart = 'col-start-9'
      break
    case 10:
      gridColStart = 'col-start-10'
      break
    case 11:
      gridColStart = 'col-start-11'
      break
    case 12:
      gridColStart = 'col-start-12'
      break
    case 13:
      gridColStart = 'col-start-13'
      break
    default:
      gridColStart = 'col-start-1'
      break
  }

  let gridColEnd
  switch (colEnd) {
    case 1:
      gridColEnd = 'col-end-1'
      break
    case 2:
      gridColEnd = 'col-end-2'
      break
    case 3:
      gridColEnd = 'col-end-3'
      break
    case 4:
      gridColEnd = 'col-end-4'
      break
    case 5:
      gridColEnd = 'col-end-5'
      break
    case 6:
      gridColEnd = 'col-end-6'
      break
    case 7:
      gridColEnd = 'col-end-7'
      break
    case 8:
      gridColEnd = 'col-end-8'
      break
    case 9:
      gridColEnd = 'col-end-9'
      break
    case 10:
      gridColEnd = 'col-end-10'
      break
    case 11:
      gridColEnd = 'col-end-11'
      break
    case 12:
      gridColStart = 'col-end-12'
      break
    default:
      gridColEnd = 'col-end-13'
      break
  }

  return { colStartClassName: gridColStart, colEndClassName: gridColEnd }
}

const HighlightedTipsBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { FirstCardColSpans, MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)
  const [active, setActive] = useState(0)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-3
                      lg:py-10 md:py-7 py-4">
        <div data-epi-edit="MainContentArea" className="md:py-12 py-4 grid grid-cols-12 gap-4 md:gap-8">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const firstCardColSpan = FirstCardColSpans[Math.floor(i / 2)]
            const colStart = i % 2 === 0 ? 1 : firstCardColSpan
            const colEnd = i % 2 === 0 ? firstCardColSpan : 13
            const { colStartClassName, colEndClassName } = getColStartColEndClassName(colStart, colEnd)

            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i} className={`grid items-stretch ${colStartClassName} ${colEndClassName}`}
                onClick={() => i !== active && setActive(i)}>
                <HighlightedTipsCardBlock data={blockData[Id]} inEditMode={inEditMode} isActive={i === active}/>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(HighlightedTipsBlock)
