import React, { useRef, useLayoutEffect } from 'react'
import './EmbedFormBlock.css'

const EmbedFormBlock = ({data, givenCss}) => {
    let {EmbedCode} = data;
    const embedCodeContainerRef = useRef(null);
    let cssClasses = "embedFormBlock";

    if (givenCss !== null && givenCss !== '')
    {
        cssClasses += " " + givenCss
    }

    if (EmbedCode === undefined) {
        EmbedCode = data;
    }

    useLayoutEffect(() => {
        const range = document.createRange();
        range.selectNode(embedCodeContainerRef.current);
        const documentFragment = range.createContextualFragment(EmbedCode);

        // Extract scripts from the document fragment
        const scripts = Array.from(documentFragment.querySelectorAll('script'));
        scripts.forEach(script => script.parentNode.removeChild(script));

        // Inject the remaining HTML
        embedCodeContainerRef.current.innerHTML = '';
        embedCodeContainerRef.current.appendChild(documentFragment);

        // Function to load scripts sequentially
        const loadScriptsSequentially = async (scripts) => {
            for (const script of scripts) {
                const newScript = document.createElement('script');
                if (script.src) {
                    newScript.src = script.src;
                    await new Promise((resolve, reject) => {
                        newScript.onload = resolve;
                        newScript.onerror = reject;
                        embedCodeContainerRef.current.appendChild(newScript);
                    });
                } else {
                    newScript.textContent = script.textContent;
                    embedCodeContainerRef.current.appendChild(newScript);
                }
            }
        };

        // Load scripts sequentially
        loadScriptsSequentially(scripts);
    }, [])

    return (
        <>
            <div ref={embedCodeContainerRef} className={cssClasses}></div>
        </>
    )
};

export default React.memo(EmbedFormBlock)

// Explanation:
// Instead of using dangerouslySetInnerHTML which doesn't allow <script> tags to execute when client side rendering, we have to manually parse and inject the HTML content.
// This approach also allows us to extract and execute script tags sequentially, ensuring that
// scripts are loaded in the correct order. This is important because some scripts may depend
// on variables or functions defined in previous scripts. Using dangerouslySetInnerHTML would
// not provide this level of control over script execution order.