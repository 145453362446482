import React from 'react'

import { RoundedButtonBlock } from 'Blocks'

const CompareCardStylesCardBlock = ({ data, inEditMode }) => {
  const { Subheading, Heading, Content, Image, Button, ContentLink } = data
  const { Id } = ContentLink

  return (
    <div data-epi-block-id={Id} className="group shadow-normal hover:shadow-active rounded-lg bg-white md:p-6 p-4 md:gap-0 gap-1 xl:w-60 lg:w-52 md:w-48 w-40 xl:h-72 lg:h-64 md:h-60 h-56 grid content-center">
      <h6 data-epi-edit="Subheading" className="font-display text-center whitespace-pre-wrap lg:text-base text-sm font-light mb-0.5">{Subheading}</h6>
      <div data-epi-edit="Heading" className="font-display text-center whitespace-pre-wrap lg:text-lg lg:leading-tight font-medium mb-3">{Heading}</div>
      <div className="grid md:gap-2 gap-1">
        <div data-epi-edit="Content" className="lg:text-xxs md:text-xxxs text-xxxs text-center font-body">{Content}</div>
        <div className="flex justify-center">
          <img data-src={Image?.Url} alt={Image?.AltTag} className='lazy' />
        </div>
        <span className="text-center lg:mt-2 mt-1">
          <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
        </span>
      </div>
    </div>
  )
}

export default React.memo(CompareCardStylesCardBlock)
