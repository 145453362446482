import AutoLoanCalculatorBlock from './AutoLoanCalculatorBlock'
import MortgageCalculatorBlock from './MortgageCalculatorBlock'
import SavingInterestCalculatorBlock from './SavingInterestCalculatorBlock'
import HELOCCalculatorBlock from './HELOCCalculatorBlock'
import HomeRefinanceCalculatorBlock from './HomeRefinanceCalculatorBlock'
import LoantermandDebtConsolidationCalculatorBlock from './LoantermandDebtConsolidationCalculatorBlock'
import RetirementCalculatorBlock from './RetirementCalculatorBlock'
import SavingsGoalCalculatorBlock from './SavingsGoalCalculatorBlock'

export default {
  AutoLoanCalculatorBlock,
  MortgageCalculatorBlock,
  SavingInterestCalculatorBlock,
  HELOCCalculatorBlock,
  HomeRefinanceCalculatorBlock,
  LoantermandDebtConsolidationCalculatorBlock,
  RetirementCalculatorBlock,
  SavingsGoalCalculatorBlock
}
