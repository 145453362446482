import React, { useState, useEffect } from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import RelatedArticlewithCategoryCardBlock from './RelatedArticlewithCategoryCardBlock'

const RelatedArticleswithCategoryBlock = ({ data, inEditMode }) => {
  const {
    Heading,
    MainContentArea
  } = data
  const blockData = contentAreaDataMapper(MainContentArea)
  const [filteredData, setFilteredData] = useState(MainContentArea)
  const [clickFilter, setClickFilter] = useState(false)
  const AllLabel = 'View All Topics'
  const AccountManagementLabel = 'Account Management'
  const BorrowWiselyLabel = 'Borrow Wisely'
  const EmpowerandSupportLabel = 'Empower and Support'
  const SpendMindfullyLabel = 'Spend Mindfully'

  useEffect(() => {
    if (!clickFilter) {
      setFilteredData(MainContentArea)
    }
  })

  const CardBlocks = () => {
    return (
      <div className="lg:gap-10 md:gap-8 gap-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center justify-between" data-epi-edit="MainContentArea">
        {filteredData?.map((block, i) => {
          const { ContentLink } = block
          const { Id } = ContentLink
          return (
            <div data-epi-block-id={Id} key={i}>
              <RelatedArticlewithCategoryCardBlock data={blockData[Id]} />
            </div>
          )
        })}
        </div>
    )
  }

  const filterCardsByCategory = (e, category) => {
    setClickFilter(true)
    const _filderedData = category === 'All' ? MainContentArea : MainContentArea.filter(data => data[category] === true)
    setFilteredData(_filderedData)
  }

  return (
    <div className="container mx-auto max-w-screen-xl xl:my-20 lg:my-16 md:my-12 my-10">
      <div className="xl:mx-32 lg:mx-24 md:mx-20 mx-16 ">
      <div className="xl:text-4xl md:text-2xl text-xl font-semibold lg:mb-12 md:mb-8 mb-6">{Heading}</div>
        <div className=" lg:mb-6 mb-4">
          <div className="">
            <div className="lg:mt-3 md:mt-2">
              <button className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet" onClick={(e) => filterCardsByCategory(e, 'All')}> {AllLabel} </button>
            </div>
            <div className="lg:mt-3 md:mt-2">
              <button className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light" onClick={(e) => filterCardsByCategory(e, 'AccountManagement')}>{AccountManagementLabel} </button>
            </div>
            <div className="lg:mt-3 md:mt-2">
              <button className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light" onClick={(e) => filterCardsByCategory(e, 'BorrowWisely')}>{BorrowWiselyLabel} </button>
            </div>
            <div className="lg:mt-3 md:mt-2">
              <button className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light" onClick={(e) => filterCardsByCategory(e, 'EmpowerandSupport')}>{EmpowerandSupportLabel} </button>
            </div>
            <div className="lg:mt-3 md:mt-2">
              <button className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light" onClick={(e) => filterCardsByCategory(e, 'SpendMindfully')}>{SpendMindfullyLabel}</button>
            </div>
          </div>
        </div>
        <CardBlocks />
      </div>
    </div>
  )
}

export default React.memo(RelatedArticleswithCategoryBlock)
