import React from 'react'
import ResponsiveEmbed from 'react-responsive-embed'

import { RoundedButtonBlock } from 'Blocks'
import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const BankAnywhereBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Button, VideoLink, AppStoreImage, AppStoreUrl, GooglePlayImage, GooglePlayUrl } = data

  const UrlContainer = () => {
    return (
      <div className="md:flex grid grid-cols-2 gap-3 md:my-16 my-8">
        <RouterLinkTemplate Path={AppStoreUrl} inEditMode={inEditMode}>
          {(AppStoreImage || inEditMode) && (
            <span data-epi-edit="AppStoreImage">
              <img className="lazy w-full md:w-auto h-12 hover:opacity-90 cursor-pointer" data-src={addWebPToImageURL(AppStoreImage?.Url)} alt={AppStoreImage?.AltTag}/>
              {inEditMode && !AppStoreImage && 'Click to edit Image'}
            </span>
          )}
          {inEditMode && <div data-epi-edit="AppStoreUrl" className="mt-2 text-sm text-gray-500">Click to edit Url</div>}
        </RouterLinkTemplate>
        <RouterLinkTemplate Path={GooglePlayUrl} inEditMode={inEditMode}>
          {(GooglePlayImage || inEditMode) && (
            <span data-epi-edit="GooglePlayImage">
              <img className="lazy w-full md:w-auto h-12 hover:opacity-90 cursor-pointer" data-src={addWebPToImageURL(GooglePlayImage?.Url)} alt={GooglePlayImage?.AltTag}/>
              {inEditMode && !GooglePlayImage && 'Click to edit Image'}
            </span>
          )}
          {inEditMode && <div data-epi-edit="GooglePlayUrl" className="mt-2 text-sm text-gray-500">Click to edit Url</div>}
        </RouterLinkTemplate>
      </div>
    )
  }

  const ContentContainer = () => {
    return (
      <div className="px-10 grid">
        <h2 className="lg:text-4xl text-2xl font-medium font-display tracking-tighter">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h2>
        <h4 className="lg:mt-6 lg:mb-12 mt-4 mb-6 lg:text-2xl text-xl font-medium font-display tracking-tight">
          <span data-epi-edit="Subheading">
            {Subheading}
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h4>
        <RoundedButtonBlock data={Button} name='Button' inEditMode={inEditMode} />
        <UrlContainer/>
      </div>
    )
  }

  const MobileView = () => {
    return (
      <div className="grid sm:hidden">
        <h2 className="mx-6 text-lg font-medium font-display tracking-tighter">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h2>
        <h4 className="mx-6 mt-3 text-sm font-medium font-display tracking-tight">
          <span data-epi-edit="Subheading">
            {Subheading}
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h4>
        <div className="my-6" data-epi-edit="VideoLink">
          <ResponsiveEmbed loading="lazy" src={VideoLink} allowFullScreen />
        </div>
        <div className="justify-self-center">
          <RoundedButtonBlock data={Button} name='Button' inEditMode={inEditMode}
            overrideClass='focus:outline-none rounded-full md:font-display font-body font-medium md:text-lg text-sm
            border-2 border-violet hover:border-violet-dark text-violet hover:text-violet-dark
            md:py-2 py-1 px-6'/>
        </div>
        <UrlContainer/>
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="sm:grid grid-cols-2 items-center hidden">
          <ContentContainer/>
          <div data-epi-edit="VideoLink">
            <ResponsiveEmbed loading="lazy" src={VideoLink} allowFullScreen />
          </div>
        </div>
        <MobileView/>
      </div>
    </div>
  )
}

export default React.memo(BankAnywhereBlock)
