import React from 'react'
import { RoundedButtonBlock } from 'Blocks'
import CheckmarkSVG from 'SVGs/checkmark.svg'

const ComparableProdBlock = ({ data, inEditMode }) => {
  const {
    Heading,
    ProductText,
    CostText,
    ApyText,
    DebitCardText,
    ChecksText,
    BillPayText,
    OverdraftText,
    LineOfCreditText,
    IdentityTheftText,
    InternationalTransactionFeesText,
    AccountTypes
  } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:px-28 lg:px-24 md:px-20 sm:px-12 px-10
                      lg:py-10 md:py-7 py-4">
        <h2
          data-epi-edit="Heading"
          className="xl:text-4xl lg:text-2xl md:text-lg sm:text-sm text-xs xl:mb-14 lg:mb-11 md:mb-8 sm:mb-6 mb-4 font-medium font-display text-center">
          {Heading} {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <table className="table-fixed w-full font-body">
          <thead className="lg:border-b-2 border border-gray-300">
            <tr className="lg:text-sm md:text-xsmall text-xxs text-center">
              <th className="xl:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 font-medium text-left"
                data-epi-edit="ProductText"
              >
                {ProductText}
              </th>
              {AccountTypes?.map((a, i) => {
                const { DisplayName } = a
                return (
                  <th key={i} className={`xl:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 font-medium ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {DisplayName}
                  </th>
                )
              })}
              {AccountTypes?.length <= 3 && <th className="hidden lg:block"></th>}
            </tr>
          </thead>
          <tbody className="lg:text-xs md:text-xxs text-xxxs text-left font-normal">
            {CostText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1"
                data-epi-edit="CostText"
              >
                {CostText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { Cost } = a
                return (
                  <td key={i} className={`text-center ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {Cost}
                  </td>
                )
              })}
            </tr>}
            {ApyText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1"
                data-epi-edit="ApyText"
              >
                {ApyText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { IsApyBoolean, ApyBoolean, ApyString } = a
                return (
                  <td key={i} className={`text-center ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {IsApyBoolean && ApyBoolean && (
                      <>
                        <CheckmarkSVG className="text-emerald hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="text-emerald md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                    {!IsApyBoolean && <span className="lg:text-xs md:text-xxs text-xxxs font-normal">{ApyString}</span>}
                  </td>
                )
              })}
            </tr>}
            {DebitCardText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1"
                data-epi-edit="DebitCardText"
              >
                {DebitCardText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { DebitCard } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {DebitCard && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {ChecksText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1"
                data-epi-edit="ChecksText"
              >
                {ChecksText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { Checks } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {Checks && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {BillPayText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 lg:text-xs md:text-xxs text-xxxs text-left font-normal"
                data-epi-edit="BillPayText"
              >
                {BillPayText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { BillPay } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {BillPay && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {OverdraftText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 lg:text-xs md:text-xxs text-xxxs text-left font-normal"
                data-epi-edit="OverdraftText"
              >
                {OverdraftText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { Overdraft } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {Overdraft && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {LineOfCreditText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 lg:text-xs md:text-xxs text-xxxs text-left font-normal"
                data-epi-edit="LineOfCreditText"
              >
                {LineOfCreditText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { LineOfCredit } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {LineOfCredit && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {IdentityTheftText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 lg:text-xs md:text-xxs text-xxxs text-left font-normal"
                data-epi-edit="IdentityTheftText"
              >
                {IdentityTheftText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { IdentityTheft } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {IdentityTheft && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            {InternationalTransactionFeesText && <tr className="border">
              <td className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 lg:text-xs md:text-xxs text-xxxs text-left font-normal"
                data-epi-edit="InternationalTransactionFeesText"
              >
                {InternationalTransactionFeesText}
              </td>
              {AccountTypes?.map((a, i) => {
                const { InternationalTransactionFees } = a
                return (
                  <td key={i} className={`text-center text-emerald ${i % 2 === 0 ? '' : 'bg-violet-tint'}`}>
                    {InternationalTransactionFees && (
                      <>
                        <CheckmarkSVG className="hidden md:block mx-auto" width="21" height="19" />
                        <CheckmarkSVG className="md:hidden mx-auto" width="9" height="8" />
                      </>
                    )}
                  </td>
                )
              })}
            </tr>}
            <tr>
              <td />
              {AccountTypes?.map((a, i) => {
                const { CtaUrl, CtaText } = a
                return (
                  <td key={i} className="xl:py-7 lg:py-5 md:py-4 sm:py-2 py-1 xl:px-4 md:px-2 px-1 text-center">
                    {CtaText && CtaUrl && (
                      <RoundedButtonBlock data={{ Name: CtaText, Style: i % 2 === 0 ? 'main' : 'secondary_violet', Size: 'sm', Url: CtaUrl }} inEditMode={inEditMode} />
                    )}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
        {inEditMode && (
          <div className="mt-5 text-sm text-gray-500" data-epi-edit="AccountTypes">Change account data</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(ComparableProdBlock)
