import React from 'react'

const EmailRenderer = ({ data }) => {
  if (!data || data.length === 0) return null
  const { Name, PlaceHolder } = data[0]
  return (
    <form>
      <input name={Name} type="text" placeholder={PlaceHolder} className="appearance-none border-2 border-violet-light focus:outline-none focus:shadow-outline font-display font-medium leading-tight placeholder-violet focus:placeholder-transparent px-4 py-2 rounded-full text-center text-violet" />
    </form>
  )
}

const SignUpFormBlock = ({ data, inEditMode }) => {
  const { Title, Description, Image, ElementsArea } = data
  return (
    <div className="container mx-auto max-w-screen-xl py-14 grid text-center justify-center relative overflow-hidden">
      <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block absolute w-full"/>
      <div className="justify-self-center relative">
        <h2 className="lg:text-4xl text-xl font-medium font-display">
          <span data-epi-edit="Title">
            {Title}
            {inEditMode && !Title && 'Click to edit Title'}
          </span>
        </h2>
        <div className="font-body lg:text-base text-sm py-10 max-w-sm lg:max-w-md px-5">
          <span data-epi-edit="Description">
            {Description}
            {inEditMode && !Description && 'Click to edit Description'}
          </span>
        </div>
        <span data-epi-edit="ElementsArea">
          <EmailRenderer data={ElementsArea} />
          {inEditMode && (!ElementsArea || ElementsArea.length === 0) && 'Click to edit Form elements'}
        </span>
      </div>
      <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden w-full pt-5"/>
    </div>
  )
}

export default React.memo(SignUpFormBlock)
