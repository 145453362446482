import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock.jsx'


const GettingStartedBlock = ({ data, inEditMode }) => {
  const { Heading, Content, Button, LoginPopupMemberNonmemberReference } = data
  const markdown = { __html: Content }
  const showLoginButtonInstead = Button?.Url === null

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-64 lg:mx-48 md:mx-32 sm:mx-16 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="shadow-normal rounded-lg md:px-11 px-6 md:py-12 py-8 grid md:gap-6 gap-4 bg-white">
          <h2 data-epi-edit="Heading" className="lg:text-4xl text-xl font-medium font-display lg:block text-center">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </h2>
          <div data-epi-edit="Content" className="lg:text-xl sm:text-base text-sm font-body text-center" dangerouslySetInnerHTML={markdown} />
          <div className="grid justify-center">
            {!showLoginButtonInstead
            ? <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
            : LoginPopupMemberNonmemberReference && 
              <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} />}
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(GettingStartedBlock)
