import React from 'react'
import { addWebPToImageURL } from 'Helpers/utilities'

const IconFeaturedBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Image } = data

  if (!Heading && !Subheading && !Image && !inEditMode) return null

  return (
    <div className="grid lg:max-w-64">
      <div data-epi-edit="Image">
        <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="50" height="50" className='lazy' />
        {inEditMode && !Image && 'Click to edit Image'}
      </div>
      <h4 data-epi-edit="Heading" className="font-display font-medium lg:text-2xl md:text-lg text-base self-center py-6">{Heading}</h4>
      <p data-epi-edit="Subheading" className="font-body md:text-base text-sm self-center">{Subheading}</p>
    </div>
  )
}

export default React.memo(IconFeaturedBlock)
