import React from 'react'

const MemberCardBlock = ({ data, inEditMode }) => {
  const { ContentLink, Image, Heading, Subheading } = data
  const { Id } = ContentLink

  return (
    <div data-epi-block-id={Id} className="grid justify-center gap-2">
      <img data-epi-edit="Image" data-src={Image?.Url} alt={Image?.AltTag} className="lazy rounded-full md:w-24 sm:w-16 w-10 md:h-24 sm:h-16 h-10 mx-auto" width="90" height="90" />
      <div className="text-center lg:mt-1">
        <p data-epi-edit="Subheading" className="font-body lg:text-lg md:text-base sm:text-xs text-xxs">{Subheading}</p>
        <p data-epi-edit="Heading" className="font-display font-medium lg:text-xl md:text-lg sm:text-sm text-xs">{Heading}</p>
      </div>
    </div>
  )
}

export default React.memo(MemberCardBlock)
