import React, { useState } from 'react'

import PromotionCarouselCardBlock from './PromotionCarouselCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'
import ChevronLeftSVG from 'SVGs/chevronLeft.svg'
import ChevronRightSVG from 'SVGs/chevronRight.svg'

const paginate = (currentOffset, indexToChangeTo) => {
  const endIndex = currentOffset + 3
  if (indexToChangeTo > endIndex) {
    return currentOffset + (indexToChangeTo - endIndex)
  } else if (indexToChangeTo < currentOffset) {
    return indexToChangeTo
  }
  return currentOffset
}

const CarouselRenderer = ({ data, name, inEditMode }) => {
  if (!data || data.length === 0) {
    if (!inEditMode) return null
    return <div data-epi-edit={name}>Click to add images</div>
  }

  const dataLength = data.length
  const blockData = contentAreaDataMapper(data)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentOffset, setCurrentOffset] = useState(0)

  return (
    <div className="md:block hidden">
      <div className="flex lg:gap-4 items-center">
        <div className="rounded-full grid px-1 cursor-pointer select-none"
          onClick={() => {
            if (currentIndex > 0) {
              setCurrentIndex(currentIndex - 1)
              const nextOffset = paginate(currentOffset, currentIndex - 1)
              if (currentOffset !== nextOffset) {
                setCurrentOffset(nextOffset)
              }
            }
          }}>
          <ChevronLeftSVG className="m-4 h-4 text-gray-500" />
        </div>
        <div data-epi-edit={name} className="flex w-full flex-wrap overflow-x-hidden md:py-6 py-4">
          {data && data.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i}
                style={{ flexBasis: 0 }}
                className={`duration-200 transition-transform transform ${i < currentOffset ? '-translate-x-full w-0' : i > currentOffset + 3 ? 'translate-x-full w-0' : 'flex-1'}`}
                onClick={() => setCurrentIndex(i)}>
                <PromotionCarouselCardBlock data={blockData[Id]} inEditMode={inEditMode} isActive={i === currentIndex} />
              </div>
            )
          })}
        </div>
        <div className="rounded-full grid px-1 cursor-pointer select-none"
          onClick={() => {
            if (currentIndex < dataLength - 1) {
              setCurrentIndex(currentIndex + 1)
              const nextOffset = paginate(currentOffset, currentIndex + 1)
              if (currentOffset !== nextOffset) {
                setCurrentOffset(nextOffset)
              }
            }
          }}>
          <ChevronRightSVG className="m-4 h-4 text-gray-500" />
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        {data && data.map((_, i) => {
          return (
            <div key={i} className="px-1 cursor-pointer"
              onClick={() => {
                setCurrentIndex(i)
                const nextOffset = paginate(currentOffset, i)
                if (currentOffset !== nextOffset) {
                  setCurrentOffset(nextOffset)
                }
              }}>
              <div className={`w-2 h-2 ${currentIndex === i ? 'bg-gray-300' : 'bg-white'} border-gray-300 border-2 rounded-full`} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(CarouselRenderer)
