import React, { useState } from 'react'

import PartnerInfoCardBlock from './PartnerInfoCardBlock'
import ChevronLeftSVG from 'SVGs/chevronLeft.svg'
import ChevronRightSVG from 'SVGs/chevronRight.svg'
import { paginate, contentAreaDataMapper } from 'Helpers/transform'

const PartnerInfoBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data
  if (!MainContentArea && !inEditMode) return null

  const pageSize = 4
  const isShowPagination = MainContentArea?.length > 4
  const dataLength = MainContentArea?.length
  const blockData = contentAreaDataMapper(MainContentArea)
  const [pagination, setPagination] = useState(paginate(dataLength, 1, pageSize))

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-20 lg:mx-12 md:mx-8
                      lg:py-10 md:py-7 py-4">
        <div data-epi-edit="MainContentArea">
          <div className="flex lg:gap-4 items-center">
            <div className={`rounded-full grid px-1 ${isShowPagination ? 'cursor-pointer' : 'opacity-20'} select-none`}
              onClick={() => {
                if (pagination.currentPage === 1) return
                setPagination(paginate(dataLength, pagination.currentPage -= 1, pageSize))
              }}
            >
              <ChevronLeftSVG className="m-4 h-4 text-gray-500" />
            </div>
            <div className="gap-28 grid grid-cols-4 md:py-6 py-4 w-full" data-epi-edit="MainContentArea">
              {MainContentArea?.slice(pagination.startIndex, pagination.endIndex + 1).map((block, i) => {
                const { ContentLink } = block
                const { Id } = ContentLink
                return (
                  <div data-epi-block-id={Id} key={i}>
                    <PartnerInfoCardBlock data={blockData[Id]} />
                  </div>
                )
              })}
            </div>
            <div className={`rounded-full grid px-1 ${isShowPagination ? 'cursor-pointer' : 'opacity-20'} select-none`}
              onClick={() => {
                if (pagination.currentPage === pagination.pages[pagination.pages.length - 1]) return
                setPagination(paginate(dataLength, pagination.currentPage += 1, pageSize))
              }}
            >
              <ChevronRightSVG className="m-4 h-4 text-gray-500" />
            </div>
          </div>
          <div className="flex gap-2 justify-center">
            {pagination && pagination.pages && pagination.pages.map((i) => {
              return (
                <div key={i} className={`px-1 cursor-pointer ${isShowPagination ? '' : 'opacity-20'}`}
                  onClick={() => setPagination(paginate(dataLength, i, pageSize))}
                >
                  <div className={`w-2 h-2 ${pagination.currentPage === i ? 'bg-gray-300' : 'bg-white'} border-gray-300 border-2 rounded-full`} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PartnerInfoBlock)
