import React, { useState } from 'react'

import { RoundedButtonBlock } from 'Blocks'
import LoginPopupMemberNonmemberFormModal from './LoginPopupMemberNonmemberFormModal'

const LoginPopupMemberNonmemberFormBlock = ({ data, name, hideButton, openModalWithExistingContent, onClose, inEditMode }) => {
  if (data === null) return null;
  const { MemberHeading, MemberUsernamePlaceholder, MemberCtaButton1, MemberCtaButton2, ForgotPasswordText,
    NonmemberHeading, NonmemberSubheading, NonmemberCtaButton, RoundedButton } = data
  const [isLoggingIn, setIsLoggingIn] = useState(openModalWithExistingContent)

  function onModalClosed() {
    setIsLoggingIn(false)
    if (onClose) {
      onClose()
    }
  }

  function onOpenModal() {
    setIsLoggingIn(true)
  }

  return (
    <>
      {!hideButton &&
        <div onClick={() => onOpenModal()} className='flex'>
          <RoundedButtonBlock
            data={{ Name: RoundedButton?.Name, Style: RoundedButton?.Style, Size: RoundedButton?.Size }}
            name={name}
            inEditMode={inEditMode}
            flex={true} />
        </div>
      }
      <LoginPopupMemberNonmemberFormModal isOpen={isLoggingIn} onClose={() => onModalClosed()} data={data} inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(LoginPopupMemberNonmemberFormBlock)
