import React from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import StillCantFindlookingForCardBlock from './StillCantFindlookingForCardBlock'

const StillCantFindlookingForBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  const dataLength = MainContentArea?.length
  const gridCols = dataLength % 3 === 0 ? 'grid-cols-3 xl:gap-x-28 md:gap-10 gap-6' : 'grid-cols-4 xl:gap-9 md:gap-6 gap-3'

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-44 lg:mx-36 md:mx-28 sm:mx-20 mx-4 xl:py-18 lg:py-10 md:py-8 py-6">
        {Heading && <h2 data-epi-edit="Heading" className="lg:text-2xl md:text-xl sm:text-lg text-base md:text-left text-center font-semibold">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>}
        {Subheading && <h4 data-epi-edit="subHeading" className="xl:text-2xl lg:text-xl md:text-lg sm:text-base text-xs md:text-left sm:text-center text-justify font-normal lg:my-16 sm:my-12 my-8 xl:mr-9 md:mr-5 md:mx-0 sm:mx-12 mx-8">
          {Subheading}
          {inEditMode && !Subheading && 'Click to edit Heading'}
        </h4>}
        <div data-epi-edit="MainContentArea" className={`grid ${gridCols}`}>
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <StillCantFindlookingForCardBlock data={blockData[Id]} key={i} inEditMode={inEditMode} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(StillCantFindlookingForBlock)
