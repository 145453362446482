import React from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'

const CardLinkBlock = ({ data, inEditMode }) => {
  const { CardHeading, CardImage, Heading, Content, Url } = data

  const CardBlock = () => {
    return (
        <div className="rounded-lg shadow-normal hover:shadow-active cursor-pointer">
        <div data-epi-edit="Image">
            <img data-src={CardImage?.Url} alt={CardImage?.AltTag} className="lazy hidden md:block" width="800" height="800" />
            <img data-src={CardImage?.Url} alt={CardImage?.AltTag} className="lazy md:hidden w-full" width="" height="500" />
            {inEditMode && !CardImage && 'Click to edit Image'}
        </div>
        <h4 data-epi-edit="CardHeading" className="font-display font-medium xl:text-3xl sm:text-2xl text-xl md:pt-6 md:pb-10 py-6 ml-4">
            {CardHeading}
        </h4>
    </div>
    )
  }

  const CardWithLink = () => {
    return (
        <div className="container mx-auto max-w-screen-xl relative">
          <div className="xl:mx-32 lg:mx-28 md:mx-12 mx-8
                          lg:py-10 md:py-7 py-5">
            <div className="md:flex md:gap-x-16">
                <div className="">
                <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
                    <CardBlock />
                </RouterLinkTemplate>
                </div>
                <div className="md:mt-0 my-4">
                    <h4 data-epi-edit="Heading" className="font-display font-medium sm:text-2xl text-lg md:mb-4 mb-2" >
                        {Heading}
                    </h4>
                    <Markdown data={Content} name='Content' inEditMode={inEditMode} />
                </div>
            </div>
          </div>
        </div>
    )
  }

  const CardWithoutLink = () => {
    return (
        <div className="container mx-auto max-w-screen-xl relative">
          <div className="xl:mx-32 lg:mx-28 md:mx-12 mx-8
                          lg:py-10 md:py-7 py-5">
            <div className="md:flex md:gap-x-16">
                <div className="">
                    <CardBlock />
                </div>
                <div className="md:mt-0 my-4">
                    <h4 data-epi-edit="Heading" className="font-display font-medium sm:text-2xl text-lg md:mb-4 mb-2" >
                        {Heading}
                    </h4>
                    <Markdown data={Content} name='Content' inEditMode={inEditMode} />
                </div>
            </div>
          </div>
        </div>
    )
  }

  if (!Url) {
    return (
        <CardWithoutLink />
    )
  }

  return (
    <CardWithLink />
  )
}

export default React.memo(CardLinkBlock)
