import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useEffect, useState } from 'react'

export default function useQueryParams () {
  const location = useLocation()
  const [query, setQuery] = useState(queryString.parse(location.search))
  useEffect(() => setQuery(queryString.parse(location.search)), [location.pathname])
  return query
}
