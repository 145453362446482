import React from 'react'

import HelpingMembersCardBlock from './HelpingMembersCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'
import { addWebPToImageURL } from 'Helpers/utilities'

const HelpingMembersBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea, Image } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl relative">
      <div className="xl:px-32 lg:px-28 md:px-12 sm:px-8 px-6
                      lg:mb-16 md:mb-10 mb-8
                      lg:pt-16 md:pt-10 pt-6 relative">
        <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden
          bg-no-repeat xl:bg-center-bottom lg:bg-center-top bg-center-bottom md:bg-cover xl:bg-contain lazy"
          data-bg={addWebPToImageURL(Image?.Url)}>
        </div>
        {(Heading || inEditMode) && (<div className="grid grid-cols-12 relative md:py-14 py-8 px-4">
          <div className="xl:col-span-9 lg:col-span-10 col-span-12 text-center lg:text-left">
            <h2 data-epi-edit="Heading" className="lg:text-4xl md:text-2xl text-xl font-medium font-display md:leading-12">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </h2>
          </div>
        </div>)}
        <div data-epi-edit="MainContentArea" className="md:flex justify-around gap-4 grid md:mx-0 sm:mx-24 mx-12">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return <HelpingMembersCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
          })}
        </div>
        {inEditMode && (<div className="text-gray-700 inline-block relative mt-4" data-epi-edit="Image">Click to change Image</div>)}
      </div>
    </div>
  )
}

export default React.memo(HelpingMembersBlock)
