import React from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'
import MemberCardBlock from './MemberCardBlock'

const MembersBlock = ({ data, inEditMode }) => {
  const { ImageLocation, Heading, Content, CtaLink, MainContentArea } = data
  const Link = CtaLink.Value[0]

  const Layout = () => {
    const ContentContainer = () => {
      return (
        <>
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
          {Link && (
            <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
              <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{Link.Text}</span>
            </RouterLinkTemplate>
          )}
          {!Link && inEditMode && (
            <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet">
              Click to edit Link
            </span>
          )}
        </>
      )
    }

    const ImageContainer = () => {
      if (!MainContentArea || MainContentArea.length === 0) {
        if (!inEditMode) return null
        return <div data-epi-edit="MainContentArea">Click to add members</div>
      }

      return (
        <div className="grid grid-cols-3 gap-8" data-epi-edit="MainContentArea">
          {MainContentArea && MainContentArea.map((block, i) => {
            return <MemberCardBlock data={block} key={i}/>
          })}
        </div>
      )
    }

    const Inner = ({ imageLocation }) => {
      const contentContainerClass = 'grid lg:gap-6 sm:gap-4 gap-2 self-center'
      if (imageLocation === 'right') {
        return (
          <>
            <div className={`${contentContainerClass} text-left lg:col-span-5`}>
              <ContentContainer/>
            </div>
            <div className="lg:col-span-7">
            <ImageContainer/>
          </div>
          </>
        )
      }

      return (
        <>
          <div className="lg:col-span-7">
            <ImageContainer/>
          </div>
          <div className={`${contentContainerClass} lg:col-span-5`}>
            <ContentContainer/>
          </div>
        </>
      )
    }

    return (
      <div className="grid lg:grid-cols-12 lg:gap-16 gap-6 justify-items-stretch items-center">
        <Inner imageLocation={ImageLocation} />
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-48 lg:mx-40 md:mx-12 sm:mx-8 mx-6
                      lg:pt-10 md:py-7 py-4">
        <Layout />
      </div>
    </div>
  )
}

export default React.memo(MembersBlock)
