import BlogFormBlock from './BlogFormBlock'
import ContactInfoFormBlock from './ContactInfoFormBlock'

import AutoBoatShowFormBlock from './AutoBoatShowFormBlock'
import AutoRefinanceFormBlock from './AutoRefinanceFormBlock'
import DebtConsolidationFormBlock from './DebtConsolidationFormBlock'
import MortgageFormBlock from './MortgageFormBlock'
import NewMemberFormBlock from './NewMemberFormBlock'
import AutoLoanFormBlock from './AutoLoanFormBlock'
import HomeEquityFormBlock from './HomeEquityFormBlock'
import MortgageRefinanceFormBlock from './MortgageRefinanceFormBlock'
import RecVehicleBoatMotorcycleFormBlock from './RecVehicleBoatMotorcycleFormBlock'
import EmbedFormBlock from './EmbedFormBlock'

import LoginPopupFormBlock from './LoginPopupFormBlock'
import LoginPopupMemberNonmemberFormBlock from './LoginPopupMemberNonmemberFormBlock'
import LoginPopupMemberNonmemberFormModal from './LoginPopupMemberNonmemberFormModal'
import OfferInfoForm from './OfferInfoForm'
import SignUpFormBlock from './SignUpFormBlock'

export default {
  BlogFormBlock,
  ContactInfoFormBlock,

  AutoBoatShowFormBlock,
  AutoRefinanceFormBlock,
  DebtConsolidationFormBlock,
  MortgageFormBlock,
  NewMemberFormBlock,
  AutoLoanFormBlock,
  HomeEquityFormBlock,
  MortgageRefinanceFormBlock,
  RecVehicleBoatMotorcycleFormBlock,
  EmbedFormBlock,

  LoginPopupFormBlock,
  LoginPopupMemberNonmemberFormBlock,
  LoginPopupMemberNonmemberFormModal,
  OfferInfoForm,
  SignUpFormBlock
}
