import React from 'react'

import VideoCardBlock from './VideoCardBlock'

const VideoGuidesWithFeaturedBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, MainContentArea } = data

  const Layout = () => {
    if (MainContentArea && MainContentArea.length > 0) {
      const firstBlock = MainContentArea[0]
      const remainingBlocks = MainContentArea.slice(1)

      return (
        <div data-epi-edit="MainContentArea" className="sm:grid hidden grid-cols-12 gap-12">
          {firstBlock && <div className="col-span-7 grid content-center"><VideoCardBlock data={firstBlock} inEditMode={inEditMode} size="lg" /></div>}
          <div className="col-span-5 grid content-between gap-2">
            {remainingBlocks && remainingBlocks.length > 0 && remainingBlocks.map((val, i) => {
              return <VideoCardBlock key={i} data={val} inEditMode={inEditMode} size="wide" />
            })}
          </div>
        </div>
      )
    }

    return <div data-epi-edit="MainContentArea" className="sm:grid hidden grid-cols-12 gap-12" />
  }

  const LayoutMobile = () => {
    return (
      <div data-epi-edit="MainContentArea" className="grid sm:hidden gap-8">
        {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((val, i) => {
          return (
            <VideoCardBlock key={i} data={val} inEditMode={inEditMode} size="wide" />
          )
        })}
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-48 lg:mx-32 md:mx-12 sm:mx-8 mx-12
                      lg:py-10 md:py-7 py-4">
        {(Heading || Subheading || inEditMode) && (
          <div className="grid justify-items-center lg:py-10 md:py-10 py-3">
            <h2 className="lg:text-4xl text-xl font-medium font-display">
              <span data-epi-edit="Heading">
                {Heading}
                {inEditMode && !Heading && 'Click to edit Heading'}
              </span>
            </h2>
            <h4 className="lg:text-2xl text-sm my-4 font-body">
              <span data-epi-edit="Subheading">
                {Subheading}
                {inEditMode && !Subheading && 'Click to edit Subheading'}
              </span>
            </h4>
          </div>
        )}
        <Layout />
        <LayoutMobile />
      </div>
    </div>
  )
}

export default React.memo(VideoGuidesWithFeaturedBlock)
