import React from 'react'

const BusinessCommunityCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Subheading } = data
  return (
    <div className="h-full grid content-between">
      <div data-epi-edit="Image">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block" width="35" height="35" />
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden" width="15" height="15" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        <h2 data-epi-edit="Heading" className="text-left xl:text-2xl lg:text-xl md:text-lg sm:text-base text-xs font-medium lg:my-6 md:my-4 sm:my-2 my-2">
          {Heading}
        </h2>
        <h6 data-epi-edit="Subheading" className="text-left xl:text-lg lg:text-base md:text-sm sm:text-xs text-xxs">
          {Subheading}
        </h6>
    </div>
  )
}

export default React.memo(BusinessCommunityCardBlock)
