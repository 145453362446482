import React from 'react'

const BaseAssemblyFormBlock = ({ formId, height }) => {
  return (
    <>
      <iframe src={`https://www.tfaforms.com/${formId}`} width="100%" height={height} frameBorder="0" />
      <script src="//tfaforms.com/js/iframe_resize_helper.js" />
    </>
  )
}

export default React.memo(BaseAssemblyFormBlock)
