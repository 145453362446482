import React, { useState, useEffect, useRef } from 'react'

const MAX_PAGES_TO_DISPLAY_LARGE = 5;
const MAX_PAGES_TO_DISPLAY_SMALL = 3;

const PaginationRenderer = ({ pageNumber, totalPageNumber, onPageNumberChange }) => {
  const [maxPagesToDisplay, setMaxPagesToDisplay] = useState(MAX_PAGES_TO_DISPLAY_LARGE);
  const previousText = useRef('Previous')
  const nextText = useRef('Next')
  const canIncrease = pageNumber < totalPageNumber
  const canDecrease = pageNumber > 1

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        previousText.current = '<';
        nextText.current = '>';
        setMaxPagesToDisplay(MAX_PAGES_TO_DISPLAY_SMALL);
      } else {
        previousText.current = 'Previous';
        nextText.current = 'Next';
        setMaxPagesToDisplay(MAX_PAGES_TO_DISPLAY_LARGE);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  // this function calculates the page numbers to display in the pagination bar. it will always display the maximum number of pages, unless there are fewer pages left to display than the maximum. in that case, it will display the maximum number of pages starting from the earliest page.
  const getPagesToDisplay = () => {
    let start = Math.max(1, pageNumber - Math.floor(maxPagesToDisplay / 2));
    let end = Math.min(totalPageNumber, start + maxPagesToDisplay - 1);
    if (end - start + 1 < maxPagesToDisplay) {
      start = Math.max(1, end - maxPagesToDisplay + 1);
    }
    return [...Array(end - start + 1).keys()].map(i => i + start);
  }

  const pagesToDisplay = getPagesToDisplay();

  const handleKeyPress = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  return (
    <div className="flex justify-center select-none">
      <ul className="inline-block mx-4 my-12 text-gray-500 font-display">
        <li tabIndex={0} className={`inline border px-4 py-2 border-gray-300 ${canDecrease ? 'cursor-pointer hover:bg-violet-dark hover:text-white hover:border-violet-dark' : 'text-gray-300 cursor-not-allowed'} rounded-l-md border-r-0`}
          onClick={() => canDecrease && onPageNumberChange(pageNumber - 1)}
          onKeyDown={(event) => handleKeyPress(event, () => canDecrease && onPageNumberChange(pageNumber - 1))}>{previousText.current}</li>
        {pagesToDisplay[0] > 1 && <>
          <li tabIndex={0} className="inline border px-4 py-2 border-gray-300 cursor-pointer
          hover:bg-violet-dark hover:text-white hover:border-violet-dark"
            onClick={() => onPageNumberChange(1)}
            onKeyDown={(event) => handleKeyPress(event, () => onPageNumberChange(1))}>1</li>
          <li className="inline px-2 sm:px-4 py-2">...</li>
        </>}

        {pagesToDisplay.map((page) => {
          return <li tabIndex={0} className={`inline border px-4 py-2 border-gray-300 cursor-pointer
          hover:bg-violet-dark hover:text-white hover:border-violet-dark
          ${pageNumber === page ? 'bg-violet text-white border-violet' : ''}`}
            onClick={() => onPageNumberChange(page)}
            onKeyDown={(event => handleKeyPress(event, () => onPageNumberChange(page)))}
            key={page}>{page}</li>
        })}

        {pagesToDisplay[pagesToDisplay.length - 1] < totalPageNumber && <>
          <li className="inline px-2 sm:px-4 py-2">...</li>
          <li tabIndex={0} className="inline border px-4 py-2 border-gray-300 cursor-pointer
          hover:bg-violet-dark hover:text-white hover:border-violet-dark border-r-0"
            onClick={() => onPageNumberChange(totalPageNumber)}
            onKeyDown={(event) => handleKeyPress(event, () => onPageNumberChange(totalPageNumber))}>{totalPageNumber}</li>
        </>}
        <li tabIndex={0} className={`inline border px-4 py-2 border-gray-300 ${canIncrease ? 'cursor-pointer hover:bg-violet-dark hover:text-white hover:border-violet-dark' : 'text-gray-300 cursor-not-allowed'} rounded-r-md`}
          onClick={() => canIncrease && onPageNumberChange(pageNumber + 1)}
          onKeyDown={(event) => handleKeyPress(event, () => canIncrease && onPageNumberChange(pageNumber + 1))}>{nextText.current}</li>
      </ul>
    </div>
  )
}

export default React.memo(PaginationRenderer)
