import React from 'react'

const HelpingMembersCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Content, ContentLink } = data
  const { Id } = ContentLink
  const markdown = { __html: Content }
  return (
    <div data-epi-block-id={Id} className="grid content-around flex-1 gap-2 md:gap-6 p-6 sm:p-4 md:p-8 max-w-xs bg-white
      border border-gray-100 duration-200 shadow-md hover:shadow-active rounded-lg transform transition-all">
      {(Heading || inEditMode) && <div data-epi-edit="Heading" className="font-display font-medium md:text-3xl text-xl text-violet">{Heading}</div>}
      {(Subheading || inEditMode) && <div data-epi-edit="Subheading" className="font-display font-medium md:text-xl text-lg text-violet py-2">{Subheading}</div>}
      <div data-epi-edit="Content" className="font-body md:text-sm text-xs" dangerouslySetInnerHTML={markdown} />
    </div>
  )
}

export default React.memo(HelpingMembersCardBlock)
