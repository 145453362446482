import React from 'react'
import { RouterLinkTemplate } from 'Components'
import { getDateWithFormat } from 'Helpers/utilities'
import ArrowRightSVG from 'SVGs/arrowRight.svg'

const RelatedArticlewithCategoryCardBlock = ({ data, inEditMode }) => {
  const { Author, StartPublish, Image, Heading, Url, AccountManagement, BorrowWisely, EmpowerandSupport, SpendMindfully } = data
  const Link = Url.Value[0]

  return (
    <div className="shadow-normal cursor-pointer rounded-lg hover:shadow-active" >
      <div data-epi-edit="Image">
        <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy rounded-t-lg w-full h-44" width="200" height="200" />
        {inEditMode && !Image && 'Click to edit Image'}
      </div>
      <div className="lg:p-4 p-3">
        <div className="mb-2">
          {Author?.PublicName && (<p className="font-normal xl:text-base text-sm" data-epi-edit="Author"> {getDateWithFormat(new Date(StartPublish))} | By {Author?.PublicName} </p>)}
        </div>
        <div className="mb-2 xl:text-xl lg:text-lg text-base font-semibold">{Heading}</div>
          {Link && (
            <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
              <span data-epi-edit="Url" className="xl:text-xl md:text-lg text-base text-violet">{Link.Text}<ArrowRightSVG className="inline-block ml-2" width="20" height="20" /></span>
            </RouterLinkTemplate>
          )}
      </div>
      <div className="hidden">{AccountManagement && <>Account Management</>}</div>
      <div className="hidden">{BorrowWisely && <>Borrow Wisely</>}</div>
      <div className="hidden">{EmpowerandSupport && <>Empower and Support</>}</div>
      <div className="hidden">{SpendMindfully && <>Spend Mindfully</>}</div>
    </div>
  )
}

export default React.memo(RelatedArticlewithCategoryCardBlock)
