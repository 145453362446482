import React, { useEffect, useRef, useState } from 'react'

import styles from './WellbySelectDropdownComponent.module.css'
import ChevronDownSVG from 'SVGs/chevronDown.svg'


const WellbySelectDropdownComponent = ({ multiple, value, onChange, options, showClearButton = true, showDivider = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(null)
  const containerRef = useRef(null)

  function clearOptions() {
    multiple ? onChange([]) : onChange(undefined)
  }

  function selectOption(option) {
    if (multiple) {
      if (value.includes(option)) {
        onChange(value.filter(o => o !== option))
      } else {
        onChange([...value, option])
      }
    } else {
      onChange(option)
    }
  }

  function isOptionSelected(option) {
    return multiple ? value.includes(option) : option === value
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(null)
  }, [isOpen])

  useEffect(() => {
    const handler = (e) => {
      if (e.target != containerRef.current) return
      switch (e.code) {
        case "Enter":
        case "Space":
          e.preventDefault()
          setIsOpen(prev => !prev)
          if (isOpen) selectOption(options[highlightedIndex])
          break
        case "ArrowUp":
        case "ArrowDown": {
          e.preventDefault()
          if (!isOpen) {
            setIsOpen(true)
            break
          }
          
          const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1)
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue)
          }
          break
        }
        case "Escape":
          setIsOpen(false)
          break
      }
    }
    containerRef.current?.addEventListener("keydown", handler)

    return () => {
      containerRef.current?.removeEventListener("keydown", handler)
    }
  }, [isOpen, highlightedIndex, options])

  return (
    <div
      ref={containerRef}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen(prev => !prev)}
      tabIndex={0}
      className={styles.container}
    >
      <span className={styles.value}>
        {multiple
          ? value.map(v => (
            <button
              key={v.value}
              onClick={e => {
                e.stopPropagation()
                selectOption(v)
              }}
              className={styles["option-badge"]}
            >
              {v.label}
              <span className={styles["remove-btn"]}>&times;</span>
            </button>
          ))
          : value?.label}
      </span>
      {showClearButton && <button
        onClick={e => {
          e.stopPropagation()
          clearOptions()
        }}
        className={styles["clear-btn"]}
      >
        &times;
      </button>
      }
      {showDivider && <div className={styles.divider}></div>}
      <div className={styles.caret}><ChevronDownSVG className='h-2' /></div>
      <ul className={`${styles.options} ${isOpen ? styles.show : ""}`}>
        {options.map((option, index) => (
          <li
            onClick={e => {
              e.stopPropagation()
              selectOption(option)
              setIsOpen(false)
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.value}
            className={`${styles.option} ${isOptionSelected(option) ? styles.selected : ""
              } ${index === highlightedIndex ? styles.highlighted : ""}`}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default React.memo(WellbySelectDropdownComponent)
