import React from 'react'

import QuoteSVG from 'SVGs/quote.svg'

const MemberQuoteBlock = ({ data, inEditMode }) => {
  const { Heading, Quote, NameDate } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-52 lg:mx-48 md:mx-32 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4
                      grid lg:gap-8 gap-4">
        <h3 data-epi-edit="Heading" className="font-display font-medium xl:text-4xl md:text-3xl text-xl md:max-w-md md:text-left text-center">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h3>
        <QuoteSVG className="justify-self-center lg:w-16 md:w-12 w-8 text-violet" />
        <div className="justify-self-center font-display font-light italic text-center">
          <h4 className="lg:text-4xl md:text-2xl md:pb-3" data-epi-edit="Quote">
            “{Quote}”
            {inEditMode && !Quote && 'Click to edit quote'}
          </h4>
          <h5 data-epi-edit="NameDate" className="lg:text-3xl md:text-xl">
            {NameDate}
            {inEditMode && !NameDate && 'Click to edit member\'s name and quote date'}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MemberQuoteBlock)
