import React, { useRef, useEffect } from 'react'

import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import { contentAreaDataMapper } from 'Helpers/transform'
import BlockComponentSelector from '../../../BlockComponentSelector'

import ChevronLeft from 'SVGs/chevronLeft.svg'
import ChevronRight from 'SVGs/chevronRight.svg'

import './HeroBannerSliderBlock.css'

// built using swiper.js version 9.0.5
// documentation links: https://swiperjs.com/get-started : https://swiperjs.com/swiper-api

const HeroBannerSliderBlock = ({ data, inEditMode }) => {
    if (!data) {
        if (!inEditMode) return null
        return <>Click to edit</>
    }

    const { SlidesContentArea, AutoplaySpeed, FadeTransitionSpeed } = data;
    const blockData = contentAreaDataMapper(SlidesContentArea);
    const swiperRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            slidesPerView: 1,
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: AutoplaySpeed,
            },
            speed: FadeTransitionSpeed,
            navigation: { // allows the arrows to be clickable
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: { // creates the pagination element functionality
                el: '.swiper-pagination',
                type: 'custom',
                clickable: true,
                renderCustom: (swiper, current, total) => { // rectangle for pagination icons
                    let rectangles = '';
                    for (let i = 1; i <= total; i++) {
                        if (current === i) {
                            rectangles += `<span class="swiper-pagination-bullet custom-bullet-active"></span>`;
                        }
                        else {
                            rectangles += `<span class="swiper-pagination-bullet custom-bullet"></span>`;
                        }
                    }
                    return rectangles;
                },
            },
            breakpoints: {
                // when the window width is >= 200
                200: {
                    navigation: {
                        enabled: false,
                    },
                },
                // when the window width is >= 1280
                1280: {
                    navigation: {
                        enabled: true
                    },
                },
            }
        })
    }, []);

    return (
        <>
            {/* slider main container */}
            <div className="swiper relative h-[300px] md:h-[350px] lg:h-[450px]" ref={swiperRef}>
                {/* required wrapper */}
                <div className="swiper-wrapper">
                    {/* slides */}
                    {SlidesContentArea && SlidesContentArea.length > 0 && SlidesContentArea.map((block, i) => {
                        const { ContentLink } = block
                        const { Id } = ContentLink
                        return (
                            <div className='swiper-slide' key={Id}>
                                <BlockComponentSelector data={blockData[Id]} />
                            </div>
                        )
                    })}
                </div>

                {/* pagination */}
                <div className="swiper-pagination flex justify-center gap-2"></div>

                {/* navigation */}
                <div className="swiper-button-prev">
                    <ChevronLeft className="h-full w-[13px] text-violet" />
                </div>
                <div className="swiper-button-next">
                    <ChevronRight className="h-full w-[13px] text-violet" />
                </div>
            </div>
        </>
    )
}

export default React.memo(HeroBannerSliderBlock)
