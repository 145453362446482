import React from 'react'

import BlockComponentSelector from '../BlockComponentSelector'
import { contentAreaDataMapper } from 'Helpers/transform'

const ContentArea = ({ data, name, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return (
      <div data-epi-edit={name} className="col-span-12 row-span-6">
        Click to edit
      </div>
    )
  }

  const blockData = contentAreaDataMapper(data)

  return (
    <div data-epi-edit={name}>
      {data && data.length > 0 && data.map((block, i) => {
        const { ContentLink } = block
        const { Id } = ContentLink
        return (
          <div data-epi-block-id={Id} key={i}>
            <BlockComponentSelector data={blockData[Id]} />
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(ContentArea)
