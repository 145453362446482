import React from 'react'

const Layout = ({ children }) => {
  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-10 mx-10">
        {children}
      </div>
    </div>
  )
}

export default React.memo(Layout)
