import React, { useEffect } from 'react'

import { BlogSearchWithRelatedArticlesCardBlock } from 'Blocks'
import ContentArea from 'Components/ContentArea'
import { addWebPToImageURL } from 'Helpers/utilities'


const BlogParentPage = ({ data, inEditMode }) => {
  const { Heading, Subheading, FeaturedImage, MainContentArea, BlogSearchWithRelatedArticles, BlogParentPages, RelatedArticles, Category } = data
  
  return (
    <>
      <div className="bg-right-bottom bg-no-repeat bg-cover" style={{ backgroundImage: FeaturedImage ? `url(${addWebPToImageURL(FeaturedImage?.Url)})` : '' }}>
        <div className="container mx-auto max-w-screen-xl">
          <div className="md:mb-3 mb-2
                          xl:ml-24 lg:ml-20 md:ml-12">
            <div className="md:mx-0 mx-6 lg:grid lg:grid-cols-12 gap-6 pb-4">
              <div className="col-span-7 xl:pt-18 lg:pt-10 md:pt-8 pt-6 relative">
                <h1 className="lg:text-6xl md:text-3xl text-2xl font-medium font-display tracking-tightest">
                  <span data-epi-edit="Heading">
                    {Heading}
                    {inEditMode && !Heading && 'Click to edit Heading'}
                  </span>
                </h1>
                <div className={`grid ${Subheading ? 'grid-cols-2' : ''} gap-2 lg:block mt-4 lg:mt-0 md:h-40`}>
                  <h3 className="lg:mt-10 lg:text-2xl text-sm font-display tracking-tight">
                    <span data-epi-edit="Subheading">
                      {Subheading}
                      {inEditMode && !Subheading && 'Click to edit Subheading'}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          {inEditMode && (
            <>
              <div className="mt-5 text-sm text-gray-500 inline-block" data-epi-edit="FeaturedImage">Change image</div>
              <div className="ml-5 text-sm text-gray-500 inline-block" data-epi-edit="Category">Change category</div>
            </>
          )}
        </div>
      </div>
      <BlogSearchWithRelatedArticlesCardBlock data={{ ...BlogSearchWithRelatedArticles, RelatedArticles, BlogParentPages }} name="BlogSearchWithRelatedArticles" inEditMode={inEditMode} activeCategory={Category[0]} />
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(BlogParentPage)
