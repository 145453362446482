import { useEffect } from 'react'
import useInEditMode from './useInEditMode'

export default function useCopyContent () {
  const eventHandler = (e) => {
    const pagelink = `\n\nRead more: ${document.title} ${document.location.href}`
    e.clipboardData.setData('text', document.getSelection() + pagelink)
    e.preventDefault()
  }
  const inEditMode = useInEditMode()

  useEffect(() => {
    if (inEditMode) return
    window.document.addEventListener('copy', eventHandler, false)
    return () => window.document.removeEventListener('copy', eventHandler)
  }, [inEditMode])
}
