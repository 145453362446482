import Block from './Block'
import HeaderBlock from './HeaderBlock'
import FooterBlock from './FooterBlock'

import Blog from './Blog'
import Calculators from './Calculators'
import Forms from './Forms'
import Homepage from './Homepage'
import Main from './Main'
import Utilities from './Utilities'
import VideoLibrary from './VideoLibrary'

import RoundedButtonBlock from './RoundedButtonBlock'
import ChildHeroBannerBlock from './ChildHeroBannerBlock'
import BlogSearchWithRelatedArticlesCardBlock from './BlogSearchWithRelatedArticlesCardBlock'
import ExitSiteSettingsBlock from './Utilities/ExitSiteSettingsBlock'

export default {
  Block,
  HeaderBlock,
  FooterBlock,

  ...Blog,
  ...Calculators,
  ...Forms,
  ...Homepage,
  ...Main,
  ...Utilities,
  ...VideoLibrary
}

export {
  HeaderBlock,
  FooterBlock,
  RoundedButtonBlock,
  ChildHeroBannerBlock,
  BlogSearchWithRelatedArticlesCardBlock,
  ExitSiteSettingsBlock
}
