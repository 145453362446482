
import React from 'react'

import PinSVG from 'SVGs/pin.svg'

const Marker = ({ $hover, isActive }) => {
  return <PinSVG className={`${$hover || isActive ? 'w-10 h-10' : 'w-8 h-8'} ${isActive ? 'animate-bounce-marker' : ''} cursor-pointer transform -translate-x-1/2 -translate-y-full`} />
}

export default React.memo(Marker)
