import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { RoundedButtonBlock } from 'Blocks'

const CTARenderer = ({ option, link, btn, inEditMode }) => {
  const CTAOption = () => {
    if (!inEditMode) return null
    return <div className="mt-5 text-sm text-gray-500" data-epi-edit="CtaOption">Click to change CTA option</div>
  }

  if (option === 'none') {
    return <CTAOption />
  }

  if (option === 'button') {
    return (
      <>
        <RoundedButtonBlock data={btn} name="CtaButton" inEditMode={inEditMode} />
        <CTAOption />
      </>
    )
  }

  return (
    <>
      {link && (
        <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
          <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{link.Text}</span>
        </RouterLinkTemplate>
      )}
      {!link && inEditMode && (
        <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet">
          Click to edit Link
        </span>
      )}
      <CTAOption />
    </>
  )
}

const CallToActionBlock = ({ data, inEditMode }) => {
  const { CtaOption, CtaButton, CtaLink, TextAlign } = data
  const Link = CtaLink.Value[0]

  let textAlign
  switch (TextAlign) {
    case 'center':
      textAlign = 'text-center'
      break
    case 'right':
      textAlign = 'text-right'
      break
    default:
      textAlign = 'text-left'
      break
  }

  const TextAlignOption = () => {
    if (!inEditMode) return null
    return <div className="mt-5 text-sm text-gray-500" data-epi-edit="TextAlign">Click to change text alignment</div>
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className={`xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6 ${textAlign}`}>
        <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode} />
        <TextAlignOption />
      </div>
    </div>
  )
}

export default React.memo(CallToActionBlock)
