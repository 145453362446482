import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock.jsx'
import { Markdown } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const BecomeAMemberBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Content, Button, LoginPopupMemberNonmemberReference } = data
  const markdown = { __html: Content }
  const showLoginButtonInstead = Button?.Url === null

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6 lg:py-10 md:py-7 py-4">
        <div className="grid justify-center" data-epi-edit="Image">
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="55" height="55" />
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="35" height="35" />
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
        <h2 data-epi-edit="Heading" className="lg:text-4xl text-xl font-medium font-display md:my-8 my-4 lg:block text-center">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <div className="grid grid-cols-12 md:mb-8 mb-4">
          <Markdown data={Content} name='Content' className="md:col-span-8 md:col-start-3 col-span-10 col-start-2 lg:text-xl sm:text-base text-sm font-body" />
        </div>
        <div className="grid justify-center">
          {!showLoginButtonInstead ? 
            <RoundedButtonBlock data={Button} name='Button' inEditMode={inEditMode} />
            :
            LoginPopupMemberNonmemberReference && 
              <LoginPopupMemberNonmemberFormBlock data={LoginPopupMemberNonmemberReference} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} />}
        </div>
      </div>
    </div>
  )
}

export default React.memo(BecomeAMemberBlock)
