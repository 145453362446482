import React from 'react'

const LoginPopupFormBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, UsernamePlaceholder, LoginButtonText, ForgotPasswordText, EnrollButtonText } = data
  if (!inEditMode && (!Heading || !UsernamePlaceholder || !LoginButtonText)) return null

  return (
    <div className="inline-block bg-white rounded-lg shadow transform lg:py-12 md:px-20 px-12 py-8 max-w-sm w-full text-left mx-auto font-body font-normal">
      <h2 className="mb-2.5 text-2xl font-display font-semibold" data-epi-edit="Heading">
        {Heading}
      </h2>
      <p className="text-lg mb-8" data-epi-edit="Subheading">{Subheading}</p>
      <form method="POST" action="https://secure.wellbyfinancial.com/login" autoComplete="false">
        <input type="text" name="username" data-epi-edit="UsernamePlaceholder" required autoFocus spellCheck="false" autoCorrect="false" autoCapitalize="false" placeholder={UsernamePlaceholder}
          className="block border rounded-full font-display placeholder-gray-500 text-gray-500 px-4 py-2 lg:py-0 mb-2 w-full outline-none" />
        {ForgotPasswordText && <a data-epi-edit="ForgotPasswordText" href="https://secure.wellbyfinancial.com/forgot" target="_blank" rel="noreferrer" className="float-right mb-2 text-violet hover:text-violet-dark font-display">{ForgotPasswordText}</a>}
        <button type="submit" data-epi-edit="LoginButtonText" className="block text-lg whitespace-nowrap font-medium font-display bg-violet w-full px-3 py-2 rounded-full text-white hover:bg-violet-dark hover:shadow-md outline-none my-4">
          {LoginButtonText}
        </button>
        {EnrollButtonText && (
          <a data-epi-edit="EnrollButtonText" href="https://secure.wellbyfinancial.com/enroll" target="_blank" rel="noreferrer" className="text-center block text-lg whitespace-nowrap font-medium font-display bg-violet w-full px-3 py-2 rounded-full text-white hover:bg-violet-dark hover:shadow-md outline-none my-4">
            {EnrollButtonText}
          </a>
        )}
      </form>
    </div>
  )
}

export default React.memo(LoginPopupFormBlock)
