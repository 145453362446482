import CallToActionBlock from './CallToActionBlock'
import DisclosurePopupBlock from './DisclosurePopupBlock'
import EmptyMarginBlock from './EmptyMarginBlock'
import PdfGeneratorLinkBlock from './PdfGeneratorLinkBlock'
import ReduceMarginBlock from './ReduceMarginBlock'
import SeparatorBlock from './SeparatorBlock'
import WellbySelectDropdownComponent from './WellbySelectDropdown/WellbySelectDropdownComponent'
import ExitSiteSettingsBlock from './ExitSiteSettingsBlock'

export default {
  CallToActionBlock,
  DisclosurePopupBlock,
  EmptyMarginBlock,
  PdfGeneratorLinkBlock,
  ReduceMarginBlock,
  SeparatorBlock,
  WellbySelectDropdownComponent,
  ExitSiteSettingsBlock
}
