import React from 'react'

import './StarsBackground.css'

const StarsBackground = () => {
  return (
    <>
      <div id="stars-group-1" className="mx-auto"></div>
      <div id="stars-group-2"></div>
      <div id="stars-group-3" className="mx-auto"></div>
      <div id="stars-group-4"></div>
      <div id="stars-group-5" className="mx-auto"></div>
      <div id="stars-group-6"></div>
    </>
  )
}

export default React.memo(StarsBackground)
