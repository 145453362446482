import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => { ref.current = value })
  return ref.current
}

export default function useLocationPathChange (action) {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' }) // scroll to top on page change
    if (typeof prevLocation === 'undefined') {
      return
    }
    action(location.pathname)
  }, [location])
}
