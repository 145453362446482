import React from 'react'
import { RoundedButtonBlock } from 'Blocks'
import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const CTARenderer = ({ option, link, btn, inEditMode }) => {
  const CTAOption = () => {
    if (!inEditMode) return null
    return <div className="mt-5 text-sm text-gray-500" data-epi-edit="CtaOption">Click to change CTA option</div>
  }
  if (option === 'none') {
    return <CTAOption />
  }
  if (option === 'button') {
    return (
      <>
        <RoundedButtonBlock data={btn} name="CtaButton" inEditMode={inEditMode} />
        <CTAOption />
      </>
    )
  }
  return (
    <>
      {link && (
        <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
          <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{link.Text}</span>
        </RouterLinkTemplate>
      )}
      {!link && inEditMode && (
        <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet">
          Click to edit Link
        </span>
      )}
      <CTAOption />
    </>
  )
}

const IllustrationContentBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { Heading, Subheading, Image, HeadingLocation, ImageLocation, CtaOption, CtaButton, CtaLink } = data
  const Link = CtaLink.Value[0]

  const ImageContainer = () => {
    if (CtaButton) {
      return (
        <div className="justify-self-center self-center">
          <div data-epi-edit="Image" className="md:py-0 py-6 xl:mt-9 sm:mt-5 mt-4">
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="270" height="170" className='lazy' />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          {inEditMode && (
            <div className="mt-5 text-sm text-gray-500" data-epi-edit="ImageLocation">Change image location option</div>
          )}
        </div>
      )
    }

    return (
      <div className="justify-self-center self-center">
        <div data-epi-edit="Image" className="md:py-0 py-6">
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="270" height="170" className='lazy' />
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
        {inEditMode && (
          <div className="mt-5 text-sm text-gray-500" data-epi-edit="ImageLocation">Change image location option</div>
        )}
      </div>
    )
  }

  const ContentContainer = () => {
    if (HeadingLocation === 'right') {
      return (
        <div className="text-left self-center xl:pr-20">
         <h3 className="text-right lg:text-2xl md:text-lg text-base font-medium font-display pb-6">
           <span data-epi-edit="Heading">
             {Heading}
             {inEditMode && !Heading && 'Click to edit Heading'}
           </span>
         </h3>
         <h5 className="text-justify lg:text-lg md:text-base text-sm font-body md:leading-6 leading-4 md:tracking-normal tracking-tighter">
           <span data-epi-edit="Subheading">
             <p>{Subheading}</p>
             {inEditMode && !Subheading && 'Click to edit Subheading'}
           </span>
         </h5>
         <div className="mt-5 flex justify-end">
           <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode}/>
         </div>
       </div>
      )
    }

    return (
      <div className="text-left self-center">
        <h3 className="lg:text-2xl md:text-lg text-base font-medium font-display pb-6">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h3>
        <h5 className="lg:text-lg md:text-base text-sm font-body md:leading-6 leading-4 md:tracking-normal tracking-tighter">
          <span data-epi-edit="Subheading">
            <p>{Subheading}</p>
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h5>
        <div className="mt-5 flex justify-end">
          <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode}/>
        </div>
      </div>
    )
  }

  const Layout = () => {
    if (ImageLocation === 'right') {
      return (
        <div className="grid grid-cols-2 lg:gap-10 gap-6 justify-items-stretch">
          <ContentContainer/>
          <ImageContainer/>
        </div>
      )
    }

    return (
      <div className="grid grid-cols-2 lg:gap-10 gap-6 justify-items-stretch">
        <ImageContainer/>
        <ContentContainer/>
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-52 lg:mx-48 md:mx-32 sm:mx-8 mx-6
                      lg:py-8 md:py-6 py-4">
        <Layout/>
      </div>
    </div>
  )
}

export default React.memo(IllustrationContentBlock)
