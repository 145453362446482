import React from 'react'

import { contentAreaDataMapper } from 'Helpers/transform'
import { Markdown } from 'Components'
import CardBlock from './CardBlock'

const ProductFeatureCardBlock = ({ data, inEditMode }) => {
  const { Heading, Content, MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl lg:py-16 md:py-10 py-6">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6">
        {(Heading || inEditMode) && (<div className="grid justify-items-center">
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display md:pb-8 pb-4">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
        </div>)}
        {(Content || inEditMode) && (<div className="md:pb-8 pb-4">
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        </div>)}
      </div>
      <div data-epi-edit="MainContentArea" className={`lg:overflow-visible overflow-x-scroll hidden-scrollbars whitespace-nowrap
        ${MainContentArea && MainContentArea.length <= 2 ? 'flex justify-around xl:mx-72 lg:mx-40 md:mx-16' : 'lg:flex justify-around xl:mx-28 lg:mx-16'}`}>
        {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
          const { ContentLink } = block
          const { Id } = ContentLink
          return (
            <div data-epi-block-id={Id} key={i} className="inline-block">
              <CardBlock data={blockData[Id]} inEditMode={inEditMode} size={MainContentArea && MainContentArea.length <= 2 ? 'base' : 'small'} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(ProductFeatureCardBlock)
