import React from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import BusinessCommunityCardBlock from './BusinessCommunityCardBlock'

const BusinessCommunityBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data

  if (!MainContentArea && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-36 lg:mx-32 md:mx-28 sm:mx-24 mx-12 lg:py-20 md:py-16 sm:py-12 py-8">
      <div data-epi-edit="MainContentArea" className="grid grid-cols-3 lg:gap-y-24 md:gap-y-20 sm:gap-y-16 gap-y-12 lg:gap-x-24 md:gap-x-20 sm:gap-x-16 gap-x-12">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <BusinessCommunityCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(BusinessCommunityBlock)
