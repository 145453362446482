import React from 'react'
import { RouterLinkTemplate } from 'Components'

const SelectaTopicCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Url } = data
  const Link = Url.Value[0]

  return (
    <div className="shadow-normal cursor-pointer rounded-lg hover:shadow-active h-full grid content-between xl:p-5 lg:p-4 md:p-3 sm:p-2 p-1">
      <h2 data-epi-edit="Heading" className="lg:text-2xl md:text-xl sm:text-lg text-xs font-semibold font-display lg:mr-6">
        {Heading} {inEditMode && !Heading && 'Click to edit Heading'}
      </h2>
      <h5 data-epi-edit="Subheading" className="xl:text-2xl lg:text-lg md:text-base sm:text-sm text-xxs font-normal font-display xl:my-6 lg:my-5">
        {Subheading} {inEditMode && !Subheading && 'Click to edit Heading'}
      </h5>
        {Link && (
          <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
            <span data-epi-edit="Url" className="xl:text-2xl lg:text-xl md:text-lg sm:text-base text-xs font-semibold text-violet">{Link.Text}</span>
          </RouterLinkTemplate>
        )}
    </div>
  )
}

export default React.memo(SelectaTopicCardBlock)
