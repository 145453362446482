import React from 'react'

import { RouterLinkTemplate } from 'Components'

const DataRowRenderer = ({ rowData, inEditMode, bgViolet }) => {
  return rowData?.Value?.map((link, i) => {
    return (
      <RouterLinkTemplate key={i} Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
        <span className={`lg:text-lg text-sm ${bgViolet ? 'hover:text-gray-300' : 'hover:text-gray-600'}`}>
            {link.Text}
        </span>
      </RouterLinkTemplate>
    )
  })
}

export default DataRowRenderer
