import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const RatesCardBlock = ({ data, inEditMode, isActive }) => {
  const { Image, Heading, AnnualPercentageRate, AnnualPercentageRateDetail, Term, TermDetail, Url } = data
  if (!Heading && !Image && !inEditMode) return null
  const Link = Url.Value[0]

  return (
    <div className={`grid justify-items-center md:p-8 sm:p-4 p-2 max-w-xs ${isActive ? 'border-violet shadow-active md:gap-4 gap-2' : 'shadow-normal md:gap-3'}
      border border-gray-100 cursor-pointer rounded-lg bg-white`}>
      <div data-epi-edit="Image" className="grid items-center sm:h-28 h-12">
        <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden lg:block" width="120" height="120"/>
        <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy lg:hidden sm:block hidden" width="84" height="84"/>
        <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy sm:hidden" width="44" height="44"/>
        {inEditMode && !Image && 'Click to edit Image'}
      </div>
      <h3 data-epi-edit="Heading" className="font-display font-medium lg:text-3xl md:text-xl sm:text-base text-xs text-violet self-center">{Heading}</h3>
      <div className="grid grid-cols-2 lg:mx-2 xl:mx-6">
        <p className="lg:text-xs sm:text-xxs text-xxxs" data-epi-edit="AnnualPercentageRate">{AnnualPercentageRate}</p>
        <p className="lg:text-xs sm:text-xxs text-xxxs" data-epi-edit="Term">{Term}</p>
        <p className="lg:text-base sm:text-sm text-xxs" data-epi-edit="AnnualPercentageRateDetail">{AnnualPercentageRateDetail}</p>
        <p className="lg:text-base sm:text-sm text-xxs" data-epi-edit="TermDetail">{TermDetail}</p>
      </div>
      <div className={isActive ? 'pb-1' : ''}>
        {Link && (
          <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
            <span data-epi-edit="Url" className="lg:text-base sm:text-sm text-xs font-medium font-display text-violet hover:text-violet-light">{Link.Text}</span>
          </RouterLinkTemplate>
        )}
      </div>
    </div>
  )
}

export default React.memo(RatesCardBlock)
