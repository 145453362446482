import React from 'react'
import ResponsiveEmbed from 'react-responsive-embed'

const VideoBlock = ({ data, inEditMode }) => {
  const { VideoLink } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-64 lg:mx-52 md:mx-36 sm:mx-24 mx-12 lg:py-12 md:py-8 py-4">
        <div className="items-center">
          <div data-epi-edit="VideoLink">
            <ResponsiveEmbed src={VideoLink} allowFullScreen />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(VideoBlock)
