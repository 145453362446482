import React from 'react'

import { contentAreaDataMapper } from 'Helpers/transform'
import CompareCardStylesCardBlock from './CompareCardStylesCardBlock'

const CompareCardStylesBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea, Content } = data
  const blockData = contentAreaDataMapper(MainContentArea)
  const markdown = { __html: Content }

  return (
    <div className="container mx-auto max-w-screen-xl relative">
      <div className="xl:mx-28 lg:mx-12
                        lg:py-8 md:py-6 py-4
                        grid lg:gap-12 gap-8">
        <h3 data-epi-edit="Heading" className="lg:text-3xl text-xl font-display font-medium text-center">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h3>
        <div data-epi-edit="MainContentArea" className="md:overflow-visible overflow-x-scroll hidden-scrollbars grid">
          <div className="flex lg:gap-x-8 gap-4 md:flex-wrap justify-center whitespace-nowrap">
            {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
              const { ContentLink } = block
              const { Id } = ContentLink
              return (
                <div key={i} className="m-5 md:m-0">
                  <CompareCardStylesCardBlock data={blockData[Id]} inEditMode={inEditMode} />
                </div>
              )
            })}
          </div>
        </div>
        <div data-epi-edit="Content" className="lg:text-xs sm:text-xxs text-xxxs font-display italic font-medium md:mx-12 sm:mx-8 mx-6" dangerouslySetInnerHTML={markdown} />
      </div>
    </div>
  )
}

export default React.memo(CompareCardStylesBlock)
