import React, { useContext } from 'react'

import { AppContext } from '../contexts'

const HiddenLinks = () => {
  // This component is to warm up unconnected childs from start page
  const { appContext } = useContext(AppContext)
  const { data } = appContext
  const { HiddenLinks } = data
  if (HiddenLinks && HiddenLinks.Value && HiddenLinks.Value.length > 0) {
    return (
      <>
      {HiddenLinks.Value.map((val, i) => {
        return <a key={i} href={val.Href} className="hidden"/>
      })}
      </>
    )
  }
  return null
}

export default React.memo(HiddenLinks)
