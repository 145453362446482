import React from 'react'
import HolidayEventCardBlock from './HolidayEventCardBlock'
import { RoundedButtonBlock } from 'Blocks'
import { contentAreaDataMapper } from 'Helpers/transform'

const HolidayEventBlock = ({ data, inEditMode }) => {
  const { MainContentArea, Button } = data

  if (!MainContentArea && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-24 sm:mx-20 mx-10 lg:py-16 md:py-12 sm:py-10 py-6">
      <div data-epi-edit="MainContentArea" className="grid grid-cols-3 xl:gap-x-14 lg:gap-x-12 md:gap-x-10 sm:gap-x-8 gap-x-4
                                                         xl:gap-y-10 lg:gap-y-8 md:gap-y-6 sm:gap-y-4 gap-y-3">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <HolidayEventCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
        <div className="grid justify-items-center lg:mt-10 md:mt-7 sm:mt-5 mt-3">
            <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
          </div>
      </div>
    </div>
  )
}

export default React.memo(HolidayEventBlock)
