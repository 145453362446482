import React, { useEffect } from 'react'
import './ContactInfoFormBlock.css'

import formRenderingService from 'Helpers/formRenderingService'
import { useQueryParams } from 'Hooks'
import api from 'Helpers/api'

const RenderTemplate = (formModel, ele) => {
  const formRendered = formRenderingService.render(formModel, ele)
  if (!formRendered) return

  const submitButton = document.querySelector('.ContactInfoFormBlock .Form__Element.FormSubmitButton')
  if (submitButton) {
    const wrapper = document.createElement('div')
    submitButton.parentNode.insertBefore(wrapper, submitButton)
    wrapper.appendChild(submitButton)
  }
}

const ContactInfoFormBlock = ({ data, inEditMode }) => {
  const { Title, Description, FormModel, ContentLink } = data
  const queryParams = useQueryParams()
  useEffect(() => {
    const element = document.getElementById('formContainer_' + ContentLink.Id)
    if (FormModel) {
      RenderTemplate(FormModel, element)
      return
    }

    api.getContentByContentLink(ContentLink.Id, queryParams)
      .then(res => RenderTemplate(res.FormModel, element))
      .catch(err => console.log(err))
  }, [])

  return (
    <div className="container mx-auto max-w-screen-xl py-14 grid text-center justify-center relative overflow-hidden ContactInfoFormBlock">
      <div className="relative">
        <h2 className="justify-self-center lg:text-4xl text-xl font-medium font-display">
          <span data-epi-edit="Title">
            {Title}
            {inEditMode && !Title && 'Click to edit Title'}
          </span>
        </h2>
        <div className="justify-self-center font-body lg:text-base text-sm pt-10 px-16">
          <span data-epi-edit="Description">
            {Description}
            {inEditMode && !Description && 'Click to edit Description'}
          </span>
        </div>
        <span data-epi-edit="ElementsArea" className="font-display">
          <div id={'formContainer_' + ContentLink.Id}></div>
          {inEditMode && 'Click to edit Form elements'}
        </span>
      </div>
    </div>
  )
}

export default React.memo(ContactInfoFormBlock)
