import React from 'react'

import { Tooltip, RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const StillCantFindlookingForCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Subheading, Url } = data

  return (
    <Tooltip tooltipText={Subheading}>
      <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
        <div className="shadow-normal cursor-pointer rounded-lg hover:shadow-active h-full grid content-between justify-items-center lg:p-4 sm:p-3 p-2">
          <div data-epi-edit="Image" className="lg:my-6 md:my-4 my-2">
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="35" height="40" />
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="15" height="20" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          <h4 data-epi-edit="Heading"
            className="lg:text-lg md:text-base sm:text-sm text-xs font-semibold text-center">
            {Heading}
          </h4>
        </div>
      </RouterLinkTemplate>
    </Tooltip>
  )
}

export default React.memo(StillCantFindlookingForCardBlock)
