import React, { useState, useEffect } from 'react'

import { ChildHeroBannerBlock } from 'Blocks'
import { useLocationPathChange } from 'Hooks'
import ContentArea from 'Components/ContentArea'

const OnPageNav = ({ data, name, inEditMode }) => {
  if ((!data || !data.Value || data.Value.length === 0) && !inEditMode) return null
  const [activeIndex, setActiveIndex] = useState(0)

  function handleClick (e, index) {
    e.preventDefault()
    setActiveIndex(index)
    let blockId = data.Value[index]
    if (blockId) blockId = blockId.replaceAll(' ', '')
    const elmnt = document.getElementById(blockId)
    if (elmnt) elmnt.scrollIntoView({ behavior: 'smooth' })
    window.history.pushState(null, null, `#${blockId}`)
  }

  useLocationPathChange(() => {
    setActiveIndex(0)
  })

  return (
    <div data-epi-edit={name} className="container mx-auto max-w-screen-xl lg:text-lg lg:font-normal font-medium font-display text-xs">
      <div className="xl:ml-24 lg:ml-20 md:ml-12 flex items-end border-b-2 border-gray-300 md:overflow-visible overflow-x-scroll hidden-scrollbars whitespace-nowrap">
        {data && (!data.Value || data.Value.length === 0) && (
          <span className="border-b-3 border-fuchsia pb-3">Click to Edit</span>
        )}
        {data && data.Value && data.Value.length !== 0 && data.Value.map((link, i) => (
          <div key={i} onClick={(event) => handleClick(event, i)} className={`${i === activeIndex ? 'border-fuchsia nav-shadow-fuchsia' : 'border-transparent'} border-b-2 pb-2 mr-8 ml-2 md:ml-0`}>
            <a href={`#${link.replaceAll(' ', '')}`} className="hover:text-gray-600">
              {link}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

const ProductPage = ({ data, inEditMode }) => {
  const { MainContentArea, HeroBanner, AnchorLinks } = data
  return (
    <>
      <ChildHeroBannerBlock data={HeroBanner} name='HeroBanner' inEditMode={inEditMode} hideSearchInput={true} />
      <OnPageNav data={AnchorLinks} name='AnchorLinks' inEditMode={inEditMode} />
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(ProductPage)
