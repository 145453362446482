import React from 'react'

import { RoundedButtonBlock } from 'Blocks'

const CompareWellbySavAccCardBlock = ({ data, inEditMode }) => {
  const { Subheading, Heading, Content, APY, APYDetail, Fees, FeesDetails, MinBalance, MinBalanceDetail, FDICInsured, FDICInsuredDetail, Button, ContentLink } = data
  const { Id } = ContentLink

  return (
    <div data-epi-block-id={Id} className="group shadow-normal hover:shadow-active rounded-lg bg-white md:p-6 p-4 md:gap-0 gap-1 lg:w-60 md:w-56 w-48 lg:h-72 md:h-64 h-60">
      <h6 data-epi-edit="Subheading" className="font-display text-center whitespace-pre-wrap lg:text-base text-sm font-light mb-0.5">{Subheading}</h6>
      <div data-epi-edit="Heading" className="font-display text-center whitespace-pre-wrap lg:text-lg lg:leading-tight font-medium mb-3">{Heading}</div>
      <div className="grid md:gap-2 gap-1">
        <div data-epi-edit="Content" className="lg:text-xxs md:text-xxxs text-xxxs text-center font-body lg:mb-5 md:mb-3 mb-2">{Content}</div>
        <div className="grid divide-y divide-gray-400 sm:text-xsmall text-xxs font-display">
          <div className="flex justify-between py-1">
            <span className="text-gray-400" data-epi-edit="APY">{APY}</span>
            <span className="font-medium" data-epi-edit="APYDetail">{APYDetail}</span>
          </div>
          <div className="flex justify-between py-1">
            <span className="text-gray-400" data-epi-edit="Fees">{Fees}</span>
            <span className="font-medium" data-epi-edit="FeesDetails">{FeesDetails}</span>
          </div>
          <div className="flex justify-between py-1">
            <span className="text-gray-400" data-epi-edit="MinBalance">{MinBalance}</span>
            <span className="font-medium" data-epi-edit="MinBalanceDetail"> {MinBalanceDetail}</span>
          </div>
          <div className="flex justify-between py-1">
            <span className="text-gray-400" data-epi-edit="FDICInsured">{FDICInsured}</span>
            <span className="font-medium" data-epi-edit="FDICInsuredDetail">{FDICInsuredDetail}</span>
          </div>
        </div>
        <span className="text-center lg:mt-2 mt-1">
          <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
        </span>
      </div>
    </div>
  )
}

export default React.memo(CompareWellbySavAccCardBlock)
