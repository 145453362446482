import { CommunityCentricBlock, IconFeaturedBlock } from './CommunityCentricBlock'
import { HelpingMembersBlock, HelpingMembersCardBlock } from './HelpingMembersBlock'
import { CardBlock, ProductFeatureCardBlock } from './ProductFeatureCardBlock'
import { PromotionCarouselBlock, PromotionCarouselCardBlock } from './PromotionCarouselBlock'
import { RatesBlock, RatesCardBlock } from './RatesBlock'

import DiscoverTheDifferenceBlock from './DiscoverTheDifferenceBlock'
import WelcomeBlock from './WelcomeBlock'

export default {
  CommunityCentricBlock,
  IconFeaturedBlock,
  HelpingMembersBlock,
  HelpingMembersCardBlock,
  CardBlock,
  ProductFeatureCardBlock,
  PromotionCarouselBlock,
  PromotionCarouselCardBlock,
  RatesBlock,
  RatesCardBlock,

  DiscoverTheDifferenceBlock,
  WelcomeBlock
}
