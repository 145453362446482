import React, { useEffect, useState } from 'react'

import { Markdown } from 'Components'
import InfoCircleSVG from 'SVGs/infoCircle.svg'

const Modal = ({ data, name, isOpen, onClose, inEditMode }) => {
  if (!isOpen) return null

  const [open, setOpen] = useState(isOpen)
  function downHandler (e) {
    if (e.keyCode === 27) {
      closeModal()
    }
  }

  const closeModal = () => {
    setOpen(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  if (!open) return null

  return (
    <div style={{ zIndex: 10000000000 }} className="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-2">
        <div className="fixed inset-0" aria-hidden="true" onClick={() => closeModal()}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full
                        rounded-lg shadow bg-white lg:max-w-screen-md md:px-20 px-12 md:py-16 py-10 font-body text-left">
          <button className="border hover:bg-gray-100 md:py-2 sm:py-0.5 md:px-4 sm:px-2 px-1.5 rounded-lg cursor-pointer absolute top-3 right-3 z-10" onClick={() => closeModal()}>
            <span className="md:text-4xl md:leading-9 leading-4 text-center text-violet">&times;</span>
          </button>
          <Markdown data={data} name={name} inEditMode={inEditMode} />
        </div>
      </div>
    </div>
  )
}

const DisclosurePopupBlock = ({ data, inEditMode }) => {
  const { Text, Content } = data
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:-mt-5 md:-mt-2.5 -mt-1 lg:pb-10 md:pb-7 pb-4">
        <span className="text-gray-700 hover:text-violet cursor-pointer relative md:text-base text-xs">
          <InfoCircleSVG width="1rem" height="1rem" className="absolute top-1/2 transform -translate-y-1/2" data-epi-edit="Content"/>
          <span className="ml-6 font-body absolute top-1/2 transform -translate-y-1/2" data-epi-edit="Text" onClick={() => setModalOpened(true)}>{Text}</span>
        </span>
        <Modal isOpen={modalOpened} onClose={() => setModalOpened(false)} data={Content} name="Content" inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(DisclosurePopupBlock)
