import React from 'react'

import { RoundedButtonBlock } from 'Blocks'

const ComparableProductsBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { Heading, ProductText, ApyText, DividendRateText, MinOpeningBalanceText, MinEarningText, AccountTypes } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-20 lg:mx-12 md:mx-8
                      lg:py-16 md:py-10 py-6">
        {(Heading || inEditMode) && (<div className="grid justify-items-center sm:mx-8 mx-6">
          <h2 className="lg:text-4xl md:text-2xl text-lg font-medium font-display xl:mb-14 lg:mb-11 md:mb-8 sm:mb-6 mb-4 text-center" data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </h2>
        </div>)}
        <table className="table-fixed border border-gray-300 w-full font-body lg:text-base md:text-sm text-xxs">
          <thead className="text-left border-b-2">
            <tr>
              <th className="p-4 lg:w-52 md:w-40 text-left" data-epi-edit="ProductText">{ProductText}</th>
              <th className="p-4 text-center" data-epi-edit="ApyText">{ApyText}</th>
              <th className="p-4 text-center" data-epi-edit="DividendRateText">{DividendRateText}</th>
              <th className="p-4 text-center lg:w-60 md:w-32" data-epi-edit="MinOpeningBalanceText">{MinOpeningBalanceText}</th>
              <th className="p-4 text-center" data-epi-edit="MinEarningText">{MinEarningText}</th>
              <th className="p-4 w-24 md:w-auto"></th>
            </tr>
          </thead>
          <tbody>
            {AccountTypes?.length > 0 && AccountTypes.map((val, i) => {
              const { DisplayName, Apy, DividendRate, MinOpeningBalance, MinEarning, CtaText, CtaUrl } = val
              return (
                <tr key={i} className={i % 2 === 1 ? 'bg-violet-tint' : ''}>
                  <td className="lg:p-4 p-2 px-2 border-t border-b border-gray-300 font-medium">{DisplayName}</td>
                  <td className="lg:p-4 p-2 px-0 border-t border-b border-gray-300 text-center">{Apy}</td>
                  <td className="lg:p-4 p-2 px-0 border-t border-b border-gray-300 text-center">{DividendRate}</td>
                  <td className="lg:p-4 p-2 px-0 border-t border-b border-gray-300 text-center">{MinOpeningBalance}</td>
                  <td className="lg:p-4 p-2 px-0 border-t border-b border-gray-300 text-center">{MinEarning}</td>
                  <td className="lg:p-4 p-2 px-0 border-t border-b border-gray-300">
                    <RoundedButtonBlock data={{ Name: CtaText, Style: i % 2 === 1 ? 'main' : 'secondary_violet', Size: 'sm', Url: CtaUrl }} inEditMode={inEditMode} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {inEditMode && (
          <div className="mt-5 text-sm text-gray-500" data-epi-edit="AccountTypes">Change account data</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(ComparableProductsBlock)
