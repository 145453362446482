import React from 'react'

import { useNProgress } from '@tanem/react-nprogress'

const Bar = ({ animationDuration, progress }) => {
  return (
    <div className="left-0 top-0 fixed w-full h-0.5"
      style={{
        background: '#54099e',
        marginLeft: `${(-1 + progress) * 100}%`,
        transition: `margin-left ${animationDuration}ms linear`,
        zIndex: 1031
      }}>
      <div className="inline-block h-full absolute right-0 w-24 transform rotate-3 translate-x-0 -translate-y-1"
        style={{
          boxShadow: '0 0 10px #54099e, 0 0 5px #54099e'
        }}
      />
    </div>
  )
}

const Container = ({ animationDuration, children, isFinished }) => {
  return (
    <div className="pointer-events-none"
      style={{
        opacity: isFinished ? 0 : 1,
        transition: `opacity ${animationDuration}ms linear`
      }}>
      {children}
    </div>
  )
}

export default function Progress ({ isAnimating }) {
  const { animationDuration, isFinished, progress } = useNProgress({ isAnimating })
  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
    </Container>
  )
}
