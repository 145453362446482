import React, { useRef, useState, useEffect } from 'react'

const Tooltip = ({ children, tooltipText }) => {
  const tipRef = useRef(null)
  const [position, setPosition] = useState('left')

  function handleMouseEnter () {
    tipRef.current.style.display = 'flex'
    tipRef.current.style.opacity = 1

    if (tipRef.current.getBoundingClientRect().x > document.body.offsetWidth / 2) setPosition('right')
    if (position === 'left') tipRef.current.style.marginLeft = '10px'
    if (position === 'right') tipRef.current.style.marginRight = '10px'
  }

  function handleMouseLeave () {
    tipRef.current.style.display = 'none'
    tipRef.current.style.opacity = 0

    if (position === 'left') tipRef.current.style.marginLeft = '5px'
    if (position === 'right') tipRef.current.style.marginRight = '5px'
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      handleMouseEnter();
    }
    else if (e.keyCode === 27) {
      handleMouseLeave();
    }
  }

  useEffect(() => {
    if (!tipRef?.current) return
    if (tipRef.current.getBoundingClientRect().x > document.body.offsetWidth / 2) setPosition('right')
  }, [tipRef])

  return (
    <span tabIndex={0} onKeyDown={(e) => handleKeyDown(e)} onFocus={handleMouseEnter} onBlur={handleMouseLeave} className="relative font-body lg:text-sm sm:text-xs text-xs">
      <span style={{ left: position === 'left' ? '100%' : '', right: position === 'right' ? '100%' : '', display: 'none', opacity: 0, minWidth: '320px' }} ref={tipRef}
        className="transform -translate-y-1/2 top-1/2 absolute whitespace-no-wrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded items-center transition-all duration-150 w-full z-50">
        {position === 'left' && <div className="bg-black h-2 w-2 absolute" style={{ left: '-4px', transform: 'rotate(45deg)' }} />}
        {position === 'right' && <div className="bg-gray-700 h-2 w-2 absolute" style={{ right: '-4px', transform: 'rotate(45deg)' }} />}
        <span dangerouslySetInnerHTML={{ __html: tooltipText }} />
      </span>
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{children}</span>
    </span>
  )
}

export default React.memo(Tooltip)
