import React from 'react'

import CarouselRenderer from './CarouselRenderer'
import CarouselMobileRenderer from './CarouselMobileRenderer'

const PromotionCarouselBlock = ({ data, inEditMode }) => {
  const { Heading, Content, MainContentArea } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-20 lg:mx-12 md:mx-8
                      lg:py-16 md:py-10 py-6">
        {(Heading || inEditMode) && (<div className="grid justify-items-center sm:mx-8 mx-6">
          <h2 className="lg:text-4xl md:text-2xl text-lg font-medium font-display md:py-4 py-2 text-center" data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </h2>
        </div>)}
        {(Content || inEditMode) && (<div data-epi-edit="Content" className="md:py-4 py-2 text-center xl:mx-48 md:mx-32 sm:mx-12 mx-3">
          {Content && <div className="font-body lg:text-lg text-sm" dangerouslySetInnerHTML={{ __html: Content }} />}
          {inEditMode && !Content && 'Click to edit Content'}
        </div>)}
        <CarouselRenderer data={MainContentArea} name='MainContentArea' inEditMode={inEditMode} />
        <CarouselMobileRenderer data={MainContentArea} name='MainContentArea' inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(PromotionCarouselBlock)
