import React from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'

const ArchivedEmailAnnouncementBlock = ({ data, inEditMode }) => {
  const { Content, Heading, EmailAnnouncements } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-52 lg:mx-48 md:mx-32 sm:mx-8 mx-6
                      lg:py-8 md:py-6 py-4">
        {(Heading || inEditMode) && (
          <h3 className="lg:text-3xl text-xl font-semibold font-display pb-6" data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </h3>
        )}
        <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        <div data-epi-edit="EmailAnnouncements">
          {EmailAnnouncements && EmailAnnouncements.length > 0 && EmailAnnouncements.map((val, i) => {
            const { Title, Description, Url, PublishedDate } = val
            const markdown = { __html: Description }
            const date = new Date(PublishedDate)

            return (
              <div key={i} className="pt-6 sm:px-10 px-6">
                <ul className="md:pl-0 pl-4 list-disc text-violet hover:text-violet-bright text-base md:text-xl font-display font-medium">
                  <li>
                    <RouterLinkTemplate Path={Url} Target="_blank" inEditMode={inEditMode}>
                      {Title}
                    </RouterLinkTemplate>
                  </li>
                </ul>
                {date && <div className="md:pt-2 pt-1 sm:text-base text-sm font-display font-medium" suppressHydrationWarning>{date.getMonth() + 1}-{date.getDate()}-{date.getFullYear()}</div>}
                {Description && <div className="md:py-3 py-2 sm:text-base text-sm font-body" dangerouslySetInnerHTML={markdown} />}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ArchivedEmailAnnouncementBlock)
