import React from 'react'

const SeparatorBlock = () => {
  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="lg:py-5 md:py-3.5 py-2">
        <div className="xl:ml-24 lg:ml-20 md:ml-12 border-b-2 border-gray-300" />
      </div>
    </div>
  )
}

export default React.memo(SeparatorBlock)
