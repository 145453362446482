import React from 'react'
import './BulletPointContentBlock.css'

import { Markdown } from 'Components'

const BulletPointContentBlock = ({ data, inEditMode }) => {
  const { Content, Image, ImageLocation } = data

  const Layout = () => {
    const ContentContainer = () => {
      return (
        <>
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
        </>
      )
    }

    const ImageContainer = () => {
      return (
        <>
          <div data-epi-edit="Image" className="md:py-0 py-6">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:max-h-full max-h-52" width="400" height="400" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          {inEditMode && (
            <div className="mt-5 text-sm text-gray-500" data-epi-edit="ImageLocation">Change image location option</div>
          )}
        </>
      )
    }

    const Inner = ({ imageLocation }) => {
      const contentContainerClass = 'col-span-7 grid lg:gap-6 sm:gap-4 gap-2 self-center lg:ml-20'
      const imageContainerClass = 'justify-self-center col-span-5'
      if (imageLocation === 'right') {
        return (
          <>
            <div className={`${contentContainerClass} text-left`}>
              <ContentContainer/>
            </div>
            <div className={`${imageContainerClass} lg:justify-self-end`}>
              <ImageContainer/>
            </div>
          </>
        )
      }

      return (
        <>
          <div className={`${imageContainerClass} lg:justify-self-start`}>
              <ImageContainer/>
            </div>
          <div className={`${contentContainerClass}`}>
            <ContentContainer/>
          </div>
        </>
      )
    }

    return (
      <div className="grid grid-cols-12 lg:gap-10 gap-6 justify-items-stretch items-end">
        <Inner imageLocation={ImageLocation} />
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl BulletPointContentBlock">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                   lg:py-10 md:py-7 py-4">
        <Layout/>
      </div>
    </div>
  )
}

export default React.memo(BulletPointContentBlock)
