import React from 'react'
import QuickLinksCardBlock from './QuickLinksCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const QuickLinksBlock = ({ data, inEditMode }) => {
  const { Heading, MainContentArea } = data
  if (!MainContentArea && !Heading && !inEditMode) return null

  const blockData = contentAreaDataMapper(MainContentArea)

  const dataLength = MainContentArea?.length ?? 0
  let gridCols = 'grid grid-cols-4'
  let marginX = 'xl:mx-64 lg:mx-48 md:mx-24 sm:mx-8 mx-6'
  if (dataLength % 5 === 0) {
    gridCols = 'grid grid-cols-5'
    marginX = 'xl:mx-48 lg:mx-40 md:mx-16 sm:mx-4 mx-2'
  } else if (dataLength < 4) {
    gridCols = 'flex justify-center'
  } else if (dataLength % 3 === 0) {
    gridCols = 'grid grid-cols-3 xl:px-28 lg:px-16 sm:px-12'
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className={`${marginX} lg:py-10 md:py-7 py-4`}>
        {(Heading || inEditMode) && <h2 data-epi-edit="Heading" className="lg:mb-24 md:mb-16 mb-10 md:text-4xl text-lg text-center font-bold font-display">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>}
        <div data-epi-edit="MainContentArea" className={`${gridCols} flex-1
                                                        md:gap-x-4 md:gap-y-12 gap-2
                                                        md:max-w-full max-w-md mx-auto`}>
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <QuickLinksCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(QuickLinksBlock)
