import React from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const DiscoverTheDifferenceBlock = ({ data, inEditMode }) => {
  if (!data) {
    if (!inEditMode) return null
    return <>Click to edit</>
  }

  const { Heading, Subheading, Image, Url, Content } = data
  const Link = Url.Value[0]

  const Layout = () => {
    return (
      <div className="grid grid-cols-12 md:gap-8 md:items-center items-stretch">
        <h2 className="col-span-12 text-xl font-medium font-display sm:hidden">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h2>
        <div className="col-span-7">
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display lg:absolute sm:block hidden">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
          <h3 className="lg:mt-10 lg:py-7 md:py-5 py-3 lg:text-2xl md:text-lg font-medium font-display">
            <span data-epi-edit="Subheading">
              {Subheading}
              {inEditMode && !Subheading && 'Click to edit Subheading'}
            </span>
          </h3>
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
          <div className="lg:py-7 pt-2 pb-7">
            {Link && (
              <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
                <span data-epi-edit="Url" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{Link.Text}</span>
              </RouterLinkTemplate>
            )}
            {!Link && inEditMode && (
              <span data-epi-edit="Url" className="lg:text-xl font-medium font-display text-violet">
                Click to edit Link
              </span>
            )}
          </div>
        </div>
        <div data-epi-edit="Image" className="pt-5 md:pt-0 col-span-5 justify-self-center">
            <img className="lazy w-full" data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="500" height="500"/>
            {inEditMode && !Image && 'Click to edit Image'}
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:py-18 lg:py-10 md:py-8 py-6
                      xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      relative overflow-hidden">
        <Layout />
      </div>
    </div>
  )
}

export default React.memo(DiscoverTheDifferenceBlock)
