import axios from 'axios'

import { toPascalCase } from 'Helpers/transform'

const get = (url, queryParams) => {
  const headers = { Accept: 'application/json', 'Accept-Language': 'en' }
  const params = queryParams ? { ...queryParams, expand: '*', currentPageUrl: window.location.pathname } : { expand: '*', currentPageUrl: window.location.pathname }
  const axiosConfig = {
    baseURL: '/',
    headers: Object.assign({}, headers),
    params
  }

  return axios.get(url, axiosConfig)
}

export default {
  getContentByContentLink: (contentLink, queryParams) => {
    return new Promise((resolve, reject) => {
      get(`api/episerver/v2.0/content/${contentLink}`, queryParams)
        .then(res => resolve(toPascalCase(res.data)))
        .catch(err => reject(err))
    })
  },
  getContentByFriendlyUrl: (friendlyUrl, queryParams) => {
    return new Promise((resolve, reject) => {
      get(friendlyUrl, queryParams)
        .then(res => resolve(toPascalCase(res.data)))
        .catch(err => reject(err))
    })
  },
  getChildren: (friendlyUrl) => {
    const urlPieces = friendlyUrl.split('?')
    const pathname = (urlPieces[0].endsWith('/') ? urlPieces[0] : urlPieces[0] + '/')
    const queries = urlPieces[1] ? '?' + urlPieces[1] : ''
    const callUrl = pathname + 'children' + queries

    return this.getContentByFriendlyUrl(callUrl)
  },
  getSearchAutoComplete: (prefix) => {
    const queryParams = { prefix }
    return new Promise((resolve, reject) => {
      get('find_v2/_autocomplete', queryParams)
        .then(res => resolve(toPascalCase(res.data)))
        .catch(err => reject(err))
    })
  },
  getSearchSpellCheck: (query) => {
    const queryParams = { query }
    return new Promise((resolve, reject) => {
      get('find_v2/_spellcheck', queryParams)
        .then(res => resolve(toPascalCase(res.data)))
        .catch(err => reject(err))
    })
  },
  getSearchRelatedQuery: (query) => {
    const queryParams = { query }
    return new Promise((resolve, reject) => {
      get('find_v2/_didyoumean', queryParams)
        .then(res => resolve(toPascalCase(res.data)))
        .catch(err => reject(err))
    })
  },
  getPdfLink: (templateName) => {
    return new Promise((resolve, reject) => {
      const headers = { Accept: 'application/json' }

      const axiosConfig = {
        baseURL: '/',
        headers: Object.assign({}, headers)
      }

      return axios.get(`api/PdfGeneratorApi?template=${templateName}`, axiosConfig)
        .then(res => {
          const response = toPascalCase(res.data)
          if (response.ResponseCode !== 200) reject(response)
          resolve(response.ResponseText)
        })
        .catch(err => reject(err))
    })
  }
}
