import React from 'react'
import { contentAreaDataMapper } from 'Helpers/transform'
import LoanRelatedCardBlock from './LoanRelatedCardBlock'

const LoanRelatedBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data

  if (!MainContentArea && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  // const dataLength = MainContentArea?.length ?? 0
  // let gridCols = 'grid grid-cols-2'
  // if (dataLength % 5 === 0) {
  //   gridCols = 'grid grid-cols-5'
  // } else if (dataLength < 4) {
  //   gridCols = 'flex justify-center'
  // } else if (dataLength % 3 === 0) {
  //   gridCols = 'grid grid-cols-3 xl:px-28 lg:px-16 sm:px-12'
  // }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-44 lg:mx-40 md:mx-24 sm:mx-44 mx-24  lg:py-20 md:py-16 sm:py-12 py-8">
      <div data-epi-edit="MainContentArea" className="grid md:grid-cols-2 sm:grid rows-2 md:gap-x-32 sm:gap-y-20 gap-y-12">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <LoanRelatedCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(LoanRelatedBlock)
