import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { Markdown, RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const CTARenderer = ({ option, link, btn, inEditMode }) => {
  const CTAOption = () => {
    if (!inEditMode) return null
    return <div className="mt-5 text-sm text-gray-500" data-epi-edit="CtaOption">Click to change CTA option</div>
  }
  if (option === 'none') {
    return <CTAOption />
  }
  if (option === 'button') {
    return (
      <>
        <RoundedButtonBlock data={btn} name="CtaButton" inEditMode={inEditMode} />
        <CTAOption />
      </>
    )
  }
  return (
    <>
      {link && (
        <RouterLinkTemplate Path={link.Href} inEditMode={inEditMode}>
          <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{link.Text}</span>
        </RouterLinkTemplate>
      )}
      {!link && inEditMode && (
        <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet">
          Click to edit Link
        </span>
      )}
      <CTAOption />
    </>
  )
}

const GoalInYourLifeBlock = ({ data, inEditMode }) => {
  const { Heading, Content, Image, CtaOption, CtaButton, CtaLink } = data
  const Link = CtaLink.Value[0]

  return (
    <div className="container mx-auto max-w-screen-xl relative">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="grid md:gap-6 gap-2 grid-cols-12">
          <div className="md:col-span-7 sm:col-span-6 col-span-12">
            <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display" data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </h2>
            <Markdown data={Content} name='Content' inEditMode={inEditMode} />
            <div className="lg:mt-5 md:mt-4 sm:mt-3 mt-2 flex justify-start">
              <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode} />
            </div>
          </div>
          <div data-epi-edit="Image" className="md:pt-0 pt-8 md:col-span-5 sm:col-span-6 col-span-12">
            <img className="lazy w-full px-8" data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="539" height="395" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(GoalInYourLifeBlock)
