import React from 'react'

import { RoundedButtonBlock } from 'Blocks'
import { addWebPToImageURL } from 'Helpers/utilities'

const WeOfferCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Content, ContentLink, Button } = data
  const { Id } = ContentLink
  const markdown = { __html: Content }

  return (
    <div data-epi-block-id={Id} className="grid content-between">
      <div className="grid justify-items-center">
        <div data-epi-edit="Image">
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy hidden md:block" width="40" height="40"/>
          <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:hidden" width="20" height="20"/>
          {inEditMode && !Image && 'Click to edit Image'}
        </div>
        <h4
          data-epi-edit="Heading"
          className="font-display font-medium lg:text-2xl md:text-lg sm:text-base text-sm text-center py-6">
          {Heading}
        </h4>
      </div>
      <div data-epi-edit="Content" className="font-body md:text-lg sm:text-sm text-xs text-center" dangerouslySetInnerHTML={markdown} />
      <div className="justify-self-center mt-6">
        <RoundedButtonBlock data={Button} name='Button' inEditMode={inEditMode} />
      </div>
    </div>
  )
}

export default React.memo(WeOfferCardBlock)
