import React, { useState, useEffect } from 'react'

import { SearchBar } from 'Components'
import { useLocationPathChange } from 'Hooks'
import ContentArea from 'Components/ContentArea'
import { addWebPToImageURL } from 'Helpers/utilities'

const ChildHeroBannerBlock = ({ data, name, inEditMode, hideSearchInput }) => {
  const { Heading, Subheading, Image, SearchPage, SearchText, CtaLink, CtaLink2, LoginPopupMemberNonmemberReference1, LoginPopupMemberNonmemberReference2 } = data
  if (!Heading && !Subheading && !Image) {
    if (inEditMode) return <div data-epi-edit={name}>Click to edit {name}</div>
    return null
  }

  const Link = CtaLink?.Value[0]

  const HeadingRenderer = () => {
    return (
      <h1 className="lg:text-6xl md:text-3xl text-2xl font-medium font-display tracking-tightest">
        <span data-epi-edit="Heading">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </span>
      </h1>
    )
  }

  const SubheadingRenderer = () => {
    if (Subheading || inEditMode) {
      return (
        <h3 className="lg:mt-10 lg:text-2xl text-sm font-display tracking-tight">
          <span data-epi-edit="Subheading">
            {Subheading}
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h3>
      )
    }
    return null
  }

  return (
    <div className="bg-right-bottom bg-no-repeat bg-cover" style={{ backgroundImage: `url(${addWebPToImageURL(Image?.Url)})` }}>
      <div data-epi-edit={name} className="container mx-auto max-w-screen-xl">
        <div className="lg:mb-4 xl:ml-24 lg:ml-20 md:ml-12">
          <div className="md:mx-0 mx-6 lg:grid lg:grid-cols-12 gap-6 pb-6">
            <div className="col-span-7 xl:pt-18 lg:pt-10 md:pt-8 pt-6 relative">
              <HeadingRenderer />
              <div className={`grid ${Subheading ? 'grid-cols-2' : ''} gap-2 lg:block mt-4 lg:mt-0`}>
                <SubheadingRenderer />
              </div>
              <div className="mt-4 lg:mt-10 w-full max-w-md">
                <SearchBar action={SearchPage} placeHolder={SearchText} name="SearchText"
                  button={Link} buttonName="CtaLink" button2={CtaLink2} buttonName2="CtaLink2" hideSearchInput={hideSearchInput}
                  loginButton1={LoginPopupMemberNonmemberReference1} loginButton2={LoginPopupMemberNonmemberReference2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const OnPageNav = ({ data, name, inEditMode }) => {
  if ((!data || !data.Value || data.Value.length === 0) && !inEditMode) return null
  const [activeIndex, setActiveIndex] = useState(0)

  function handleClick (e, index) {
    e.preventDefault()
    setActiveIndex(index)
    let blockId = data.Value[index]
    if (blockId) blockId = blockId.replaceAll(' ', '')
    const elmnt = document.getElementById(blockId)
    if (elmnt) elmnt.scrollIntoView({ behavior: 'smooth' })
    window.history.pushState(null, null, `#${blockId}`)
  }

  useLocationPathChange(() => {
    setActiveIndex(0)
  })

  return (
    <div data-epi-edit={name} className="container mx-auto max-w-screen-xl">
      <div className="xl:ml-24 lg:ml-20 md:ml-12
                      xl:mb-12 md:mb-8 mb-6
                      lg:text-lg lg:font-normal font-medium font-display text-xs">
        <div className="flex items-end border-b-2 border-gray-300 md:overflow-visible overflow-x-scroll hidden-scrollbars whitespace-nowrap">
          {data && (!data.Value || data.Value.length === 0) && (
            <span className="border-b-3 border-fuchsia pb-3">Click to Edit</span>
          )}
          {data && data.Value && data.Value.length !== 0 && data.Value.map((link, i) => (
            <div key={i} onClick={(event) => handleClick(event, i)} className={`${i === activeIndex ? 'border-fuchsia nav-shadow-fuchsia' : 'border-transparent'} border-b-2 pb-2 mr-8 ml-2 md:ml-0`}>
              <a href={`#${link.replaceAll(' ', '')}`} className="hover:text-gray-600">
                {link}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const GrandChildPage = ({ data, inEditMode }) => {
  const { MainContentArea, HeroBanner, AnchorLinks } = data
  return (
    <>
      <ChildHeroBannerBlock data={HeroBanner} name='HeroBanner' inEditMode={inEditMode} hideSearchInput={true} />
      <OnPageNav data={AnchorLinks} name='AnchorLinks' inEditMode={inEditMode} />
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(GrandChildPage)
