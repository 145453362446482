import React from 'react'
import { Markdown } from 'Components'

const LegalSitemapPageContentBlock = ({ data, inEditMode }) => {
  const { Heading, Content1, Content2 } = data

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-80 lg:mx-64 md:mx-44 sm:mx-36 mx-12
                      xl:py-12 lg:py-10 md:py-8 sm:py-6 py-4">
        <h2 data-epi-edit="Heading" className="xl:text-3xl lg:text-2xl md:text-xl sm:text-lg text-base
                                              lg:mb-8 md:mb-6 sm:mb-4 mb-3 font-bold">
          {Heading}
        </h2>
        <div className="flex xl:gap-x-72 lg:gap-x-64 md:gap-x-48 sm:gap-x-40 gap-x-16">
          <>
            <Markdown data={Content1} name="Content1" inEditMode={inEditMode}/>
          </>
          <>
            <Markdown data={Content2} name="Content2" inEditMode={inEditMode}/>
          </>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LegalSitemapPageContentBlock)
