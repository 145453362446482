import React from 'react'

import { useInEditMode } from 'Hooks'

const Page = () => {
  const inEditMode = useInEditMode()
  if (!inEditMode) return null

  return <>Page template is not valid for unpublished content. Change to Draft Mode to view the drafted version.</>
}

export default React.memo(Page)
