import React, { useState, useEffect } from 'react'

import { ChildHeroBannerBlock } from 'Blocks'
import { RouterLinkTemplate, PaginationRenderer } from 'Components'
import ContentArea from 'Components/ContentArea'

const IsBestBet = (metaData) => {
  if (metaData === null || metaData === undefined) {
    return false;
  }

  return metaData.BestBet !== null
    && (metaData.BestBetStyle !== null && metaData.BestBetStyle?.StringValue === 'True');
}

const SearchResultLine = ({ data, inEditMode }) => {
  const { Title, Excerpt, Url, FileExtension, MetaData } = data
  let isBestBet = IsBestBet(MetaData);
  let containerStyleClasses = "lg:mb-6 mb-4";
  let h2LinkStyleClasses = "lg:text-2xl text-xl text-violet hover:text-violet-light";
  let descStyleClasses = "font-display text-sm mb-2";
  let urlLinkStyleClasses = "text-sm font-medium font-display text-[#9966FF] hover:text-violet"

  //Remove any query string params from what displays
  let urlLinkText = Url.split('?')[0];

  if (isBestBet) {
    containerStyleClasses += " bg-[#D3D9FF] rounded-2xl lg:py-4 lg:px-6 px-4 py-2";
    //Completely replace as we don't want the lg-only text-2xl designation
    h2LinkStyleClasses = "text-[#430883] text-2xl leading-7 font-semibold hover:text-violet-light"
    descStyleClasses += " font-medium leading-[22px] line-clamp-2"
    urlLinkStyleClasses = "text-sm font-medium font-display text-[#54099E] hover:text-violet-light"
  }

  return (
    <div className={containerStyleClasses}>
      {isBestBet && <div className="text-lg mb-2 font-display font-medium text-[#430883]">Suggested Result</div>}
      <h2 className="font-medium font-display mb-2 block">
        {!FileExtension && <RouterLinkTemplate Path={Url} inEditMode={inEditMode}><span className={h2LinkStyleClasses} dangerouslySetInnerHTML={{ __html: Title }}/></RouterLinkTemplate>}
        {FileExtension && <a className={h2LinkStyleClasses} href={Url} target="_blank" rel="noreferrer">{Title}</a>}
        <div className="lg:text-xl text-lg text-violet-light">{FileExtension?.toUpperCase()}</div>
      </h2>
      <div className={descStyleClasses} dangerouslySetInnerHTML={{ __html: Excerpt }} />
      {!FileExtension && <a className={urlLinkStyleClasses} href={Url}>{urlLinkText}</a>}
    </div>
  )
}

const paginate = (array, pageSize, pageNumber) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const SearchPage = ({ data, inEditMode }) => {
  const { SearchQuery, SearchResults, PageSize, MainContentArea, HeroBanner, SearchCategories } = data
  const [pageNumber, setPageNumber] = useState(1)

  return (
    <>
      <ChildHeroBannerBlock data={HeroBanner} name='HeroBanner' inEditMode={inEditMode} prefillSearch={SearchQuery} isSearchPage={true} searchCategories={SearchCategories} />
      {SearchResults?.length > 0 && <div className="container mx-auto max-w-screen-xl">
        <div className="xl:pt-18 lg:pt-12 md:pt-8 py-6 md:pb-0 mb-6
                        xl:px-32 lg:px-28 md:px-12 sm:px-8 px-6
                        relative overflow-hidden">
          {SearchQuery && <h4 className="font-display lg:text-xl text-lg lg:mb-12 mb-8">Your search for <span className="italic">&ldquo;{SearchQuery}&rdquo;</span> returned {SearchResults?.length ?? 0} result{SearchResults && SearchResults.length > 1 ? 's' : ''}.</h4>}
          {SearchResults && SearchResults.length > 0 && paginate(SearchResults, PageSize, pageNumber).map((result, i) => {
            return <SearchResultLine key={i} data={result} inEditMode={inEditMode} />
          })}
          {SearchResults && SearchResults.length > 0 && <PaginationRenderer pageNumber={pageNumber} onPageNumberChange={(page) => setPageNumber(page)}
            totalPageNumber={SearchResults && SearchResults.length > 0 ? Math.ceil(SearchResults.length / PageSize) : 1} />}
        </div>
      </div>}
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
    </>
  )
}

export default React.memo(SearchPage)
