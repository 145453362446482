import React from 'react'

const PartnerInfoCardBlock = ({ data, inEditMode }) => {
  const { Heading, Image } = data

  return (
    <div className="rounded-lg shadow-md grid">
      <img data-epi-edit="Image" data-src={Image?.Url} alt={Image?.AltTag} className="lazy aspect-w-16 aspect-h-9 w-full" />
      <div data-epi-edit="Heading" className="font-body md:text-xl text-lg py-4 px-6 self-end bg-white">
        {Heading}
      </div>
    </div>
  )
}

export default React.memo(PartnerInfoCardBlock)
