import React, { useEffect, useState, useRef } from 'react'

import { useStateCallback } from 'Hooks'
import { RouterLinkTemplate } from 'Components'
import LoginPopupMemberNonmemberFormBlock from 'Blocks/Forms/LoginPopupMemberNonmemberFormBlock.jsx'
import Select, { components } from 'react-select'
import api from 'Helpers/api'

import ChevronDownSVG from 'SVGs/chevronDown.svg'
import UncheckedBox from 'SVGs/UncheckedBox.svg'
import CheckedBox from 'SVGs/CheckedBox.svg'

import './SearchBar.css'

const ButtonRenderer = ({ name, data, inEditMode }) => (
  <div data-epi-edit={name} className='md:flex-none flex-[0_0_48%] md:only:flex-none'>
    <RouterLinkTemplate Path={data.Href} inEditMode={inEditMode}>
      <div className="focus:outline-none rounded-full font-medium font-body text-white md:text-base sm:text-sm text-xs
                   bg-violet hover:bg-violet-dark border-2 border-violet hover:border-violet-dark
                     lg:py-2.5 py-2 md:px-8 sm:px-6 px-4 whitespace-nowrap text-center">{inEditMode && !data && !data?.Text ? 'Click to edit' : data.Text}</div>
    </RouterLinkTemplate>
  </div>
)

const getButtonStyles = (style, color) => {
  // shared classes first
  let inlineStyles = `rounded-full font-medium font-body text-xs sm:text-sm md:text-base whitespace-nowrap
                    py-2 lg:py-2.5 px-4 sm:px-6 md:px-8`

  switch (style) {
    case 'primary':
      inlineStyles += ` border-2 text-white`
      if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#320767] border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
      break
    case 'secondary':
      inlineStyles += ` bg-white border-2 text-black`
      if (color === 'violet') { inlineStyles += ' border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' border-[#21064C] hover:border-violet' }
      break
    case 'primary-with-arrow':
      inlineStyles += ` border-2 text-white`
      if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#320767] border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
      break
    case 'secondary-with-arrow':
      inlineStyles += ` bg-white border-2`
      if (color === 'violet') { inlineStyles += ' text-black border-violet hover:border-[#320767]' }
      if (color === 'dark-violet') { inlineStyles += ' text-black border-[#21064C] hover:border-violet' }
      break
  }

  return inlineStyles
}

const ButtonWithStylesRenderer = ({ data, name, inEditMode }) => {
  if (!data) return
  const { Color, Link, Style } = data
  const showArrow = data.Style === 'primary-with-arrow' || data.Style === 'secondary-with-arrow' ? true : false;
  const link = Link?.Value[0]

  const classes = getButtonStyles(data.Style, data.Color);

  return (
    <div data-epi-edit={name} className='md:flex-none flex-[0_0_48%] md:only:flex-none'>
      <a href={link?.Href}>
        <div className={`flex gap-4 justify-center ${classes}`}>
          {inEditMode && !data && !link?.Text ? 'Click to edit' : <div>{link?.Text}</div>}
          {showArrow && <ChevronDownSVG className="w-4 stroke-2" />}
        </div>
      </a>
    </div>
  )
}

const SearchBar = ({ name, placeHolder, q, action, searchPageName, button, buttonName, button2, buttonName2, inEditMode, hideSearchInput, isSearchPage, searchCategories, loginButton1, loginButton2 }) => {
  const searchText = placeHolder == null || placeHolder === '' ? 'How may we help you?' : placeHolder
  const [query, setQuery] = useStateCallback(q ?? '')
  const [autoComplete, setAutoComplete] = useState([])
  const [xsrfToken, setXsrfToken] = useState('')
  const formEl = useRef(null)
  const tokenEl = useRef(null)
  const inputEl = useRef(null)
  const categoriesSelectEl = useRef(null)
  const submitButtonRef = useRef(null)
  const selectedCategoriesIds = useRef(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState(null)
  const hasButton2 = button2?.Link.Value.length > 0 ? true : false
  const showLoginButton2Instead = !hasButton2
  const [isMounted, setIsMounted] = useState(false)

  const hasSearchCategories = searchCategories?.length > 0 ? true : false
  const allOption = { Description: 'All', Name: 'all', Id: 'all' }
  const searchCategoriesWithAll = hasSearchCategories ? [allOption, ...searchCategories] : null
  const searchCategoriesOptions = searchCategoriesWithAll?.map(category => ({ label: category.Description, value: category.Id, name: category.Name }))

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    var catsParam = urlParams.get('cats');
    if (catsParam && hasSearchCategories) {
      const cats = catsParam.split(',')
      const selectedCats = searchCategoriesOptions.filter(option => cats.includes(option.value.toString()))
      setSelectedCategories(selectedCats)
    }
    setIsMounted(true)
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!query) {
        setAutoComplete([])
        return
      }

      api.getSearchAutoComplete(query)
        .then(data => {
          setAutoComplete(data.Hits.filter(item => item.Query !== query))
          setTimeout(() => setAutoComplete([]), 5000)
        })
        .catch(err => console.log(err))
    }, 200)

    setXsrfToken(window.csrfToken)
    return () => clearTimeout(timeOutId)
  }, [query])

  const focusOnSelectInput = () => {
    setTimeout(() => {
      if (categoriesSelectEl.current) {
        categoriesSelectEl.current.focus();
      }
    }, 0);
  }

  const submitForm = () => {
    submitButtonRef.current && submitButtonRef.current.click()
  }

  const handleSubmission = (event) => {
    event.preventDefault()
    formEl.current.childNodes[0].removeChild(tokenEl.current);
    formEl.current.submit()
  }

  const handleCategorySelectOptionsChange = (selectedOptions) => {
    let newSelectedOptions;
    const hasAllOption = selectedOptions.map(option => option.value).includes('all')
    const hasMoreThanOneOption = selectedOptions.length > 1
    const recentSelectedOption = selectedOptions[selectedOptions.length - 1]
    const recentSelectedOptionIsAllOption = recentSelectedOption?.value === 'all'

    if (hasAllOption && hasMoreThanOneOption && recentSelectedOptionIsAllOption) {
      //only select all option
      newSelectedOptions = selectedOptions.filter(option => option.value === 'all')
    }
    else {
      //remove all option
      newSelectedOptions = selectedOptions.filter(option => option.value !== 'all')
    }

    setSelectedCategories(newSelectedOptions)
  }

  const CustomOptionComponent = ({ children, ...props }) => {
    return (
      <components.Option {...props} className='option-component bg-transparent text-inherit hover:cursor-pointer hover:bg-violet hover:text-white'>
        <div className='flex justify-between items-center'>
          <div>{props.label}</div>
          <div className='text-[#54099E]'>
            {props.isSelected ? <CheckedBox className='checkbox-icon w-full' /> : <UncheckedBox className='checkbox-icon w-full' />}
          </div>
        </div>
      </components.Option>
    )
  }

  const InputRenderer = ({ hideSearchInput }) => {
    if (hideSearchInput && hideSearchInput === true) {
      return null;
    }
    return (
      <div className="w-full">
        <input type="hidden" ref={tokenEl} name="__RequestVerificationToken" value={xsrfToken} />
        <div className='grid lg:flex'>
          <div className='flex'>
            {isMounted && hasSearchCategories &&
              <Select
                menuIsOpen={menuIsOpen}
                onMenuClose={() => setMenuIsOpen(false)}
                onMenuOpen={() => {
                  setMenuIsOpen(true);
                  focusOnSelectInput()
                }}
                ref={categoriesSelectEl}
                className="w-full max-w-[200px] hover:cursor-pointer"
                value={selectedCategories}
                options={searchCategoriesOptions}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                isClearable={false}
                isSearchable={false}
                placeholder='Select Categories'
                delimiter=','
                name='cats'
                components={{
                  IndicatorSeparator: () => null,
                  Option: CustomOptionComponent,
                }}
                onChange={(selectedOptions) => {
                  handleCategorySelectOptionsChange(selectedOptions)
                  focusOnSelectInput()
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '100%',
                    backgroundColor: '#323767',
                    color: '#fff',
                    borderTopLeftRadius: '9999px',
                    borderBottomLeftRadius: '9999px',
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingLeft: '1rem',
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: '#fff',
                  }),
                }}
              />}

            <div className="relative text-gray-800 focus-within:text-gray-800 w-full">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <span className="p-2 focus:outline-none focus:shadow-outline" data-epi-edit={searchPageName}>
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-4 h-4 md:w-6 md:h-6 text-gray-500"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </span>
              </span>
              <span data-epi-edit={name}>
                <input autoFocus="autoFocus" type="search" name="q" value={query} onChange={event => { setQuery(event.target.value) }}
                  className={`w-full h-full appearance-none shadow-inner placeholder-gray-600 py-2 lg:text-lg md:text-base sm:text-sm text-xs font-body
                border border-gray-300 md:pl-12 pl-10 pr-3 focus:outline-none focus:text-gray-800 ${hasSearchCategories ? 'rounded-r-full border-l-0' : 'rounded-full'}`}
                  placeholder={searchText} size={searchText.length + 5} autoComplete="off" ref={inputEl} required={true} />
              </span>
              {autoComplete && autoComplete.length > 0 && (
                <div role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
                  className="absolute rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  style={{ width: inputEl?.current?.offsetWidth, top: inputEl?.current?.offsetTop + inputEl?.current?.offsetHeight + 5 }}>
                  <div className="py-1" role="none">
                    {autoComplete.map((val, i) => {
                      const { Query } = val
                      return (
                        <span key={i} tabIndex="-1" onClick={() => setQuery(Query, () => submitForm())}
                          className="font-body cursor-pointer hover:text-violet-light text-gray-700 block px-8 py-4 text-sm">
                          {Query}
                        </span>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='flex justify-end ml-4 mt-2 lg:mt-0'>
            <button ref={submitButtonRef} type="submit"
              className='rounded-full font-medium font-display whitespace-nowrap bg-violet hover:bg-violet-dark border-2 border-violet hover:border-violet-dark text-white lg:text-sm sm:text-xs 
              text-xs py-1 lg:px-6 md:px-4 px-3'
              onClick={handleSubmission}>Search</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <form action={action} ref={formEl} className="flex flex-wrap gap-2 md:gap-4 relative justify-start">
      <InputRenderer hideSearchInput={hideSearchInput} />
      {(button || inEditMode) ?
        <ButtonRenderer data={button} name={buttonName} inEditMode={inEditMode} />
        :
        loginButton1 &&
        <div className='loginButtonSearchBar md:flex-none flex-[0_0_48%]'>
          <LoginPopupMemberNonmemberFormBlock data={loginButton1} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} className='flex' />
        </div>
      }
      {((hasButton2 && !isSearchPage) || inEditMode) ?
        <ButtonWithStylesRenderer data={button2} name={buttonName2} inEditMode={inEditMode} />
        :
        showLoginButton2Instead && loginButton2 &&
        <div className='loginButtonSearchBar md:flex-none flex-[0_0_48%]'>
          <LoginPopupMemberNonmemberFormBlock data={loginButton2} name='LoginPopupMemberNonmemberForm' inEditMode={inEditMode} />
        </div>
      }
    </form>
  )
}

export default React.memo(SearchBar)
