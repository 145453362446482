import React from 'react'

const ReduceMarginBlock = ({ data }) => {
  const { SliderValue } = data
  let marginClass
  switch (SliderValue) {
    case 1:
      marginClass = '-m-1'
      break
    case 2:
      marginClass = '-m-2'
      break
    case 3:
      marginClass = '-m-3'
      break
    case 4:
      marginClass = '-m-4'
      break
    case 5:
      marginClass = '-m-6'
      break
    case 6:
      marginClass = '-m-8'
      break
    case 7:
      marginClass = '-m-10'
      break
    case 8:
      marginClass = '-m-16'
      break
    case 9:
      marginClass = '-m-24'
      break
    case 10:
      marginClass = '-m-36'
      break
    default:
      marginClass = '-m-px'
      break
  }

  return <div className={marginClass} />
}

export default React.memo(ReduceMarginBlock)
