import React, { useEffect } from 'react'

import ContentArea from 'Components/ContentArea'
import { RoundedButtonBlock } from 'Blocks'
import { RouterLinkTemplate } from 'Components'
import ChevronDownSVG from 'SVGs/chevronDown.svg'
import './ApplyNowPage.css'
import { addWebPToImageURL } from 'Helpers/utilities'

const ApplyNowPage = ({ data, inEditMode }) => {
  const { Image, Heading, Button, Button2, MainContentArea } = data
  if (!Image && !Heading && !Button) {
    if (inEditMode) return <div>Click to edit</div>
    return null
  }
  const HeadingRenderer = () => {
    return (
      <h1 className="xl:text-6xl lg:text-5xl md:text-3xl text-2xl xl:pb-28 md:pb-20 pb-16 font-medium font-display tracking-tightest">
        <span data-epi-edit="Heading">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </span>
      </h1>
    )
  }
  const ButtonRenderer = () => {
    if (Button) {
      return (
        <div className="lg:text-2xl text-sm font-display tracking-tight flex-[0_0_48%] md:flex-none button-container">
          <RoundedButtonBlock data={Button} name="Button" inEditMode={inEditMode} />
        </div>
      )
    }
    return null
  }

  const ButtonWithStylesRenderer = ({ data, name, inEditMode }) => {
    if (!data) return null
    const { Color, Link, Style } = data

    const hasLink = Link.Value.length > 0 ? true : false
    if (!hasLink) return null

    const link = Link?.Value[0]
    const showArrow = data.Style === 'primary-with-arrow' || data.Style === 'secondary-with-arrow' ? true : false;

    const classes = getButtonStyles(data.Style, data.Color);

    return (
      <div data-epi-edit={name} className='flex-[0_0_48%] md:flex-none'>
        <RouterLinkTemplate Path={link?.Href} inEditMode={inEditMode}>
          <div className={`flex gap-4 justify-center ${classes}`}>
            {inEditMode && !data && !link?.Text ? 'Click to edit' : <div>{link?.Text}</div>}
            {showArrow && <ChevronDownSVG className="w-4 stroke-2" />}
          </div>
        </RouterLinkTemplate>
      </div>
    )
  }

  const getButtonStyles = (style, color) => {
    // shared classes first
    let inlineStyles = `rounded-full font-medium font-body text-sm sm:text-sm md:text-base whitespace-nowrap
                      py-1 md:py-2 px-6 md:px-8`

    switch (style) {
      case 'primary':
        inlineStyles += ` border-2 text-white`
        if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#323767] border-violet hover:border-[#323767]' }
        if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
        break
      case 'secondary':
        inlineStyles += ` bg-white border-2 text-black`
        if (color === 'violet') { inlineStyles += ' border-violet hover:border-[#323767]' }
        if (color === 'dark-violet') { inlineStyles += ' border-[#21064C] hover:border-violet' }
        break
      case 'primary-with-arrow':
        inlineStyles += ` border-2 text-white`
        if (color === 'violet') { inlineStyles += ' bg-violet hover:bg-[#323767] border-violet hover:border-[#323767]' }
        if (color === 'dark-violet') { inlineStyles += ' bg-[#21064C] hover:bg-violet border-[#21064C] hover:border-violet' }
        break
      case 'secondary-with-arrow':
        inlineStyles += ` bg-white border-2`
        if (color === 'violet') { inlineStyles += ' text-black border-violet hover:border-[#323767]' }
        if (color === 'dark-violet') { inlineStyles += ' text-black border-[#21064C] hover:border-violet' }
        break
    }

    return inlineStyles
  }

  return (
    <div>
      <div className="bg-right-bottom bg-no-repeat bg-cover" style={{ backgroundImage: `url(${addWebPToImageURL(Image?.Url)})` }}>
        <div className="container mx-auto max-w-screen-xl">
          <div className="lg:mb-4 md:mb-3 mb-4 xl:ml-24 lg:ml-20 md:ml-12">
            <div className="mx-6 md:mx-0 lg:grid lg:grid-cols-12 gap-6 pb-10">
              <div className="lg:col-span-12 col-span-8 lg:pt-20 md:pt-12 pt-10 relative">
                <HeadingRenderer />
                <div className={`flex flex-wrap justify-start gap-2 md:gap-6`}>
                  <ButtonRenderer />
                  <ButtonWithStylesRenderer data={Button2} name="Button2" inEditMode={inEditMode} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
    </div>
  )
}

export default React.memo(ApplyNowPage)
