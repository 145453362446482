import React from 'react'

import { RouterLinkTemplate } from 'Components'

const RightPageLinksRenderer = ({ data, name, inEditMode }) => {
  if (!data || !data.Value || data.Value.length === 0) {
    if (!inEditMode) return null

    return (
      <div data-epi-edit={name}>
        <span className="text-sm text-gray-500">Click to edit References</span>
      </div>
    )
  }

  return (
    <ul data-epi-edit={name}>
      {data?.Value?.map((link, i) => (
        <li key={i}
          className={`inline-block 2xl:text-base lg:text-lg text-base ${i === 0 ? '' : '2xl:pl-10 lg:pl-12 md:pl-5 sm:pl-3 pl-2'}`}>
          <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
            {link.Text}
          </RouterLinkTemplate>
        </li>
      ))}
    </ul>
  )
}

export default RightPageLinksRenderer
