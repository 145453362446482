import React from 'react'

const HolidayScheduleBlock = ({ data, inEditMode }) => {
  const { Heading, Holidays } = data
  if (!Heading && (!Holidays || Holidays.length === 0) && !inEditMode) return null
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:pt-12 lg:pb-8 md:py-7 py-4">
        <h2 className="lg:text-4xl md:text-2xl text-lg font-medium font-display text-center lg:pb-14 md:pb-10 pb-6" data-epi-edit="Heading">
          {Heading}
          {inEditMode && !Heading && 'Click to edit Heading'}
        </h2>
        <div className="grid grid-cols-4 lg:gap-16 md:gap-8 gap-4" data-epi-edit="Holidays">
          {Holidays && Holidays.length > 0 && Holidays.map((val, i) => {
            const { Title, Description, HolidayDate } = val
            const date = new Date(HolidayDate)

            return (
              <div key={i} className="flex md:gap-3 gap-2">
                <div className="grid font-display lg:text-sm md:text-xs sm:text-xxs text-xxxs">
                  <div className="text-violet-bright text-medium">{monthNames[date.getMonth()]}</div>
                  <div className="text-gray-500">{date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}</div>
                </div>
                <div className="grid gap-1">
                  <div className="font-display font-medium lg:text-sm md:text-xs sm:text-xxs text-xxxs">{Title}</div>
                  <div className="font-body md:text-xs text-xxxs text-gray-500">{Description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(HolidayScheduleBlock)
