import React from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'
import { RoundedButtonBlock } from 'Blocks'
import { addWebPToImageURL } from 'Helpers/utilities'

const CTARenderer = ({ option, link, btn, inEditMode }) => {
  const CTAOption = () => {
    if (!inEditMode) return null
    return <div className="mt-5 text-sm text-gray-500" data-epi-edit="CtaOption">Click to change CTA option</div>
  }

  if (option === 'none') {
    return <CTAOption />
  }

  if (option === 'button') {
    return (
      <>
        <RoundedButtonBlock data={btn} name="CtaButton" inEditMode={inEditMode} />
        <CTAOption />
      </>
    )
  }

  return (
    <>
      {link && (
        <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
          <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet hover:text-violet-light">{link.Text}</span>
        </RouterLinkTemplate>
      )}
      {!link && inEditMode && (
        <span data-epi-edit="CtaLink" className="lg:text-xl font-medium font-display text-violet">
          Click to edit Link
        </span>
      )}
      <CTAOption />
    </>
  )
}

const ContentBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Image, ImageLocation, Content, CtaOption, CtaButton, CtaLink } = data
  const Link = CtaLink.Value[0]

  const Layout = () => {
    const ContentContainer = () => {
      return (
        <>
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium font-display">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
          {(Subheading || inEditMode) && (
            <h4 className="lg:text-2xl md:text-xl font-body">
              <span data-epi-edit="Subheading">
                {Subheading}
                {inEditMode && !Subheading && 'Click to edit Subheading'}
              </span>
            </h4>
          )}
          <Markdown data={Content} name='Content' inEditMode={inEditMode} />
          <CTARenderer option={CtaOption} link={Link} btn={CtaButton} inEditMode={inEditMode}/>
        </>
      )
    }

    const ImageContainer = () => {
      return (
        <>
          <div data-epi-edit="Image" className="md:py-0 py-6">
            <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className="lazy md:max-h-full max-h-52" width="400" height="400" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
          {inEditMode && (
            <div className="mt-5 text-sm text-gray-500" data-epi-edit="ImageLocation">Change image location option</div>
          )}
        </>
      )
    }

    const Inner = ({ imageLocation }) => {
      const contentContainerClass = 'col-span-7 grid lg:gap-6 sm:gap-4 gap-2 self-center'
      const imageContainerClass = 'justify-self-center col-span-5'
      if (imageLocation === 'right') {
        return (
          <>
            <div className={`${contentContainerClass} text-left`}>
              <ContentContainer/>
            </div>
            <div className={`${imageContainerClass} lg:justify-self-end`}>
              <ImageContainer/>
            </div>
          </>
        )
      }

      return (
        <>
          <div className={`${imageContainerClass} lg:justify-self-start`}>
              <ImageContainer/>
            </div>
          <div className={`${contentContainerClass}`}>
            <ContentContainer/>
          </div>
        </>
      )
    }

    return (
      <div className="grid grid-cols-12 lg:gap-10 gap-6 justify-items-stretch items-center">
        <Inner imageLocation={ImageLocation} />
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <Layout/>
      </div>
    </div>
  )
}

export default React.memo(ContentBlock)
