import React from 'react'

const LegalCareersCardBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Subheading } = data
  return (
    <div className="h-full ">
      <div data-epi-edit="Image">
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy hidden md:block" width="40" height="40" />
            <img data-src={Image?.Url} alt={Image?.AltTag} className="lazy md:hidden" width="18" height="18" />
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        <h2 data-epi-edit="Heading" className="font-bold text-left xl:text-2xl lg:text-xl sm:text-sm text-xxs xl:mt-6 lg:mt-5 md:mt-4 sm:mt-3 mt-1 xl:mb-10 lg:mb-8 md:mb-4 sm:mb-4 mb-1">
          {Heading}
        </h2>
        <h6 data-epi-edit="Subheading" className="text-left xl:text-lg lg:text-sm md:text-xs sm:text-xxs text-xxxs">
          {Subheading}
        </h6>
    </div>
  )
}

export default React.memo(LegalCareersCardBlock)
