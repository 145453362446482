import VideoCardBlock from './VideoCardBlock'
import VideoGuidesBlock from './VideoGuidesBlock'
import VideoGuidesWithFeaturedBlock from './VideoGuidesWithFeaturedBlock'
import VideoGuideWithContentBlock from './VideoGuideWithContentBlock'
import VideoGuideWithWideCards from './VideoGuideWithWideCards'

export default {
  VideoCardBlock,
  VideoGuidesBlock,
  VideoGuidesWithFeaturedBlock,
  VideoGuideWithContentBlock,
  VideoGuideWithWideCards
}
