import React from 'react'
import EventCardBlock from './EventCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const EventBlock = ({ data, inEditMode }) => {
  const { MainContentArea } = data

  if (!MainContentArea && !inEditMode) return null
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-24 sm:mx-20 mx-10 lg:py-16 md:py-12 sm:py-10 py-6">
      <div data-epi-edit="MainContentArea" className="grid lg:gap-y-5 md:gap-y-4 sm:gap-y-3 gap-y-2">
          {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <EventCardBlock data={blockData[Id]} inEditMode={inEditMode} key={i} />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(EventBlock)
