import React, { useState, useEffect } from 'react'

const Card = ({ text, textName, icon, iconName, inEditMode, isActive, setFilter }) => {
  const [active, setActive] = useState(isActive)

  function handleSetState (state) {
    setActive(state)
    if (typeof setFilter === 'function' && state) {
      setFilter(text)
    } else {
      setFilter(null)
    }
  }

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  return (
    <div suppressHydrationWarning={true} className={`mx-1 flex-1 group bg-white cursor-pointer ${active ? 'shadow-normal' : ''} transition-opacity rounded-lg md:p-4 p-2 gap-2 grid justify-items-center items-center`}
      onClick={() => handleSetState(!active)}>
        <div data-epi-edit={iconName} className="lg:mb-4 md:mb-3 mb-2">
          <img data-src={icon?.Url} alt={icon?.AltTag} width="30" height="30" className='lazy' />
      </div>
      <h4 data-epi-edit={textName}
        className="font-display font-medium text-center md:text-sm text-xs leading-6" >
        {text}
      </h4>
    </div>
  )
}

export default React.memo(Card)
