import React from 'react'

import VideoCardBlock from './VideoCardBlock'
import { contentAreaDataMapper } from 'Helpers/transform'

const VideoGuidesBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, MainContentArea } = data
  const blockData = contentAreaDataMapper(MainContentArea)

  return (
    <div className="container mx-auto max-w-screen-xl py-12 px-2">
      {(Heading || Subheading || inEditMode) && (
        <div className="grid justify-items-center lg:py-10 md:py-10 py-3">
          <h2 className="lg:text-4xl text-xl font-medium font-display">
            <span data-epi-edit="Heading">
              {Heading}
              {inEditMode && !Heading && 'Click to edit Heading'}
            </span>
          </h2>
          <h4 className="lg:text-2xl text-sm my-4 font-body">
            <span data-epi-edit="Subheading">
              {Subheading}
              {inEditMode && !Subheading && 'Click to edit Subheading'}
            </span>
          </h4>
        </div>
      )}
      <div data-epi-edit="MainContentArea" className={`grid sm:grid-cols-3 md:gap-4 gap-2 justify-around justify-items-center ${MainContentArea && MainContentArea.length < 3 ? 'lg:flex xl:mx-24 lg:mx-16 md:mx-12 mx-10' : ''}`}>
        {MainContentArea && MainContentArea.length > 0 && MainContentArea.map((block, i) => {
          const { ContentLink } = block
          const { Id } = ContentLink
          return (
            <div data-epi-block-id={Id} key={i}>
              <VideoCardBlock data={blockData[Id]} inEditMode={inEditMode} size='sm' />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(VideoGuidesBlock)
