import React from 'react'

import { RouterLinkTemplate } from 'Components'
import { addWebPToImageURL } from 'Helpers/utilities'

const CardBlock = ({ data, inEditMode, size }) => {
  const { Text, Image, Url } = data

  if (!Text && !Image && !inEditMode) return null

  const Content = () => {
    return (
      <div className={`grid justify-items-center gap-2 p-4 md:p-8 max-w-xs ${size === 'base' ? 'md:w-60 md:h-60' : 'lg:w-44 lg:h-44 md:w-52 md:h-52 w-32 h-32'}
      border border-gray-100 cursor-pointer duration-200 hover:border-violet-bright shadow hover:shadow-active m-2 mb-6 rounded-lg transform transition-all`}>
        <img data-epi-edit="Image" data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} className='lazy' width={size === 'base' ? '120' : '80'} height={size === 'base' ? '144' : '80'} />
        <div data-epi-edit="Text" className="font-display font-medium md:text-xl text-sm self-center">
          {Text}
          {!Text && inEditMode && 'Click to edit'}
        </div>
      </div>
    )
  }

  if (inEditMode) {
    return (
      <>
        <Content />
        <div data-epi-edit="Url" className="pl-5 inline-block text-sm text-gray-500">Edit Url</div>
      </>
    )
  }

  return (
    <RouterLinkTemplate Path={Url} inEditMode={inEditMode}>
      <Content/>
    </RouterLinkTemplate>
  )
}

export default React.memo(CardBlock)
