import React, { useEffect } from 'react'

import ContentArea from 'Components/ContentArea'

const LandingPage = ({ data, inEditMode }) => {
  const { MainContentArea } = data
  return (
    <ContentArea data={MainContentArea} name="MainContentArea" inEditMode={inEditMode} />
  )
}

export default React.memo(LandingPage)
