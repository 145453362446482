import React from 'react'
import { RoundedButtonBlock } from 'Blocks'

const LoanRelatedCardBlock = ({ data, inEditMode }) => {
  const { Heading, Subheading, Button } = data
  return (
    <div className="h-full shadow-normal rounded-lg hover:shadow-active bg-gray-200">
      <div className="lg:py-10 sm:py-8 py-6 lg:ml-9 md:ml-7 sm:ml-6 ml-5">
        <h2 data-epi-edit="Heading" className="text-left lg:text-xl md:text-lg sm:text-base text-base font-medium">
          {Heading}
        </h2>
        <h6 data-epi-edit="Subheading" className="font-display text-left lg:text-base md:text-sm sm:text-xs text-xxs lg:my-6 sm:my-5 my-4 ">
          {Subheading}
        </h6>
        <RoundedButtonBlock data={Button} name="Button" className="lg:text-base sm:text-xs text-xxs" inEditMode={inEditMode}/>
      </div>
    </div>
  )
}

export default React.memo(LoanRelatedCardBlock)
